<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="check"></con-icon-by-name>
        </div>
        <h3 class="mb-0">Status</h3>
      </div>
    </div>
  </div>
    <div class="row mb-3 sticky-header mozenda-menu">
      <div class="col-12">
        <div class="row">
          <div class="col-2 filter-options">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select [(ngModel)]="filterModels.selectedTypeFilter" [disabled]="loading">
                <mat-option *ngFor="let type of filterTypes"
                            [value]="type.value"
                            (click)="selectTypeFilter(type)">
                  {{type.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  <div class="row">
    <div class="col-12">
      <con-loader *ngIf="loading" [showCircularLoder]=showCircularLoader></con-loader>
      <div class="inner-scroll" *ngIf="mlReportStatuses?.data && mlReportStatuses?.data.length && !showCircularLoader">
        <div>
          <div>
            <div *ngFor="let mlStatus of mlReportStatuses.data; let index = index">
              <div>
                <div class="card mb-2">
                  <div class="card-header" (click)="toggleType(mlStatus)">
                    <div class="d-flex justify-content-start align-items-center">
                      <div class="mozenda-icon">
                        <con-icon-by-name iconName="medium" [mutations]="['fw']" class="cursor-pointer" (click)="reportService.goToEntity($event, 'company_report', mlStatus?.company_report?.id)"></con-icon-by-name>
                      </div>
                      {{getReportTitle(mlStatus)}}
                      <span *ngIf="mlStatus?.company_report?.company?.website" ngbTooltip="Website Link">
                        <a [href]=mlStatus?.company_report?.company?.website class="text-white pl-10" target="_blank"> <con-icon-by-name iconName="external-link"></con-icon-by-name></a>
                      </span>
                      <span class="badge badge-pill timeline-pill badge-primary ml-status-created ml-2" [ngbTooltip]=MLSendStatus.CREATED_MESSAGE *ngIf="mlStatus.request_status === MLSendStatus.CREATED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>CREATED</span>
                      <span class="badge badge-pill timeline-pill badge-primary ml-status-in-progress ml-2" [ngbTooltip]=MLSendStatus.IN_PROGRESS_MESSAGE *ngIf="mlStatus.request_status === MLSendStatus.IN_PROGRESS"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>IN PROGRESS</span>
                      <span class="badge badge-pill timeline-pill badge-primary ml-status-submitted ml-2" [ngbTooltip]=MLSendStatus.SUBMITTED_MESSAGE *ngIf="mlStatus.request_status === MLSendStatus.SUBMITTED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>SUBMITTED</span>
                      <span class="badge badge-pill timeline-pill badge-primary ml-status-failed ml-2" [ngbTooltip]=MLSendStatus.FAILED_MESSAGE *ngIf="mlStatus.request_status === MLSendStatus.FAILED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>FAILED</span>
                      <span class="badge badge-pill timeline-pill badge-primary ml-status-closed ml-2" [ngbTooltip]=MLSendStatus.CLOSED_MESSAGE *ngIf="mlStatus.request_status === MLSendStatus.CLOSED"> <con-icon-by-name [mutations]="['ml-status-black']" iconName="check"></con-icon-by-name> CLOSED</span>
                      <div class="card-header-toggle-individual ml-auto">
                        <a class="btn btn-sm btn-primary btn-padding" [routerLink]="['../../company_reports/', mlStatus.company_report?.company?.id,'report',mlStatus.company_report_id,'report-overview']" target="_blank" (click)="goToReport($event)">Go to report</a>
                        <con-icon-by-name iconName="angle-right"
                                          [mutations]="getMutations(mlStatus)"></con-icon-by-name>
                      </div>
                    </div>
                  </div>
                  <div class="p-4 col-12" *ngIf="!mlStatus.hide && !mlStatus.loading">

                    <div *ngIf="mlStatus?.error_msg">
                      <p><strong>ML Status Message : </strong> </p>
                      <p> {{mlStatus?.error_msg}} </p>

                    </div>

                    <div class="card-header">
                      <div class="d-flex justify-content-start align-items-center">
                        <con-icon-by-name iconName="medium" [mutations]="['fw']"></con-icon-by-name>
                        Inspect
                        <div class="card-header-toggle-individual ml-auto">
                          <con-icon-by-name iconName="copy" class="ml-15 cursor-pointer" (click)="copyJSON($event, mlStatus.data)"></con-icon-by-name>
                        </div>
                      </div>
                    </div>
                    <con-object-inspector [object]="mlStatus.data" >

                    </con-object-inspector>
                  </div>
                  <con-loader *ngIf="!mlStatus.hide && mlStatus.loading"></con-loader>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-2" [ngClass]="{'hide-pagination':!mlReportStatuses?.total}">
          <ngb-pagination [collectionSize]="mlReportStatuses?.total"
                          [page]="mlReportStatuses?.current_page"
                          (pageChange)="loadPage($event)"
                          [pageSize]="mlReportStatuses?.per_page"
                          maxSize="10"
                          [rotate]="true">
          </ngb-pagination>
        </div>
        <div class="row">
          <div class="col-12 text-center"><small>Showing {{mlReportStatuses?.from}} to {{mlReportStatuses?.to}} of {{mlReportStatuses?.total}} entries</small></div>
        </div>
      </div>
      <con-no-data *ngIf="!loading" [count]="mlReportStatuses?.total" [message]="noDataMessage"></con-no-data>
    </div>
  </div>
</div>
