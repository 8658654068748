import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { CompanyReportsService } from '../../../company-reports/services/company-reports.service';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ApiSettings, featureFlagSettings, EstimatesSettings } from '../../../settings.class';
import { EstimatesService } from '../../services/estimates.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExcelImportModalComponent } from '../excel-import-modal/excel-import-modal.component';
import { Subject, combineLatest, of } from 'rxjs';
import { EntitiesService } from 'app/entities/services/entities.service';
import { FeatureFlagService } from 'app/shared/services/feature-flag.service';

@Component({
  selector: 'con-active-consensus',
  templateUrl: './active-consensus.component.html',
  styleUrls: ['./active-consensus.component.scss']
})
export class ActiveConsensusComponent implements OnInit, OnDestroy {
  company: any;
  showData = false;
  recommendationsForm: FormGroup;
  targetPriceForm: FormGroup;
  outlookForm: FormGroup;
  currentRouteParams: any;
  consensusData: any;
  latestSurveyId!: number;
  fetchConsensusLoading = false;
  currentSurveyDetails: any;
  isLoading = false;
  private componentDestroyed$ = new Subject();
  isAPISyncEnabled: boolean = false;
  featuresEnabled: any = {};
  featureFlaggedKeys = featureFlagSettings.FEATURE_KEYS;

  constructor( 
    private http: HttpClient, 
    private estimateService: EstimatesService, 
    private route: ActivatedRoute, 
    private companyReportService: CompanyReportsService, 
    private fb: FormBuilder, 
    private toaster: ToastrService, 
    private modalService: NgbModal, 
    private entityService: EntitiesService,
    private featureFlagService: FeatureFlagService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.pipe(
      takeUntil(this.componentDestroyed$),
      switchMap((params: Params) => {
        this.currentRouteParams = params;
        if (params && params.id && params.companyId) {
          return combineLatest([
            this.estimateService.getConsensusValues().pipe(
              takeUntil(this.componentDestroyed$),
              switchMap((consensusData) => {
                if(consensusData)
                  return of(consensusData);
                else{
                  this.isLoading = true;
                  return this.estimateService.getConsenusData(params.id)
                }
              })
            ),
            this.companyReportService.getCompanyData(params.companyId),
            this.estimateService.getLatestSurveyDetails(params.companyId),
            this.estimateService.getAPISyncStatus(params.companyId),
            this.featureFlagService.isFeaturesEnabled([this.featureFlaggedKeys.estimates_manual_onboarding]),
            this.estimateService.getCurrentSurveyApproveStatus().pipe(
              switchMap((data) => {
                this.isLoading = true;
                return this.estimateService.getSurvey(params.id);
              }),
              takeUntil(this.componentDestroyed$)
            )
          ]).pipe(
            map(([consenusData, companyDetails, latestSurveys, APISyncStatus, featuresEnabled, surveyData]) => ({ consenusData, companyDetails, latestSurveys, APISyncStatus, featuresEnabled, surveyData }))
          );
        } else {
          return of(null);
        }
      })
    ).subscribe(
      (data) => {
        if (data) {
          this.company = data?.companyDetails;
          this.showData = true;
          this.consensusData = data?.consenusData;
          this.currentSurveyDetails = data?.surveyData;
          this.latestSurveyId = data?.latestSurveys?.data[0]?.id;
          this.isAPISyncEnabled = data?.APISyncStatus;
          this.featuresEnabled = data?.featuresEnabled;
          this.buildForm(this.consensusData);
          if (this.consensusData.estimates) {
            for (data of this.consensusData.estimates) {
              data.hide = true;
            }
          }
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  getConsenusData() {
    this.isLoading = true;
    this.http.get(ApiSettings.BASE_URL + `/estimates/survey/${this.currentRouteParams.id}/consensus`).subscribe((data: any) => {
      this.isLoading = false;
      this.showData = true;
      this.consensusData = data;
      this.buildForm(this.consensusData);
      if (this.consensusData.estimates) {
        for (data of this.consensusData.estimates) {
          data.hide = true;
        }
      }
    })
  }

  buildForm(data: any) {

    // Create the two new form groups
    this.targetPriceForm = this.createTargetPriceForm(data.target_price);
    this.outlookForm = this.createOutlookForm(data.outlook);

    // Combine all forms into one parent form
    this.recommendationsForm = this.fb.group({
      targetPrice: this.targetPriceForm,
      outlook: this.outlookForm
    });
    if(this.currentSurveyDetails.locked){
      this.recommendationsForm.disable();
      
    }
  }
  createTargetPriceForm(targetPriceData) {
    return this.fb.group({
      id:new FormControl(targetPriceData?.id || null),
      mean: new FormControl(this.estimateService.setFormValue(targetPriceData?.mean), [Validators.pattern(EstimatesSettings.DECIMAL_REGEX)]),
      median: new FormControl(this.estimateService.setFormValue(targetPriceData?.median), [Validators.pattern(EstimatesSettings.DECIMAL_REGEX)]),
      high: new FormControl(this.estimateService.setFormValue(targetPriceData?.high), [Validators.pattern(EstimatesSettings.DECIMAL_REGEX)]),
      low: new FormControl(this.estimateService.setFormValue(targetPriceData?.low), [Validators.pattern(EstimatesSettings.DECIMAL_REGEX)]),
      amount: new FormControl(this.estimateService.setFormValue(targetPriceData?.amount), [Validators.pattern(EstimatesSettings.INTEGER_REGEX)]),
    });
  }

  createOutlookForm(outlookData) {
    return this.fb.group({
      id:new FormControl(outlookData?.id || null),
      buy: new FormControl(this.estimateService.setFormValue(outlookData?.buy), [Validators.pattern(EstimatesSettings.INTEGER_REGEX)]),
      hold: new FormControl(this.estimateService.setFormValue(outlookData?.hold), [Validators.pattern(EstimatesSettings.INTEGER_REGEX)]),
      sell: new FormControl(this.estimateService.setFormValue(outlookData?.sell), [Validators.pattern(EstimatesSettings.INTEGER_REGEX)]),
    });
  }

  onSubmit() {

  }
  getStripedWebsiteString(companyWebsite: string): string {
    if (companyWebsite) {
      return companyWebsite.replace(/(^\w+:|^)\/\//, '');
    }
    return '';
  }

  fetchLatestConsensusManually() {
    if( this.currentSurveyDetails?.locked || !this.isAPISyncEnabled )
      return;
    this.fetchConsensusLoading = true;
    this.estimateService.fetchLatestConsensus(this.currentRouteParams.id).pipe(takeUntil(this.componentDestroyed$)).subscribe((data: any) => {
      this.toaster.success('A process has been initiated to fetch latest consensus');
      this.fetchConsensusLoading = false;
    },
    err => {
      if(err?.data && err?.data?.type === ApiSettings.RESPONSES.VALUE_ERROR)
        this.toaster.error(this.entityService.getFirstErrorForToaster(err?.data) || 'Failed to initiate a process to fetch latest consensus');
      else
        this.toaster.error('Failed to initiate a process to fetch latest consensus');
    })
  }

  downloadTemplate() {
    if(this.currentSurveyDetails?.locked)
      return;
    this.estimateService.downloadExcelTemplate(this.currentRouteParams.id).pipe(takeUntil(this.componentDestroyed$)).subscribe((response: any) => {
      const contentDisposition = response.headers.get('content-disposition');
      const downloadAnchor = document.createElement('a');
      downloadAnchor.href = window.URL.createObjectURL(new Blob([response.body], { type: 'application/xlsx' }));
      downloadAnchor.download = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');;
      downloadAnchor.click();
      this.toaster.success('Template downloaded successfully');
    },
      err => {
        this.toaster.error('Failed to download template');
      }
    );
  }

  openImportModal() {
    if(this.currentSurveyDetails?.locked)
      return;
    const modalRef = this.modalService.open(ExcelImportModalComponent, { size: 'md' });
    modalRef.componentInstance.surveyId = this.currentRouteParams.id;
    modalRef.result.then(() => {
      this.getConsenusData();
      this.refreshSyncStatus();
    }, () => { });
  }

  refreshSyncStatus(){
    this.estimateService.getAPISyncStatus(this.currentRouteParams.companyId).pipe(take(1)).subscribe((status: boolean) => {
      this.isAPISyncEnabled = status;
    })
  }
}
