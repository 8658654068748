<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title pb-3 pt-3">
        <div>
          <ul class="nav nav-pills mb-15 pull-left w-200">
            <li class="nav-item">
              <a class="nav-link"
                 [routerLink]="[]"
                 [queryParams]="{ view: 'missing' }"
                 routerLinkActive="active">Missing</a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 [routerLink]="[]"
                 [queryParams]="{ view: 'upcoming' }"
                 routerLinkActive="active">upcoming</a>
            </li>
          </ul>
        </div>
        <div class="clearfix"></div>
        <div class="d-flex justify-content-start sticky-header py-2">
          <h3 class="mb-0" *ngIf='!loading'>
            <span class="badge badge-dark">{{company?.name}} <span *ngIf='isUpcoming'>Upcoming</span> <span *ngIf='!isUpcoming'>Missing</span>  Events</span>
            <span *ngIf="company?.website"><a [href]=company?.website class="link-fontsize" target="_blank">{{reportService.getStripedWebsiteString(company?.website)}}</a></span>
          </h3>
          <div class="ml-auto">
            <div class="chip chip-inverse chip-link"  (click)="toggleAddReportEvent()">
              <i class="fa fa-plus"></i>
              Add New Report Event
            </div>
          </div>
        </div>
        <!-- show add report event -->
        <div class="full-width" *ngIf='showAddReportEvent'>
          <div class="card mb-2" >
            <div class="card-header" (click)="toggleType()">
              <div class="d-flex justify-content-start align-items-center">
                <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                Add New Report Event
                <div class="card-header-toggle-individual ml-auto">
                  <button type="button" class="close no-highlight cursor-pointer" aria-label="Close" (click)='closeAddReportEvent($event)'>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <con-icon-by-name iconName="angle-right" [mutations]="getMutations()"></con-icon-by-name>
                </div>
              </div>
            </div>
            <div class="card-body" *ngIf="newReportExpanded" >
              <con-entity-form-content
                [showEditButton]="false"
                [entityName]="entityName"
                [preFillValues]="{report_date: getCurrentDate(),report_type:'update'}"
                [canDelete]="false"
                [initialSaveEnabled] = "true"
                [emptyAfterSave]="true"
                [fixedValues]="{ company_id: this.companyId}"
                (afterSave)='refreshEventTable()'
              >
              </con-entity-form-content>
            </div>
          </div>
        </div>
        <con-events-table [reloadListing]="reloadListing" [is_upcoming]="isUpcoming" [companyId]="companyId"></con-events-table>
      </div>
    </div>
  </div>
</div>
