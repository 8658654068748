<ng-container *ngIf='loading'>
  <con-loader [showCircularLoder]='true'></con-loader>
</ng-container>
<ng-container *ngIf='!loading && isErrored'>
  <div class='mat-error'>Filter could not be loaded</div>
</ng-container>

<ng-container *ngIf='!loading && !isErrored'>
<form [formGroup]="itemsForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)='processFormValues()'>
  <div formGroupName="searchInfo">
    <div class="row" >
      <div class="col-md-2">
        <small class='filter-text-color'>Report Date</small>
        <con-entity-search-by-field
          [field]="getFieldData('report_date')"
          [control]="getField('searchInfo', 'reportDate')">
        </con-entity-search-by-field>
      </div>
      <div class="col-md-2" *ngIf='companyId === null'>
        <small class='filter-text-color'>Company Name</small>
        <con-entity-search-by-field
          [field]="getFieldData('company.name')"
          [control]="getField('searchInfo', 'companyName')">
        </con-entity-search-by-field>
      </div>
      <div class="col-md-2">
        <small class='filter-text-color'>Account Standard</small>
        <con-entity-search-by-field
          [field]="getFieldData('accounting_standard')"
          [control]="getField('searchInfo', 'accountStandard')">
        </con-entity-search-by-field>
      </div>
      <div class="col-md-2">
        <small class='filter-text-color'>Period End Date</small>
        <con-entity-search-by-field
          [field]="getFieldData('period_end_date')"
          [control]="getField('searchInfo', 'periodEndDate')">
        </con-entity-search-by-field>
      </div>
      <div class="col-md-2">
        <small class='filter-text-color'>Comment</small>
        <con-entity-search-by-field
          [field]="getFieldData('comment')"
          [control]="getField('searchInfo', 'comment')">
        </con-entity-search-by-field>
      </div>
      <div class="col-md-2">
        <small class='filter-text-color'>Data Entry Status</small>
        <con-entity-search-by-field
          [field]="getFieldData('data_entry_status')"
          [control]="getField('searchInfo', 'dataEntryStatus')">
        </con-entity-search-by-field>
      </div>
    </div>
  </div>
  <div class='row'>
    <div class='col-md-2 mt-18'>
      <small class='filter-text-color'>Taxonomy Name</small>
      <con-entity-search-by-field
        [field]="getFieldData('taxonomy_id')"
        [control]="getField('searchInfo', 'taxonomyId')">
      </con-entity-search-by-field>
    </div>
    <div class='col-md-2 mt-18'>
      <small class='filter-text-color'>Claim Status</small>
      <con-entity-search-by-field
        [field]="getFieldData('claimed_by')"
        [control]="getField('searchInfo', 'claimedBy')">
      </con-entity-search-by-field>
      <ng-container *ngIf="itemsForm.get('searchInfo')?.get('claimedBy')?.value === '' || itemsForm.get('searchInfo')?.get('claimedBy')?.value === 'Claimed'">
       <div class='mt-10'>
        <label for="claimedByMe"><small  class='filter-text-color'>Claimed By Me</small></label>
        <input type="checkbox" id="claimedByMe" [formControl]="getField('searchInfo', 'claimedByMe')">
       </div>
      </ng-container>
    </div>
    <div class='col-md-8'>
      <div formArrayName="sortKeys">
        <table class='mt-15 full-width'>
          <tr>
            <td class='w-190'>
              <small class='pt-5 filter-text-color' >Sort</small>
            </td>
            <td class='w-100-p'> &nbsp;</td>
            <td> &nbsp;</td>
            <td class='w-100-p'> &nbsp;</td>
          </tr>
          <tr class='pb-10' *ngFor="let item of itemsForm.get('sortKeys')?.controls; let i = index;" [formGroupName]="i">
            <td class="pr-15">
              <div class="input-group">
                <div class="input-group-prepend">
          <span class="input-group-text">
            <con-icon-by-name iconName="sort-alpha-desc" [mutations]="['fw']"></con-icon-by-name>
          </span>
                </div>
                <select class="form-control custom-select" [formControl]="item.get('columnName')" (change)="onColumnNameChange(i, item.get('columnName').value)">
                  <option *ngFor="let option of columnNameOptions" [value]="option.value" [hidden]="selectedOptions.includes(option)">{{option.name}}</option>
                </select>
              </div>
            </td>
            <td class="pr-15">

              <div class="input-group">
                <select class="form-control custom-select" [formControl]="item.get('sortOrder')">
                  <option *ngFor="let option of sortOrderOptions" [value]="option.value">{{option.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <button type="button"  (click)="removeItem(i)" class='btn btn-danger pull-left mr-5'  *ngIf="showRemoveButton"><con-icon-by-name iconName="minus-circle"></con-icon-by-name> </button>
              <button type="button" *ngIf="itemsForm.get('sortKeys')?.controls.length - 1 === i" (click)="addItem()" class='btn btn-success pull-left' [disabled]="disableAddButton"><con-icon-by-name iconName="plus-circle"></con-icon-by-name> </button>
            </td>
            <td>
              <button class='btn btn-primary ml-15 pull-right' *ngIf="itemsForm.get('sortKeys')?.controls.length - 1 === i">Filter</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>


</form>
</ng-container>
