<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="calendar"></con-icon-by-name>
        </div>
        <h3 class="mb-0">Company Report Standards</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="card">
        <div class="card-header">
          Go to company report standards
        </div>
        <div class="card-body">
          <con-entity-search-in-relation
            parentEntityName="CompanyTier"
            [parentId]="companyTierId"
            relationName="companies"
            [disabled]="!profile.can(profile.getPermissionName('CompanyTier', 'show', 'companies'))"
            (onSelect)="navigateToCompany($event)"
          ></con-entity-search-in-relation>
        </div>
      </div>
    </div>
  </div>

  <div class="row" >
    <div class="col-12">
      <div class="ml-auto">
        <div class="chip chip-inverse chip-link pull-right"  (click)="addNewEvent()">
          <i class="fa fa-plus"></i>
          Add New Report Standard
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-15" >
    <div class="col-12">
      <!-- show add report event -->

      <div>

        <div class="card mb-2" *ngIf="showAddEvent">
          <div class="card-header" (click)="toggleType()">
            <div class="d-flex justify-content-start align-items-center">
              <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
              Add New Report Standard
              <div class="card-header-toggle-individual ml-auto">
                <con-icon-by-name iconName="angle-right" [mutations]="getMutations()"></con-icon-by-name>
                <con-icon-by-name ngbTooltip="Remove Window" iconName="remove" (click)="removeAddEvent()"></con-icon-by-name>
              </div>
            </div>
          </div>

          <div class="card-body" *ngIf="expandAddForm">
            <con-entity-form-content [resetKey]="resetKey" entityName="CompanyReportingStandard"
                                     [showWhenChanged]="false"
                                     [canDelete]="false" emptyAfterSave="true" (afterSave)="reportingStandardCreated($event)"
                                     toastMessage="Reporting standard added successfully!">
            </con-entity-form-content>
          </div>
        </div>
      </div>

      <div class='row'>
        <div class='col-12'>
          <div class="card mb-2" >
            <con-custom-reporting-entity-listing entityName='CustomCompanyReportingStandard' [runUpdate]='runUpdate' ></con-custom-reporting-entity-listing>
          </div>
        </div>
      </div>
    </div>




  </div>
</div>
