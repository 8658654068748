import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { CompanyReportsService } from '../../services/company-reports.service';
import { EntitiesService } from '../../../entities/services/entities.service';
import { map, take } from 'rxjs/operators';
import { TaxonomyType } from '../../models/TaxonomyType';
import { Router } from '@angular/router';
import { ProfileService } from '../../../auth/services/profile.service';
@Component({
  selector: 'con-report-event-filter',
  templateUrl: './report-event-filter.component.html',
  styleUrls: ['./report-event-filter.component.scss']
})
export class ReportEventFilterComponent implements OnInit {
  @Input() companyId = null;
  @Output() filterSearchTerm = new EventEmitter<string>();
  loading = true;
  isErrored = false;
  itemsForm: FormGroup;
  taxonomyType = TaxonomyType;
  prefillData = [ {
    "type": "day",
    "is_searchable": false,
    "is_filterable": true,
    "is_primary": false,
    "disabled": false,
    "show_in_table": true,
    "show_in_store": true,
    "show_in_update": true,
    "key": "report_date",
    "label": "Report Date",
    "rules": [
      "string",
      "date",
      "required",
      "date_format:Y-m-d"
    ],
    "format": "Y-m-d"
  },
    {
      "type": "string",
      "is_searchable": true,
      "is_filterable": true,
      "is_primary": true,
      "disabled": false,
      "show_in_table": true,
      "show_in_store": false,
      "show_in_update": false,
      "key": "company.name",
      "label": "Comp. Name",
      "rules": [
        "string",
        "nullable"
      ]
    },
    {
      "type": "enum",
      "is_searchable": false,
      "is_filterable": true,
      "is_primary": false,
      "disabled": false,
      "show_in_table": true,
      "show_in_store": true,
      "show_in_update": true,
      "key": "accounting_standard",
      "label": "Acc. Std",
      "rules": [
        "alpha_dash_space",
        "in:ifrs,us_gaap,swiss_gaap_fer,japanese_gaap,jmis,hkfrs,china_gaap,ind_as,tfrs,hgb",
        "required"
      ],
      "values": [
        {
          "value": "ifrs",
          "label": "IFRS"
        },
        {
          "value": "us_gaap",
          "label": "U.S. GAAP"
        },
        {
          "value": "swiss_gaap_fer",
          "label": "Swiss GAAP FER"
        },
        {
          "value": "japanese_gaap",
          "label": "Japanese GAAP"
        },
        {
          "value": "jmis",
          "label": "JMIS"
        },
        {
          "value": "hkfrs",
          "label": "HKFRS"
        },
        {
          "value": "china_gaap",
          "label": "China GAAP"
        },
        {
          "value": "ind_as",
          "label": "Ind AS"
        },
        {
          "value": "tfrs",
          "label": "TFRS"
        },
        {
          "value": "hgb",
          "label": "HGB"
        }
      ]
    }, {
      "type": "day",
      "is_searchable": false,
      "is_filterable": true,
      "is_primary": false,
      "disabled": false,
      "show_in_table": true,
      "show_in_store": true,
      "show_in_update": true,
      "key": "period_end_date",
      "label": "Per. End Date",
      "rules": [
        "string",
        "date",
        "required",
        "date_format:Y-m-d"
      ],
      "format": "Y-m-d"
    },{
      "type": "text",
      "is_searchable": false,
      "is_filterable": true,
      "is_primary": false,
      "disabled": false,
      "show_in_table": true,
      "show_in_store": true,
      "show_in_update": true,
      "key": "comment",
      "label": "Comment",
      "rules": [
        "string",
        "nullable"
      ]
    },
    {
      "type": "enum",
      "is_searchable": false,
      "is_filterable": true,
      "is_primary": false,
      "disabled": false,
      "show_in_table": true,
      "show_in_store": false,
      "show_in_update": true,
      "key": "data_entry_status",
      "label": "Status",
      "rules": [
        "alpha_dash_space",
        "in:Not Started,Ongoing,Verification,Completed,",
        "nullable"
      ],
      "values": [
        {
          "value": "Not Started",
          "label": "Not Started"
        },
        {
          "value": "Data Entry",
          "label": "Data Entry"
        },
        {
          "value": "Verify",
          "label": "Verify"
        }
      ]
    }, {
      "type": "enum",
      "is_searchable": false,
      "is_filterable": true,
      "is_primary": false,
      "disabled": false,
      "show_in_table": true,
      "show_in_store": false,
      "show_in_update": true,
      "key": "claimed_by",
      "label": "Status",
      "rules": [
        "alpha_dash_space",
        "in:Not Started,Ongoing,Verification,Completed,",
        "nullable"
      ],
      "values": [
        {
          "value": "Claimed",
          "label": "Claimed"
        },
        {
          "value": "UnClaimed",
          "label": "UnClaimed"
        }
      ]
    }
  ]
  selectedOptions = [];
  columnNameOptions = [
    {name: 'Market Cap', value: 'marketcap'},
    {name: 'Report Date', value: 'report_date'},
    {name: 'Period End Date', value: 'period_end_date'},
  ];
  sortOrderOptions = [
    {name: 'Asc', value: 'asc'},
    {name: 'Desc', value: 'desc'},
  ];
  disableAddButton = false;
  showRemoveButton = false;
  queryString = '';

  constructor(private formBuilder: FormBuilder,private metaService: EntitiesService, private router: Router, private taxonomyService: CompanyReportsService) {}

  ngOnInit() {
    this.loading = true;
    const taxonomyData = [];
    this.metaService.getEntityDescriptionByEntityName('Taxonomy')
      .pipe(map(entityDescription => {
        return entityDescription
          .getData()
          .fields
          .find(f => f.key === 'taxonomy_type');
      }))
      .subscribe((types) => {
        const incomeStatementIndex = types.values.findIndex((item) => item.value === this.taxonomyType.INCOME_STATEMENT);
        if(incomeStatementIndex !== -1) {
          this.taxonomyService.getTaxonomyNameIncomeStatement(this.taxonomyType.INCOME_STATEMENT,1, 10000).subscribe((resultData) => {

            resultData.data.forEach((item) => {
              taxonomyData.push({
                value: item.id,
                label: item.name
              })
            })
           this.setFormGroup(taxonomyData);
          }, (error) => {
            console.error(error);
            this.isErrored = true;
          })
        } else {
          this.setFormGroup(taxonomyData);
        }
      });
  }

  setFormGroup(taxonomyData) {
    const taxonomyPrefillData = {
      "type": "enum",
      "is_searchable": false,
      "is_filterable": true,
      "is_primary": false,
      "disabled": false,
      "show_in_table": true,
      "show_in_store": true,
      "show_in_update": true,
      "key": "taxonomy_id",
      "label": "Taxonomy Name",
      "rules": [
        "numeric",
        "required"
      ],
      "values": taxonomyData
    }
    this.prefillData.push(taxonomyPrefillData);
    this.loading = false;

    this.itemsForm = this.formBuilder.group({
      searchInfo: this.formBuilder.group({
        reportDate: this.taxonomyService.getReportEventsFilterState.reportDate,
        companyName: this.taxonomyService.getReportEventsFilterState.companyName,
        accountStandard: this.taxonomyService.getReportEventsFilterState.accountStandard,
        periodEndDate: this.taxonomyService.getReportEventsFilterState.periodEndDate,
        comment: this.taxonomyService.getReportEventsFilterState.comment,
        dataEntryStatus: this.taxonomyService.getReportEventsFilterState.dataEntryStatus,
        taxonomyId: this.taxonomyService.getReportEventsFilterState.taxonomyId,
        claimedBy: this.taxonomyService.getReportEventsFilterState.claimedBy,
        claimedByMe: this.taxonomyService.getReportEventsFilterState.claimedByMe
      }),
      sortKeys: this.formBuilder.array([])
    });
    (this.taxonomyService.getReportEventsFilterState.sortTerms as Array<any>).forEach((sortItem)=> {
      (this.itemsForm.get('sortKeys') as FormArray).push(this.formBuilder.group(sortItem));
      const selectedOptionItem =  this.columnNameOptions.find(item => item.value === sortItem.columnName);
      this.selectedOptions.push(selectedOptionItem);
    })
    this.processFormValues();
  }
  addItem() {
    const remainingItems = this.columnNameOptions.filter(obj1 => !this.selectedOptions.some(obj2 => obj1.value ==obj2.value));
    if(remainingItems.length) {
      this.selectedOptions.push(remainingItems[0]);
    }
    if(remainingItems.length === 1) {
      this.disableAddButton = true;
    }
    (this.itemsForm.get('sortKeys') as FormArray).push(this.formBuilder.group({
      columnName: [remainingItems.length ? remainingItems[0].value : ''],
      sortOrder: ['desc']
    }));
    this.showRemoveButton = true;
  }
  removeItem(index: number) {
    (this.itemsForm.get('sortKeys') as FormArray).removeAt(index);
    const removedOption = this.selectedOptions[index];
    if(removedOption && !this.columnNameOptions.find((item) => item.value === removedOption.value)) {
      this.columnNameOptions.push(removedOption);
    }
    this.selectedOptions[index] = null;
    this.selectedOptions = this.selectedOptions.filter(x => x);
    const remainingItems = this.columnNameOptions.filter(obj1 => !this.selectedOptions.some(obj2 => obj1.value ==obj2.value));
    if(remainingItems.length > 0) {
      this.disableAddButton = false;
    }
    if((this.itemsForm.get('sortKeys') as FormArray).value.length === 1){
      this.showRemoveButton = false;
    }
  }
  onColumnNameChange(i: number, value: any) {
    (this.itemsForm.get('sortKeys') as FormArray).controls[i].get('columnName').setValue(value);
    const selectedOption = this.columnNameOptions.find(o => o.value === value);
    this.selectedOptions[i] = selectedOption;
  }
  isMatchingRoute(): boolean {
    const idPattern = '\\d+';
    const viewPattern = '\\w+';
    const reportEventPattern = new RegExp(`/company_reports/report-event/${idPattern}\\?view=${viewPattern}`);

    return reportEventPattern.test(this.router.url);
  }
  processFormValues() {
    this.queryString = '';
    const searchTerms = this.itemsForm.get('searchInfo').value;
    const sortTerms = this.itemsForm.get('sortKeys').value;
    if(searchTerms.reportDate) {
      this.queryString += (this.queryString ? '&' : '') +'q:report_date=' + searchTerms.reportDate;
    }
    // check page
    if(searchTerms.companyName && !this.isMatchingRoute()) {
      this.queryString += (this.queryString ? '&' : '') +'q:company:name=' + searchTerms.companyName;
    }
    if(searchTerms.accountStandard) {
      this.queryString += (this.queryString ? '&' : '') +'q:accounting_standard=' + searchTerms.accountStandard;
    }
    if(searchTerms.taxonomyId) {
      this.queryString += (this.queryString ? '&' : '') +'q:taxonomy_id=' + searchTerms.taxonomyId;
    }
    if(searchTerms.periodEndDate) {
      this.queryString += (this.queryString ? '&' : '') +'q:period_end_date=' + searchTerms.periodEndDate;
    }
    if(searchTerms.comment) {
      this.queryString += (this.queryString ? '&' : '') +'q:comment=' + searchTerms.comment;
    }
    if(searchTerms.dataEntryStatus) {
      this.queryString += (this.queryString ? '&' : '') +'q:data_entry_status=' + searchTerms.dataEntryStatus;
    }

    if(searchTerms.claimedBy) {
      if(searchTerms.claimedBy === 'UnClaimed') {
        this.queryString += (this.queryString ? '&' : '') + 'claimed_by=' + 'unclaimed';
      } else {
        // claimed case
        this.queryString += (this.queryString ? '&' : '') + 'claimed_by=' + 'claimed';
        this.queryString += (this.queryString ? '&' : '') + 'claimed_by_me=' + searchTerms.claimedByMe;
      }
    } else {
      this.queryString += (this.queryString ? '&' : '') + 'claimed_by=' + 'all';
      this.queryString += (this.queryString ? '&' : '') + 'claimed_by_me=' + searchTerms.claimedByMe;
    }
    this.queryString += (this.queryString ? '&' : '') + 'custom_sort=';
    for(let i = 0 ; i< sortTerms.length; i++) {
      this.queryString += (i !== 0? ',' : '') + sortTerms[i].columnName + ' ' + sortTerms[i].sortOrder;
    }
    this.queryString = '?'.concat(this.queryString);
    this.filterSearchTerm.emit(this.queryString);

    const filterObject = {
      reportDate: searchTerms.reportDate,
      companyName: searchTerms.companyName,
      accountStandard: searchTerms.accountStandard,
      periodEndDate: searchTerms.periodEndDate,
      comment: searchTerms.comment,
      dataEntryStatus: searchTerms.dataEntryStatus,
      taxonomyId: searchTerms.taxonomyId,
      sortTerms: sortTerms,
      claimedBy: searchTerms.claimedBy,
      claimedByMe: searchTerms.claimedByMe
    }
    this.taxonomyService.setReportEventsFilterState(filterObject);
  }
  getField(parentKey, childKey) {
    return this.itemsForm.get(parentKey).get(childKey) as FormControl;
  }
  getFieldData(fieldData: string) {
    return this.prefillData.find(o => o.key === fieldData);
  }
}
