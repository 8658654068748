<div class="sidebar">
    <con-loader *ngIf="loading"></con-loader>
    <nav class="nav flex-column" *ngIf="!loading">
        <div class="d-flex justify-content-start align-items-center">
            <a class="nav-link flex-grow-1" [routerLink]="['missing-share-count']" routerLinkActive="active">
            Missing <span class="badge badge-pill badge-danger">{{counts?.missing}}</span>
        </a>
            <div class="ml-auto align-self-stretch" *ngIf="showRefreshButton('missing-share-count')">
                <button class="btn btn-sm btn-dark" ngbTooltip="Refresh Events" container="body" placement="right" (click)="this.loadPage('missing', $event)">
                <con-icon-by-name iconName="refresh"></con-icon-by-name>
            </button>
            </div>
        </div>

        <div class="d-flex justify-content-start align-items-center">
            <a class="nav-link flex-grow-1" [routerLink]="['update-share-count']" routerLinkActive="active">
                Update <span class="badge badge-pill badge-danger">{{counts?.outdated}}</span>
            </a>
            <div class="ml-auto align-self-stretch" *ngIf="showRefreshButton('update-share-count')">
                <button class="btn btn-sm btn-dark" ngbTooltip="Refresh Events" container="body" placement="right" (click)="this.loadPage('outdated', $event)">
                <con-icon-by-name iconName="refresh"></con-icon-by-name>
            </button>
            </div>
        </div>

        <div class="d-flex justify-content-start align-items-center">
            <a class="nav-link flex-grow-1" [routerLink]="['duplicate-primary-instrument']" routerLinkActive="active">
                Duplicate Primaries <span class="badge badge-pill badge-danger">{{counts?.duplicate_primaries}}</span>
                </a>
            <div class="ml-auto align-self-stretch" *ngIf="showRefreshButton('duplicate-primary-instrument')">
                <button class="btn btn-sm btn-dark" ngbTooltip="Refresh Events" container="body" placement="right" (click)="this.loadPage('duplicate_primaries', $event)">
                <con-icon-by-name iconName="refresh"></con-icon-by-name>
            </button>
            </div>
        </div>

        <div class="d-flex justify-content-start align-items-center">
            <a class="nav-link flex-grow-1" [routerLink]="['missing-primary-instrument']" routerLinkActive="active">
                Missing Primaries <span class="badge badge-pill badge-danger">{{counts?.missing_primaries}}</span>
                </a>
            <div class="ml-auto align-self-stretch" *ngIf="showRefreshButton('missing-primary-instrument')">
                <button class="btn btn-sm btn-dark" ngbTooltip="Refresh Events" container="body" placement="right" (click)="this.loadPage('missing_primaries', $event)">
                <con-icon-by-name iconName="refresh"></con-icon-by-name>
            </button>
            </div>
        </div>

      <div class="d-flex justify-content-start align-items-center">
        <a class="nav-link flex-grow-1" [routerLink]="['exchange-rate']" routerLinkActive="active">
          Exchange Rate
        </a>
      </div>

    </nav>
</div>
<div class="content">
    <router-outlet></router-outlet>
</div>
