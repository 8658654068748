import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { LoaderComponent } from './components/loader.component';
import { FormErrorsComponent } from './components/form-errors.component';
import { FlexibleInputComponent } from './components/flexible-input.component';
import { IconByNameComponent } from './components/icon-by-name.component';
import { ObjectInspectorComponent } from './components/object-inspector.component';
import { FileUploaderComponent } from './components/file-uploader.component';
import { AvatarComponent } from './components/avatar.component';
import { CheckboxInputComponent } from './components/checkbox-input.component';
import { ConversationComponent } from './components/conversation.component';
import { JsonDifferenceComponent } from './components/json-difference.component';
import { DateInputComponent } from './components/date-input.component';
import { DayInputComponent } from './components/day-input.component';
import { DateShowerComponent } from './components/date-shower.component';
import { NoDataComponent } from './components/no-data.component';

import { ResolveLinkToFileDirective } from './directives/resolve-link-to-file.directive';

import { FileUploadModule } from 'ng2-file-upload';
import { FileSelectDirective } from 'ng2-file-upload';

import { UsersService } from './services/users.service';
import { DateConverterService } from './services/date-converter.service';

import { CapitalizePipe } from './pipes/capitalize.pipe';
import { PrettyCasePipe } from './pipes/pretty-case.pipe';
import { SnakeCasePipe } from './pipes/snake-case.pipe';
import { FormatAsFieldPipe } from './pipes/format-as-field.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { StringifyPipe } from './pipes/stringify.pipe';

import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

import { MomentModule } from 'ngx-moment';

import * as moment from 'moment';
import { IMaskModule } from 'angular-imask';
import { ListRadioSelectionComponent } from './components/list-radio-selection.component';
import { SafePipe } from './pipes/safe.pipe';
import { MlDirectoryPreviewComponent } from './components/ml-directory-preview.component';
import { CustomSearchSelectComponent } from './components/custom-search-select.component';
import { ExcerptPipe } from './pipes/excerpt.pipe';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MultiFileUploaderComponent } from './components/multi-file-uploader.component';
import { HtmlToPlainTextPipe } from './pipes/html-to-plain-text.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ConfirmationPopupComponent } from './components/confirmation-popup.component';
import { ConvertToTimezonePipe } from './pipes/convert-to-timezone.pipe';
import { RefIdInputComponent } from './components/ref-id-input.component';
moment.updateLocale('en', {
  longDateFormat : {
    LT: 'hh:mm',
    LTS: 'hh:mm:ss',
    L: 'yyyy-MM-DD',
    l: 'yyyy-MM-DD',
    LL: 'MMMM Do yyyy',
    ll: 'MMM D yyyy',
    LLL: 'MMMM Do yyyy LT',
    lll: 'MMM D yyyy LT',
    LLLL: 'dddd, MMMM Do yyyy LT',
    llll: 'ddd, MMM D yyyy LT'
  }
});

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    MomentModule,
    HighlightModule,
    FileUploadModule,
    NgSelectModule,
    IMaskModule,
    FormsModule,
    CodemirrorModule,
    NgxFileDropModule,
    AngularEditorModule
  ],
  exports: [KeysPipe,
    SafePipe,
    CapitalizePipe,
    PrettyCasePipe,
    SnakeCasePipe,
    FormatAsFieldPipe,
    StringifyPipe,
    LoaderComponent,
    FormErrorsComponent,
    FlexibleInputComponent,
    IconByNameComponent,
    ObjectInspectorComponent,
    FileUploaderComponent,
    AvatarComponent,
    CheckboxInputComponent,
    ConversationComponent,
    JsonDifferenceComponent,
    ResolveLinkToFileDirective,
    FileSelectDirective,
    DateInputComponent,
    DayInputComponent,
    DateShowerComponent,
    NoDataComponent, ListRadioSelectionComponent, MlDirectoryPreviewComponent, CustomSearchSelectComponent,HtmlToPlainTextPipe,  ExcerptPipe, ConvertToTimezonePipe],
  declarations: [KeysPipe,
    CapitalizePipe,
    PrettyCasePipe,
    SnakeCasePipe,
    FormatAsFieldPipe,
    StringifyPipe,
    LoaderComponent,
    FormErrorsComponent,
    FlexibleInputComponent,
    IconByNameComponent,
    ObjectInspectorComponent,
    FileUploaderComponent,
    AvatarComponent,
    CheckboxInputComponent,
    ConversationComponent,
    JsonDifferenceComponent,
    ResolveLinkToFileDirective,
    DateInputComponent,
    DayInputComponent,
    DateShowerComponent,
    NoDataComponent,
    ListRadioSelectionComponent,
    MlDirectoryPreviewComponent,
    SafePipe,
    CustomSearchSelectComponent,
    ExcerptPipe,
    MultiFileUploaderComponent,
    HtmlToPlainTextPipe,
    ConfirmationPopupComponent,
    ConvertToTimezonePipe,
    RefIdInputComponent
  ],
  providers: [
    UsersService,
    DateConverterService,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json')
        }
      }
    }
  ]
})
export class SharedModule { }
