import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { featureFlagSettings } from 'app/settings.class';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private featureFlags$ = new BehaviorSubject<string[]>([]);

  constructor(private http: HttpClient) {
    this.initializeFeatureFlags();
  }

  private initializeFeatureFlags(): void {
    this.fetchFeatureFlags().pipe(take(1)).subscribe();
  }

  private fetchFeatureFlags(): Observable<any> {
    const params = new HttpParams()
      .set('q:enabled', 'f:true')
      .set('page', '1')
      .set('per_page', '10000');
    return this.http.get(`${featureFlagSettings.BASE_URL}`, { params }).pipe(
      tap((flags: any) => {
        const featureFlags = flags?.data?.map((flag: any) => flag.key);
        this.featureFlags$.next(featureFlags);
      }),
      catchError(error => {
        this.featureFlags$.next([]);
        return of({});
      })
    );
  }

  isFeaturesEnabled(features: string[]): Observable<{ [key: string]: boolean }> {
    return this.featureFlags$.pipe(
      map((flags: string[]) => {
        const featureStatus: { [key: string]: boolean } = {};
        features.forEach(feature => {
          featureStatus[feature] = flags.includes(feature);
        });
        return featureStatus;
      })
    );
  }
  
}
