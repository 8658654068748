
<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="calendar"></con-icon-by-name>
        </div>
        <h3 class="mb-0">Company Reporting Standard</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-start sticky-header py-2">
        <h3 class="mb-0">
          <span class="badge badge-dark">
            {{ entity?.name }}
          </span>
          <span *ngIf="entity?.website"><a [href]=entity?.website class="link-fontsize" target="_blank">{{companyService.getStripedWebsiteString(entity?.website)}}</a></span>
        </h3>
        <h6 class="mb-0 ml-15" *ngIf='reportingStandard?.is_semi_annual === true'><span class="badge primary-badge mt-10">Bi Annual Reporting Standard</span></h6>
        <h6 class="mb-0 ml-15" *ngIf='reportingStandard?.is_semi_annual === false || reportingStandard?.is_semi_annual === null'><span class="badge primary-badge mt-10">Normal Reporting Standard</span></h6>
        <div class="ml-auto">
          <div class="chip chip-inverse chip-link"  (click)="addNewEvent()">
            <i class="fa fa-plus"></i>
            Add New Report Standard
          </div>
        </div>
      </div>
      <div class="row mt-15" >
        <div class="col-12">
          <!-- show add reporting standard -->

          <div>

            <div class="card mb-2" *ngIf="showAddEvent">
              <div class="card-header" (click)="toggleType()">
                <div class="d-flex justify-content-start align-items-center">
                  <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                  Add New Report Standard
                  <div class="card-header-toggle-individual ml-auto">
                    <con-icon-by-name iconName="angle-right" [mutations]="getMutations()"></con-icon-by-name>
                    <con-icon-by-name ngbTooltip="Remove Window" iconName="remove" (click)="removeAddEvent()"></con-icon-by-name>
                  </div>
                </div>
              </div>

              <div class="card-body" *ngIf="expandAddForm">
                <con-entity-form-content [resetKey]="resetKey" entityName="CompanyReportingStandard"
                                          [parentCompany]="{id: entity?.id, name: entity?.name}"
                                          [fixedValues]="{ company_id: entity?.id}" [showWhenChanged]="false"
                                          [canDelete]="false" emptyAfterSave="true" (afterSave)="reportingStandardCreated($event)"
                                          toastMessage="Reporting standard added successfully!">>
                </con-entity-form-content>
              </div>
            </div>
          </div>

          <div class='row'>
            <div class='col-12'>
              <h3>History</h3>
              <div class="card mb-2" >
                <con-entity-list [runUpdate]=runUpdate entityName='CompanyReportingStandard' originEntityName='Company' relation='ReportingStandards' [entityId]=companyId ></con-entity-list>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='row mt-15'>
        <div class='col-12'>
          <h3>Anomalies</h3>
          <div class="card">
            <div class="table-responsive">
              <table class="table">
                <thead  class="thead-light">
                <tr>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Is Semi Annual</th>
                  <th class='anomaly-th'>Anomalies</th>
                </tr>
                </thead>
                <con-loader *ngIf="mismatchLoading"></con-loader>
                <tbody *ngIf="!mismatchLoading">
                <ng-container *ngFor="let anomaly of mismatchData?.data">
                  <tr>
                    <td>{{formatDateTime(anomaly?.from_date) }}</td>
                    <td>{{formatDateTime(anomaly?.to_date)}}</td>
                    <td>{{anomaly?.is_semi_annual.toString() | titlecase}}</td>
                    <td>
                      <ol class='anomaly-val'>
                        <li *ngFor="let log of anomaly?.anomalies">
                          <a routerLink='../../../../company_reports/{{log?.company_id}}/report/{{log?.id}}/income-statement'>{{ log?.report_type }} - {{ log?.report_date }}</a>
                        </li>
                      </ol>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
            <con-no-data count='0' *ngIf="!mismatchLoading && errorMessage"  [message]="errorMessage"></con-no-data>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
