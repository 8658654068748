<div class="container-fluid mb-3">
    <div>
        <div class="row">
            <div class="col-12">
                <div class="page-title mb-2 pb-3 pt-3">
                    <h3 class="mb-0">Cash Flow</h3>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-start sticky-header non-sticky py-2">
            <h3><span class="badge badge-dark">{{getDisplayTitle()}}</span></h3>
            <h6 class="mb-0 ml-15" *ngIf='entity?.is_semi_annual === true'><a target='_blank' routerLink='../../../reporting-standard/company/{{entity?.company_id}}'><span class="badge primary-badge mt-10">Bi Annual Reporting Standard</span></a> </h6>
            <h6 class="mb-0 ml-15" *ngIf='entity?.is_semi_annual === false || entity?.is_semi_annual === null'><a target='_blank' routerLink='../../../reporting-standard/company/{{entity?.company_id}}'><span class="badge primary-badge mt-10">Normal Reporting Standard</span></a></h6>
            <span *ngIf="entity?.company?.website" class="website-link-positioning">
              <a [href]=entity?.company?.website class="link-fontsize" target="_blank">
                {{companyService.getStripedWebsiteString(entity?.company?.website)}}</a>
            </span>
            <div class="ml-auto">
              <a [href]='entity?.task_backlink' target='_blank' *ngIf='entity?.task_backlink'>
                <div class="chip chip-inverse">
                  <con-icon-by-name iconName="external-link"></con-icon-by-name>
                  Task
                </div>
              </a>
              <div class="chip chip-inverse">
                <con-avatar [userId]="entity.company.manager?.id" [size]="32"></con-avatar>
                {{ entity.company.manager?.first_name }} {{ entity.company.manager?.last_name}}
              </div>
            </div>
        </div>
        <div class="alert ml-status-created" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.CREATED">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.CREATED_MESSAGE}}
          <button class='btn btn-primary pull-right cancel-btn-pos' (click)='companyService.cancelRequest(this.entity.id)'>Cancel</button>
        </div>
        <div class="alert ml-status-in-progress" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.IN_PROGRESS">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.IN_PROGRESS_MESSAGE}}
          <button class='btn btn-primary pull-right cancel-btn-pos' (click)='companyService.cancelRequest(this.entity.id)'>Cancel</button>
        </div>
        <div class="alert ml-status-submitted" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.SUBMITTED">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.SUBMITTED_MESSAGE}}
        </div>
        <div class="alert ml-status-closed" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.CLOSED">
          <con-icon-by-name iconName="check"></con-icon-by-name> {{MLSendStatus.CLOSED_MESSAGE}}
        </div>
        <div class="alert ml-status-failed" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.FAILED">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.FAILED_MESSAGE}}
        </div>
        <con-no-data *ngIf="entity?.locked && !settingLock && !loading" count="0" class="locked-report"></con-no-data>
        <con-loader *ngIf="loading || settingLock"></con-loader>
        <div class="row" *ngIf="!settingLock">
            <div class="col-12">
                <div *ngIf="!loading">
                    <div class="card mb-2" *ngIf="!entity?.locked && !entitiesService.entityLockedByMl(entity)">
                        <div class="card-header" (click)="toggleType()">
                            <div class="d-flex justify-content-start align-items-center">
                                <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>New Cash Flow
                                <div class="card-header-toggle-individual ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="p-4 col-6">
                            <con-entity-form-content entityName="CashFlow" [entity]="entityCompanyReport"
                                [syntheticEnabled]="true"
                                [parentCompany]="{id: entity.company.id, name: entity.company.name, report_type: entity.report_type}"
                                [fixedValues]="{ company_report_id: entity.id }" [showWhenChanged]="false"
                                [canDelete]="false" emptyAfterSave="true" (afterSave)="cashFlowCreated($event)"
                                toastMessage="Cash flow added successfully!">
                            </con-entity-form-content>
                        </div>
                    </div>

                    <div *ngIf="!loading">
                        <div *ngFor="let cashFlow of companyReport.data; let index = index">
                            <div>
                                <div class="card mb-2"
                                     [ngClass]="getHeaderClass(cashFlow)">
                                    <div class="card-header"
                                        (click)="toggleType(cashFlow); getCashFlowData(cashFlow)">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <con-icon-by-name iconName="database" [mutations]="['fw']"  *ngIf='!cashFlow?.is_synthetic' class="mr-1 cursor-pointer" (click)="companyService.goToEntity($event, 'cash_flow', cashFlow?.id)"></con-icon-by-name>
                                            <span class='is_synthetic_icon' *ngIf='cashFlow?.is_synthetic'>S</span>
                                            {{getDisplayName(cashFlow)}}
                                            <span class="badge badge-pill timeline-pill badge-primary ml-annotation-failed ml-2"
                                                  ngbTooltip="Problem with the labeling of the report"
                                                  *ngIf="cashFlow?.is_fully_annotated !== null && !cashFlow?.is_fully_annotated && cashFlow?.is_fully_annotated !== undefined"
                                            >
                                              <con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>
                                              NOT FULLY ANNOTATED
                                            </span>
                                            <div class="card-header-toggle-individual ml-auto">
                                                <con-icon-by-name iconName="angle-right"
                                                    [mutations]="getMutations(cashFlow)"></con-icon-by-name>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="display-flex row m-0">
                                        <div class="p-4 col-6" *ngIf="!cashFlow.hide">
                                            <con-entity-form-content [entity]="cashFlow" entityName="CashFlow"
                                                [fixedValues]="{ company_report_id: entity.id }" [showWhenChanged]="false"
                                                [canDelete]="false" (afterSave)="cashFlowUpdated($event, index)"
                                                toastMessage="Cash flow updated successfully!"
                                                [canEdit]="!entity?.locked && !entitiesService.entityLockedByMl(entity)">
                                            </con-entity-form-content>
                                        </div>
                                        <div class="col-6" *ngIf="!cashFlow?.hide && !entity?.locked && !entitiesService.entityLockedByMl(entity)">
                                          <div class="mt-15">
                                            <button class='btn btn-primary pull-right' (click)='generateSyntheticStatement(cashFlow)' *ngIf='cashFlow?.cashFlowData?.synthetic_eligible'>Generate Synthetic</button>
                                            <button class='btn btn-primary pull-right mr-15' (click)='calculateSyntheticStatement(cashFlow)' *ngIf='cashFlow?.cashFlowData?.is_synthetic'>Calculate Synthetic</button>
                                            <con-common-comment (commentUpdated)="updateCommentValue($event, index)" [commentInfo]="cashFlow?.comment" [id]="cashFlow?.id" updateUrl="cash_flow"></con-common-comment>
                                          </div>
                                        </div>
                                    </div>
                                    <con-loader *ngIf="cashFlow.loading"></con-loader>
                                    <div class="card-body mb-2"
                                        *ngIf="!cashFlow.hide && !cashFlow.loading">
                                        <div class="card-header">
                                            {{getCFTitle(cashFlow)}}
                                        </div>
                                        <div class="card-body col-md-12" *ngIf="cashFlow.cashFlowData && cashFlow.cashFlowData.modules">
                                          <div>
                                            <con-custom-search-select (onSelect)="appendPreviewUrl($event, cashFlow)" [id]="cashFlow?.company_report_id" ></con-custom-search-select>
                                            <con-ml-directory-preview [displayMLPreviewUrl]="cashFlow?.previewUrl" (closePreviewFile)="setPreviewMLReportUrlEmpty(cashFlow, $event)"></con-ml-directory-preview>
                                          </div>
                                          <con-cash-flow-tree-layout *ngIf="cashFlow.cashFlowData" [locked]="entity?.locked || entitiesService.entityLockedByMl(entity)" [reportLock]="entity?.locked" [cashFlow]="cashFlow.cashFlowData" [entity]="entity"></con-cash-flow-tree-layout>

                                        </div>

                                    </div>
                                </div>
                                <con-no-data [count]="companyReport?.data.length" [message]="noDataMessage">
                                </con-no-data>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
