import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { EntitiesService } from 'app/entities/services/entities.service';
import { DateConverterService } from 'app/shared/services/date-converter.service';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'con-direkt-sync-events-filter',
  templateUrl: './direkt-sync-events-filter.component.html'
})
export class DirektSyncEventsFilterComponent implements OnInit {

  @Output() filterEvents = new EventEmitter<any>();
  @Input() isLoadingData: boolean;

  public today: string = this.dateConverter.toEntityString(moment().startOf('day'));
  public isFilterExpanded = true;
  public filterForm: FormGroup = new FormGroup({
    handled: new FormControl('', []),
    date: new FormControl('', []),
    owner_id: new FormControl('', []),
    owner_type: new FormControl('', []),
    direkt_response_code: new FormControl('', []),
    calendar_event_type: new FormControl('', []),
    event: new FormControl('', [])
  });
  public selectedOwnerDetails: any;
  public selectedTypeDetails: any;
  public isLoading = true;

  constructor(
    private entityService: EntitiesService,
    private dateConverter: DateConverterService
  ) { }

  ngOnInit(): void {
    this.entityService.getEntityDescriptionByEntityName('CalendarDirektSync').pipe(take(1)).subscribe((entityDescription) => {
      this.isLoading = false;
    })
  }

  selectOwner(ownerDetails: any, ownerType: string) {
    this.selectedOwnerDetails = ownerDetails;
    this.filterForm.get('owner_id').setValue(ownerDetails.id);
    this.filterForm.get('owner_type').setValue(ownerType);
    this.filterForm.markAsDirty();
  }

  clearOwner() {
    this.selectedOwnerDetails = null;
    this.filterForm.get('owner_id').setValue('');
    this.filterForm.get('owner_type').setValue('');
  }

  selectType(type: any) {
    this.selectedTypeDetails = type;
    this.filterForm.get('calendar_event_type').setValue(type.id);
    this.filterForm.markAsDirty();
  }

  clearType() {
    this.selectedTypeDetails = null;
    this.filterForm.get('calendar_event_type').setValue('');
  }

  submitFilter() {
    const params = {
      "handled": this.filterForm.get('handled').value.toString() || '',
      "calendar_event.owner_id" : this.filterForm.get('owner_id').value || '',
      "calendar_event.owner_type" : this.filterForm.get('owner_type').value || '',
      "calendar_event.from_date": this.filterForm.get('date').value || '',
      "direkt_response_code": this.filterForm.get('direkt_response_code').value || '',
      "calendar_event.calendar_event_type_id": this.filterForm.get('calendar_event_type').value || '',
      "event": this.filterForm.get('event').value || ''
    }
    this.filterEvents.emit(params);
  }

  reset(): void {
    this.filterForm.reset()
    this.filterForm.markAsDirty();
    this.filterForm.get('handled').setValue('');
    this.entityService.resetDayInput.next('direktSyncFilter');
    this.selectedOwnerDetails = null
    this.selectedTypeDetails = null
  }

}
