
import { Routes, RouterModule } from '@angular/router';
import { MarketcapComponent } from './layout/marketcap/marketcap.component';
import { MarketcapStartComponent } from './layout/marketcap-start/marketcap-start.component';
import { MarketcapCompanyComponent } from './layout/marketcap-company/marketcap-company.component';
import { MissingShareCountComponent } from './layout/missing-share-count/missing-share-count.component';
import { UpdateShareCountComponent } from './layout/update-share-count/update-share-count.component';
import { DuplicatePrimaryInstrumentComponent } from './layout/duplicate-primary-instrument/duplicate-primary-instrument.component';
import { MissingPrimaryInstrumentComponent } from './layout/missing-primary-instrument/missing-primary-instrument.component';
import { ExchangeRateComponent } from './layout/exchange-rate/exchange-rate.component';


export const marketcapRoutes: Routes = [
  {
    path: '',
    component: MarketcapComponent,
    canActivate: [],
    children: [
      {
        path: '',
        component: MarketcapStartComponent
      },
      {
        path: 'missing-share-count',
        component: MissingShareCountComponent
      },
      {
        path: 'update-share-count',
        component: UpdateShareCountComponent
      },
      {
        path: 'duplicate-primary-instrument',
        component: DuplicatePrimaryInstrumentComponent
      },
      {
        path: 'missing-primary-instrument',
        component: MissingPrimaryInstrumentComponent
      },
      {
        path: 'exchange-rate',
        component: ExchangeRateComponent
      },
      {
        path: ':id',
        component: MarketcapCompanyComponent
      }
    ]
  }
];

export const marketcapRouting = RouterModule.forChild(marketcapRoutes);
