<!--<con-object-inspector [object]="testdata" >-->
<!--</con-object-inspector>-->
<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="check"></con-icon-by-name>
        </div>
        <h3 class="mb-0">Report Event Status</h3>
      </div>
    </div>
  </div>
  <div class="row mb-3 sticky-header mozenda-menu">
    <div class="col-12">
      <div class="row">
        <div class="col-2 filter-options">
          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]="filterModels.selectedTypeFilter" [disabled]="loading">
              <mat-option *ngFor="let type of filterTypes"
                          [value]="type.value"
                          (click)="selectTypeFilter(type)">
                {{type.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <con-loader *ngIf="loading" [showCircularLoder]=showCircularLoader></con-loader>
      <div class="inner-scroll" *ngIf="reportEventStatuses?.data && reportEventStatuses?.data.length && !showCircularLoader">
        <div>
          <div>
            <div *ngFor="let docStatus of reportEventStatuses.data; let index = index">
              <div>
                <div class="card mb-2">
                  <div class="card-header" (click)="toggleType(docStatus)">
                    <div class="d-flex justify-content-start align-items-center">
                      <div class="mozenda-icon">
                        <con-icon-by-name iconName="medium" [mutations]="['fw']"></con-icon-by-name>
                      </div>
                      {{getReportTitle(docStatus)}}
                      <span class="badge badge-pill timeline-pill badge-primary ml-status-created ml-2" [ngbTooltip]=MLSendStatus.CREATED_MESSAGE *ngIf="docStatus.request_status === MLSendStatus.CREATED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>CREATED</span>
                      <span class="badge badge-pill timeline-pill badge-primary ml-status-failed ml-2" [ngbTooltip]=MLSendStatus.FAILED_MESSAGE *ngIf="docStatus.request_status === MLSendStatus.FAILED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>FAILED</span>
                      <span class="badge badge-pill timeline-pill badge-primary ml-status-closed ml-2" [ngbTooltip]=MLSendStatus.CLOSED_MESSAGE *ngIf="docStatus.request_status === MLSendStatus.CLOSED"> <con-icon-by-name [mutations]="['ml-status-black']" iconName="check"></con-icon-by-name> CLOSED</span>
                      <div class="card-header-toggle-individual ml-auto">
                        <a class="btn btn-sm btn-primary btn-padding"  target="_blank" (click)="goToReport($event, docStatus)">Go to Company Report Events</a>
                        <con-icon-by-name iconName="angle-right"
                                          [mutations]="getMutations(docStatus)"></con-icon-by-name>
                      </div>
                    </div>
                  </div>
                  <div class="p-4 col-6" *ngIf="!docStatus.hide && !docStatus.loading">
                    <div class="d-flex justify-content-start align-items-center pb-16" *ngIf="docStatus?.error_msg">
                      <strong>ML Status Message : </strong>  {{docStatus?.error_msg}}
                    </div>
                    <div class="card-header">
                      <div class="d-flex justify-content-start align-items-center">
                        <con-icon-by-name iconName="medium" [mutations]="['fw']"></con-icon-by-name>
                        Inspect
                        <div class="card-header-toggle-individual ml-auto">
                          <con-icon-by-name iconName="copy" class="ml-15 cursor-pointer" (click)="copyJSON($event, docStatus.data)"></con-icon-by-name>
                        </div>
                      </div>
                    </div>
                    <con-object-inspector [object]="docStatus.data" >
                    </con-object-inspector>
                  </div>
                  <con-loader *ngIf="!docStatus.hide && docStatus.loading"></con-loader>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-2" [ngClass]="{'hide-pagination':!reportEventStatuses?.total}">
          <ngb-pagination [collectionSize]="reportEventStatuses?.total"
                          [page]="reportEventStatuses?.current_page"
                          (pageChange)="loadPage($event)"
                          [pageSize]="reportEventStatuses?.per_page"
                          maxSize="10"
                          [rotate]="true">
          </ngb-pagination>
        </div>
        <div class="row">
          <div class="col-12 text-center"><small>Showing {{reportEventStatuses?.from}} to {{reportEventStatuses?.to}} of {{reportEventStatuses?.total}} entries</small></div>
        </div>
      </div>
      <con-no-data *ngIf="!loading" [count]="reportEventStatuses?.total" [message]="noDataMessage"></con-no-data>
    </div>
  </div>
</div>
