<con-loader *ngIf='loading'></con-loader>
<div>
  <div class="row">
    <div class="col-12">
      <div class="card mb-3">
        <div class="card-body">
          <div>
            <div class='half-width pull-left'>
              <h5 class="card-title m-0">Report Event Filter</h5>
            </div>
            <div class='half-width pull-left'>
              <con-icon-by-name iconName="angle-right" (click)="filterExpanded = !filterExpanded" [mutations]="filterExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']" class="filter-toggle m-0"></con-icon-by-name>
            </div>
          </div>
          <div class='clearfix'></div>
          <div [ngClass]="filterExpanded ? 'd-block mt-10' : 'd-none'">
            <con-report-event-filter (filterSearchTerm)='updateFilterTerms($event)' [companyId]="companyId">
            </con-report-event-filter>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class='card' *ngIf='!is_upcoming && !loading'>
  <div>
    <con-custom-entity-listing [customFilterQuery]="customFilterQuery" [reloadListing]="reloadListing" [companyId]="companyId" entityName='CompanyReportEvent' [actions]="missingActions"  [customAction]="true"  [isUpcoming]="is_upcoming"></con-custom-entity-listing>
  </div>
</div>
<div class='card' *ngIf='is_upcoming && !loading'>
  <con-custom-entity-listing [customFilterQuery]="customFilterQuery" [reloadListing]="reloadListing" [companyId]="companyId" entityName='CompanyReportEvent' [actions]="upcomingActions"  [customAction]="true"  [isUpcoming]="is_upcoming"></con-custom-entity-listing>
</div>
