import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntitiesService, EntityDescription } from '../../services/entities.service';
import { SelectedInstancesService } from '../../../doc-process/sub-modules/kpi-and-kiid/services/selected-instances.service';
import { TranslationService } from '../../../utility/services/translation.service';
import { FileTokenService } from '../../../auth/services/file-token.service';
import { combineLatest, debounceTime, startWith, switchMap, take, tap } from 'rxjs/operators';
import { ApiSettings } from '../../../settings.class';
import { lowerCase, snakeCase } from 'change-case';
import { EntityConfirmModalComponent } from './entity-confirm-modal.component';
import { CompanyReportsService } from '../../../company-reports/services/company-reports.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'con-custom-reporting-entity-listing',
  templateUrl: './custom-reporting-entity-listing.component.html',
  styleUrls: ['./custom-reporting-entity-listing.component.scss']
})
export class CustomReportingEntityListingComponent implements OnInit , OnChanges{

  @Input() entityName: string;
  @Input() isUpcoming: boolean;
  @Input() originEntityName: string = null;
  @Input() relation: string = null;
  @Input() entityId: number = null;
  @Input() actions: Array<any> = [];
  @Input() loaders: any = {};
  @Input() timestamps = false;
  @Input() runUpdate: Subject<boolean> = new Subject<boolean>();
  @Input() fixedSearchParams: any = {};
  @Input() isSelectable = false;
  @Input() external = false;
  @Input() externalMetaData: any;
  @Input() preserveSelection: any;
  @Input() enableHyperLink = true;
  @Input() smallCells = false;
  @Input() customAction = false;
  @Input() companyId = null;
  @Input() reloadListing = false;
  @Input() customFilterQuery = '';

  @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChecked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCheckedAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() onResponse: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedUrl = new EventEmitter<any>();
  private entityName$ = new Subject<string>();
  public searchForm = new FormGroup({});
  private page$ = new Subject<number>();
  public response: any;
  public entityDescription: EntityDescription;
  public currentPage  = 1;
  public loading: boolean;
  public noDataMessage = 'No data to display.';
  public orderField: string;
  public orderAsc: boolean;
  public allSelected: boolean;
  private snakeCase = snakeCase;
  private translationSub: Subscription;
  private customSortFilerEnabled: boolean = false;
  // private filterQuery = '?order_by=marketcap';
  private filterQuery = '';
  constructor(
    private formBuilder: FormBuilder,
    private service: EntitiesService,
    private reportService: CompanyReportsService,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private selectedInstancesService: SelectedInstancesService,
    private transService : TranslationService,
    private tokenService: FileTokenService
  ) {}
  private resetOrdering() {
    if (!this.external) {
      this.orderField = 'id';
      this.orderAsc = false;
    } else {
      this.orderField = 'id'
      this.orderAsc = true;
    }
  }
  public ngOnInit() {
    this.loadData();
    this.translationSub= this.transService.onTranslationSaved.subscribe((companyId)=> {
      if(this.entityId == companyId) {
        this.loadData();
      }
    })
  }
  public loadData() {


    this.allSelected = false
    const entityNameObservable = this.entityName$.pipe(
      startWith(this.entityName),
      switchMap(entityName => {
        const x = {
          "fields": [
            {
              "type": "boolean",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": false,
              "disabled": false,
              "show_in_table": true,
              "show_in_store": true,
              "show_in_update": true,
              "key": "reporting_standard.is_semi_annual",
              "label": "Is Semi Annual",
              "rules": [
                "boolean",
                "required"
              ]
            },
            {
              "type": "day",
              "is_searchable": true,
              "is_filterable": true,
              "is_primary": false,
              "disabled": false,
              "show_in_table": true,
              "show_in_store": true,
              "show_in_update": true,
              "key": "reporting_standard.from_date",
              "label": "From Date",
              "rules": [
                "string",
                "date",
                "required",
                "date_format:Y-m-d"
              ],
              "format": "Y-m-d"
            },
            {
              "type": "string",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": false,
              "disabled": false,
              "show_in_table": true,
              "show_in_store": false,
              "show_in_update": false,
              "key": "name",
              "label": "Company Name",
              "rules": [
                "string",
                "nullable"
              ]
            }
          ],
          "relations": [
            {
              "owner": "CompanyReportingStandard",
              "name": "is_semi_annual",
              "foreign_key": "reporting_standard",
              "label": "ReportingStandard",
              "type": "belongsTo",
              "is_standard_relation": true,
              "is_standard_count": false,
              "disabled": false,
              "required": true,
              "model": "ReportingStandard"
            },
            {
              "owner": "CompanyReportingStandard",
              "name": "from_date",
              "foreign_key": "reporting_standard",
              "label": "ReportingStandard",
              "type": "belongsTo",
              "is_standard_relation": true,
              "is_standard_count": false,
              "disabled": false,
              "required": true,
              "model": "ReportingStandard"
            }
          ],
          "icon": "flag",
          "name": "CustomReportingStandard"
        }
        const ed: EntityDescription= new EntityDescription(x);
        return of(ed);
      }),
      tap(entityDescription => {
        const form = {};
        entityDescription.getFilterableFields().forEach(field => {
          const fixedSearchObject = this.fixedSearchParams[field.key]
          if (fixedSearchObject) { form[field.key] = this.fixedSearchParams[field.key] } else { form[field.key] = ''; }
        });
        this.searchForm = this.formBuilder.group(form);
        this.page$ = new Subject<number>();
        this.resetOrdering();
        this.entityDescription = entityDescription;
      }),
      switchMap(entityDescription => {
        return this.searchForm.valueChanges.pipe(debounceTime(400),
          startWith({}),
          combineLatest(this.page$.pipe(startWith(1)),
            (terms, page) => {
              return {terms, page};
            }), );
      }),
      tap(params => this.currentPage = params.page),
      tap(params => this.loading = true),
      switchMap(res => {

        if(res.terms && Object.keys(res.terms).length > 0) {
          const mappedTerms = {
            is_semi_annual: '',
            from_date: '',
            'company.name': ''
          }

          if(res.terms['reporting_standard.is_semi_annual']){
            const [_, value] = res.terms['reporting_standard.is_semi_annual'].split(':');
            mappedTerms.is_semi_annual = value;
          } else {
            delete mappedTerms.is_semi_annual
          }
          if(res.terms['reporting_standard.from_date']){
            mappedTerms.from_date = res.terms['reporting_standard.from_date'];
          } else {
            delete mappedTerms.from_date
          }
          if(res.terms['name']){
            mappedTerms['company.name'] = res.terms['name'];
          } else {
            delete mappedTerms['company.name']
          }
          res.terms = mappedTerms;
        }
        const terms = { ...this.fixedSearchParams, ...res.terms };
        if (this.relation) {
          return this.service.searchRelation(this.originEntityName,
            this.entityId,
            this.relation,
            terms,
            res.page,
            this.orderField,
            this.orderAsc);
        }
        if(this.companyId){
          terms['company_id'] = this.companyId;
        }
        // below code is reference with row selection is enabled
        return this.service.searchEntities(this.entityDescription.name,
          terms,
          {
            page: res.page,
            order_by: this.orderField,
            order_asc: this.orderAsc
          });
      }),
      switchMap((instance: any) => {

        if (this.selectedInstancesService.selectedInstanceIds && instance.data) {
          this.handleSelectedInstances(instance, this.selectedInstancesService.selectedInstanceIds.projectInstanceTable)
        }
        if (this.selectedInstancesService.instancesMetadata && instance.data) {
          this.handleSelectedInstances(instance, this.selectedInstancesService.instancesMetadata.projectInstanceTable)
        }
        return of(instance)
      }))
      .subscribe(res => {
        if (this.entityName === 'Owner') {
          this.formatOwnerData(res);
        } else {
          res.data.map(item => item.edit_mode = false);
          this.response = res;
          this.onResponse.emit(res);
        }
        this.loading = false;
      }, err => {
        console.log(err);
        this.loading = false;
      });
  }
  ngOnDestroy(): void {
    this.translationSub.unsubscribe();
  }

  handleSelectedInstances(instance, selectedInstances) {
    let selectedCounter = 0
    instance.data.forEach(item => {
      if (selectedInstances.indexOf(item.id) > -1) {
        item.selected = true
        selectedCounter++
      }
    });
    if (selectedCounter === instance.data.length) { this.allSelected = true } else { this.allSelected = false }
  }

  public formatOwnerData(res) {
    if (res && res.data) {
      res.data.forEach(owner => {
        owner.capital = (owner.capital !== null && owner.capital !== '') ? (owner.capital * 100).toFixed(2) : null;
        owner.votes = (owner.votes !== null && owner.votes !== '') ? (owner.votes * 100).toFixed(2) : null;
      });
    }
    this.response = res;
  }
  public doAction(event, name, entity) {
    event.preventDefault();
    event.stopPropagation();
    if(name === 'edit') {
      entity.edit_mode = !entity.edit_mode;
    } else {
      this.onAction.emit({ entity, action: name });
    }
  }
  public ngOnChanges(event) {
    if (event.runUpdate) {
      this.runUpdate.subscribe(res => {
        this.page$.next(1);
      });
    }
    if (event.entityName) {
      this.entityName$.next(this.entityName);
    }

    if(event.reloadListing) {
      this.refresh();
    }
  }

  public changePage(event) {
    this.page$.next(event);
  }
  public fieldCanBeOrdered(field) {
    return field.key.indexOf('.') < 0;
  }
  public orderBy(field, asc) {
    this.orderField = field;
    this.orderAsc = asc;
    this.page$.next(1);
  }
  selectAll () {
    if (this.allSelected) {
      this.onCheckedAll.emit(this.response.data)
      this.response.data.forEach(entity => {
        entity.selected = true;
        this.selectRow(entity);
      });
    } else {
      this.onCheckedAll.emit([])
      this.response.data.forEach(entity => {
        entity.selected = false;
        this.selectRow(entity);
      });
    }
  }
  isAllSelected () {
    const selectedObject = JSON.parse(localStorage.getItem('selectedItems'));
    if (!selectedObject) {
      return false;
    } else {
      let allSelected = true;
      if (this.response && this.response.data) {
        this.response.data.forEach(entity => {
          if (!this.isChecked(entity.id)) {
            allSelected = false;
          }
        });
      }
      if (allSelected) {
        return true;
      } else {
        return false;
      }
    }
  }
  public selected(entity) {
    this.onChecked.emit(entity)
  }
  public selectRow (entity) {

    let selectedObject = this.selectedInstancesService.selectedInstanceIds// JSON.parse(localStorage.getItem('selectedItems'));
    if (!selectedObject) {
      selectedObject = {projectInstanceTable: []};
    }
    let selectedObjectDetails = this.selectedInstancesService.instancesMetadata// JSON.parse(localStorage.getItem('selectedItemDetails'));
    if (!selectedObjectDetails) {
      selectedObjectDetails = {projectInstanceTable: []};
    }
    if (entity.selected) {
      if (selectedObject[this.entityName] !== undefined && selectedObject[this.entityName].indexOf(entity.id) === -1) {
        selectedObject[this.entityName].push(entity.id);
      } else {
        selectedObject[this.entityName] = [entity.id];
      }

      if (selectedObjectDetails[this.entityName] !== undefined &&
        selectedObjectDetails[this.entityName].findIndex( ent => ent.id === entity.id) === -1) {
        selectedObjectDetails[this.entityName].push(entity);
      } else {
        selectedObjectDetails[this.entityName] = [entity];
      }
    } else {
      if (selectedObject[this.entityName] !== undefined) {
        const index = selectedObject[this.entityName].indexOf(entity.id);
        if (index > -1) {
          selectedObject[this.entityName].splice(index, 1);
        }
      }
      if (selectedObjectDetails[this.entityName] !== undefined) {
        const index = selectedObjectDetails[this.entityName].findIndex(ent => ent.id === entity.id);
        if (index > -1) {
          selectedObjectDetails[this.entityName].splice(index, 1);
        }
      }
    }
    this.handleSelectedInstances(this.response, selectedObject.projectInstanceTable)
    this.selectedInstancesService.addInstanceMetadata(selectedObjectDetails)
    this.selectedInstancesService.addselectedInstanceIds(selectedObject)
  }
  public setSelection (data) {
    data.forEach(entity => {
      entity.selected = this.isChecked(entity.id);
    });
  }
  public isChecked (id: number) {
    const selectedObject = JSON.parse(localStorage.getItem('selectedItems'));
    if (selectedObject && selectedObject[this.entityName]) {
      if (selectedObject[this.entityName].indexOf(id) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public refresh() {
    this.page$.next(1);
  }

  public openTranslation(entity) {
    this.transService.onDescriptionSaved(entity);
  }

  public canTranslate(entity) {
    return this.entityName == "Description" && entity.language.name == "English";
  }
  // below code is for delete confirmation modal if the feature is required
  // deleteEntity(event, entity: any) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   const modalRef = this.modalService.open(EntityConfirmModalComponent,
  //     {
  //       size: 'md'
  //     });
  //   const data = {
  //     title: 'Lock',
  //     message: 'Are you sure you want to delete this report standard?',
  //     alert: false
  //   }
  //   modalRef.componentInstance.modalData = data;
  //   modalRef.result.then((result) => {
  //     this.reportService.deleteReportStandard(entity.id).subscribe((response) => {
  //       this.toaster.success("Deleted successfully!");
  //       this.refresh();
  //     });
  //   });
  // }
}
