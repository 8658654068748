<div class="table-responsive">
  <table class="table table-hover">
    <thead class="thead-light">
    <tr>
      <th *ngIf="isSelectable"></th>
      <th>
        Id
        <!-- below commented code of sort reference if is needed -->
        <!--        <i class="fa fa-sort-asc fa-lg" [ngClass]="{'active': orderField === 'id' && orderAsc }" (click)="orderBy('id', true)"></i><i class="fa fa-sort-desc fa-lg" [ngClass]="{'active': orderField === 'id' && !orderAsc }" (click)="orderBy('id', false)"></i>-->
      </th>
      <th *ngFor="let field of entityDescription?.getFieldsForTable(timestamps)">
        {{ field.label }}
        <!-- below commented code of sort reference if is needed -->
        <!--        <span *ngIf="fieldCanBeOrdered(field)"> <i class="fa fa-sort-asc fa-lg" [ngClass]="{'active': orderField === field.key && orderAsc }" (click)="orderBy(field.key, true)"></i><i class="fa fa-sort-desc fa-lg" [ngClass]="{'active': orderField === field.key && !orderAsc }" (click)="orderBy(field.key, false)"></i></span>-->
      </th>
      <th *ngIf="actions.length || customAction">
        Action
      </th>
    </tr>
    <tr>
      <td>
        <div class="btn-group btn-group-md" role="group">
          <button class="btn btn-dark" ngbTooltip="Refresh Table" container="body" (click)="refresh()">
            <con-icon-by-name iconName="refresh" [mutations]="['fw']">
            </con-icon-by-name>
          </button>
          <button class="btn btn-info" [ngbTooltip]="'Showing ' + response?.data.length + ' of ' + response?.total" container="body">
            <con-icon-by-name iconName="info" [mutations]="['fw']">
            </con-icon-by-name>
          </button>
        </div>
      </td>
      <td *ngFor="let field of entityDescription?.getFieldsForTable(timestamps)">
        <con-entity-search-by-field
          [field]="field"
          [control]="searchForm.controls[field.key]">
        </con-entity-search-by-field>
      </td>
      <td *ngIf="actions.length || customAction">
      </td>
    </tr>
    <!-- below commented code needed for future column based selection -->
        <tr>
    <!--      <td *ngIf="isSelectable" class="text-center select-all">-->
    <!--        <input type="checkbox" [(ngModel)]="allSelected" (change)="selectAll()">-->
    <!--      </td>-->
    <!--      <td class="align-middle">-->
    <!--        <div class="btn-group btn-group-md" role="group">-->
    <!--          <button class="btn btn-dark" ngbTooltip="Refresh Table" container="body" (click)="refresh()">-->
    <!--            <con-icon-by-name iconName="refresh" [mutations]="['fw']">-->
    <!--            </con-icon-by-name>-->
    <!--          </button>-->
    <!--          <button class="btn btn-info" [ngbTooltip]="'Showing ' + response?.data.length + ' of ' + response?.total" container="body">-->
    <!--            <con-icon-by-name iconName="info" [mutations]="['fw']">-->
    <!--            </con-icon-by-name>-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </td>-->

<!--          <td *ngFor="let field of entityDescription?.getFieldsForTable(timestamps)">-->
<!--            <con-entity-search-by-field-->
<!--              [field]="field"-->
<!--              [control]="searchForm.controls[field.key]">-->
<!--            </con-entity-search-by-field>-->
<!--          </td>-->
<!--          <td *ngIf="actions.length || customAction">-->
<!--          </td>-->

        </tr>
    </thead>
    <tbody *ngIf="!loading">
    <ng-container *ngFor="let entity of response?.data">
      <tr [ngbTooltip]='entity?.reporting_standard?.is_semi_annual === true? "Bi Annual Reporting Standard" : "Normal Reporting Standard"'>
        <td *ngIf="isSelectable" class="text-center">
          <input type="checkbox" [(ngModel)]="entity.selected" (change)="selectRow(entity); selected(entity)">
        </td>
        <td *ngIf="!enableHyperLink">{{ entity.id }}</td>
        <td *ngIf="enableHyperLink">
          <a [routerLink]="['', 'company_reports', 'reporting-standard', 'company', entity.id]">
            {{ entity.id }}
          </a>
        </td>
        <td *ngFor="let field of entityDescription.getFieldsForTable(timestamps)">
          <ng-container *ngIf="field.key === 'report_date' && entity?.company_report_id !== null">
            <a [routerLink]="['', 'company_reports',entity?.company_id, 'report', entity?.company_report_id,'report-file']">
              <con-entity-content-by-field
                [field]="field"
                [entityDescription]="entityDescription"
                [entity]="entity">
              </con-entity-content-by-field>
            </a>
          </ng-container>
          <ng-container *ngIf="field.key === 'company.name'">
            <a [routerLink]="['', 'company_reports','company',entity?.company_id]">
              <con-entity-content-by-field
                [field]="field"
                [entityDescription]="entityDescription"
                [entity]="entity">
              </con-entity-content-by-field>
            </a>
          </ng-container>
          <ng-container *ngIf="(field.key !== 'report_date' && field.key !== 'company.name') ||  (field.key === 'report_date' && entity?.company_report_id === null)">
            <con-entity-content-by-field
              [field]="field"
              [entityDescription]="entityDescription"
              [entity]="entity">
            </con-entity-content-by-field>
          </ng-container>
        </td>
        <td *ngIf="loaders[entity.id]">
          <con-loader></con-loader>
        </td>
        <td *ngIf="actions.length && !loaders[entity.id] && !customAction" class="row-action">
          <ng-container *ngFor="let action of actions">
            <span *ngIf="!entity.locked"><button class="btn btn-sm action-btn" [ngClass]="'btn-' + action.buttonClass" (click)="doAction($event, action.name, entity)"><con-icon-by-name [iconName]="action.icon"></con-icon-by-name> {{ action.label }}</button></span>
          </ng-container>
          <ng-container *ngIf="canTranslate(entity)">
                            <span>
                                <button class="btn btn-sm btn-primary translate" (click)="openTranslation(entity)"><con-icon-by-name iconName="globe"></con-icon-by-name>Translate</button>
                            </span>
          </ng-container>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
<con-loader *ngIf="loading"></con-loader>
<con-no-data *ngIf="!loading" [count]="response?.total" [message]="noDataMessage"></con-no-data>
<div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!response?.total}">
  <ngb-pagination [collectionSize]="response?.total"
                  [(page)]="currentPage"
                  (pageChange)="changePage($event)"
                  [pageSize]="response?.per_page"
                  maxSize="10"
                  [rotate]="true"></ngb-pagination>
</div>
