import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';
import { EntityDescription, EntitiesService } from '../../services/entities.service';
import { snakeCase } from 'change-case';
import { CompanyReportsService } from '../../../company-reports/services/company-reports.service';
import { CalendarEventSettings } from 'app/settings.class';
import { DateConverterService } from 'app/shared/services/date-converter.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'con-entity-form',
    templateUrl: './entity-form.component.html'
})

export class EntityFormComponent implements OnChanges {
    @Input() entityName: string;
    @Input() entity: any = {};
    @Input() fixedValues: any = {};
    @Input() removeRelations = false;
    @Input() isExpanded = true;
    @Input() canEdit: boolean;
    @Input() canShow: boolean;
    @Input() canStore: boolean;
    @Input() canDelete: boolean;
    @Input() emptyAfterSave = false;
    @Input() except: any = [];
    @Input() only: any = [];
    @Input() showWhenChanged = true;
    @Input() fromCalendar = false;
    @Input() reloadEditData: boolean;
    @Input() fromDetailPage: boolean;
    @Input() parentCompany: any;
    @Input() fromReports = false;
    @Input() fromPressRelease = false;
    @Input() isFromEntityStart = false;
    @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();

    public entityDescription: EntityDescription;
    public expanded: boolean;
    public obj: any;
    public isLocked = false;
    public isCalculated = false;
    public preFillValues: any = {}
    public tomorrow: string = this.dateConverter.toEntityString(moment().add(1, 'days').startOf('day'));
    constructor(private service: EntitiesService, private companyService: CompanyReportsService, private dateConverter: DateConverterService) { }

    isLoading() {
        return this.service.isLoading();
    }

    toggleExpansion() {
        this.expanded = !this.expanded;
    }

    entityAlreadyExists() {
        return this.obj && this.obj.id !== undefined;
    }

    ngOnChanges() {
        this.preFillValues = {}; //clear prefill values to avoid prefilling the wrong entity (CONN-1717)
        if (!this.entity) {
            this.obj = {};
        } else {
            this.obj = this.entity;
            if (this.service.isEntityLocked(this.entity, this.entityName)) {
                this.isLocked = true;
            }
            if (this.service.isEntityCalculated(this.entity, this.entityName)) {
                this.isCalculated = true;
            }
        }
        this.expanded = this.isExpanded;

        this.service.getEntityDescriptionByEntityName(this.entityName)
            .subscribe(entityDescription => {
                this.entityDescription = entityDescription;
            });

        if (this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY && this.isFromEntityStart) {
            this.preFillValues = {
                date_approximation_id: CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.date_approximation_id,
                time_approximation_id: CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.time_approximation_id,
                from_date: this.tomorrow
              }
            this.obj = {
                time_approximation: environment.time_approx_object,
                date_approximation: environment.date_approx_object,
                date_approximation_id: environment.date_approx_object.id,
                time_approximation_id: environment.time_approx_object.id,
                ...this.obj
            }
        }
    }

    onSave(entity) {
        this.afterSave.emit(entity);
    }

    goToEntity(event, entityName, id) {
      event.stopPropagation();
      entityName = snakeCase(entityName);
      this.companyService.goToEntity(event,entityName, id);
    }
}
