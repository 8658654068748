<nav class="nav flex-column">
  <a class="nav-link"
     [routerLink]="['report']"
     routerLinkActive="active">
      Report
  </a>
  <a class="nav-link"
     [routerLink]="['check']"
     routerLinkActive="active">
    Check
  </a>
  <a class="nav-link"
     [routerLink]="['report-events']"
     routerLinkActive="active">
    Report Events
  </a>
  <a class="nav-link"
     [routerLink]="['missing-events']"
     routerLinkActive="active">
    Missing Events
  </a>
  <a class="nav-link"
     [routerLink]="['upcoming-events']"
     routerLinkActive="active">
    Upcoming Events
  </a>
  <a class="nav-link"
     [routerLink]="['status']"
     routerLinkActive="active">
      ML Report Status
  </a>
  <a class="nav-link"
     [routerLink]="['report-event-status']"
     routerLinkActive="active">
    Report Event Status
  </a>
  <a class="nav-link"
     [routerLink]="['reporting-standard']"
     routerLinkActive="active">
    Reporting Standard
  </a>
</nav>
