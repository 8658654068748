import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityDescription } from 'app/entities/services/entities.service';
import { ApiSettings, CalendarEventSettings } from 'app/settings.class';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    private http: HttpClient
  ) { }

  getDirektSyncList(params: HttpParams): Observable<any> {
    return this.http.get(`${ApiSettings.BASE_URL}/calendar_event/direkt_sync_status`, { params });
  }

  getPrefillValuesForReportDatesForm(entityDescription: EntityDescription): any {
    return {
      to_date: null,
      date_approximation_id: CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.date_approximation_id,
      time_approximation_id: CalendarEventSettings.REPORT_DATES_TIME_APPROXIMATION_ID,
      date_confirmed: entityDescription.getPrefillValueByFieldKey('date_confirmed') || CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.date_confirmed,
      source_type: entityDescription.getPrefillValueByFieldKey('source_type') || CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.source_type,
      profile_id: CalendarEventSettings.REPORT_DATES_DIREKT_PROFILE_ID,
      owner_type: 'Company',
    }
  }

  getFlattendErrorArray(error: any): string[] {
    const messages: string[] = [];
    if (error.fields) {
      for (const field in error.fields) {
        if (error.fields.hasOwnProperty(field)) {
          messages.push(...error.fields[field]);
        }
      }
    }
    if (error.general) {
      messages.push(...error.general);
    }
    return messages;
  }
  
}
