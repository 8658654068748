import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { CompanyReportsSettings } from 'app/settings.class';
import { ToastrService } from 'ngx-toastr';
import { MLSendStatus } from 'app/company-reports/models/MLSendStatus';
import {DatePipe} from '@angular/common';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'con-key-financials',
  templateUrl: './key-financials.component.html',
  styleUrls: ['./key-financials.component.scss']
})
export class KeyFinancialsComponent implements OnInit, OnDestroy {
  reportCtrls: UntypedFormGroup;
  reloadPageSubscription: Subscription;
  @Input() entity: any = { };

  mask = CompanyReportsSettings.MASK
  loading = false;
  tableFormatData = {
    tableHeadData : [],
    incomeStatements : [],
    cashFlows : [],
    periodKpis : [],
    balanceSheets : [],
    snapshotKpis : [],
  };
  defaultPeriodKpis = {
    modules: [],
    quantity: null,
    unit: null
  };
  defaultBalanceSheets = {
    modules: [],
    quantity: null,
    unit: null
  };
  defaultCashFlows = {
    modules: [],
    quantity: null,
    unit: null
  };
  defaultSnapshotKpis= {
    modules: [],
    quantity: null,
    unit: null
  };
  defaultIncomeStatements = {
    modules: [],
    quantity: null,
    unit: null,
    from_date: null,
    to_date: null
  };
  public MLSendStatus = MLSendStatus;

  constructor(private toastr: ToastrService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
    public companyService: CompanyReportsService,private router: Router) {
    this.reportCtrls = new UntypedFormGroup({ });
  }

  ngOnInit() {
    if(!this.entity.key_financials_approved && this.entity.locked) {
      this.entity.key_financials_approved = true
    }
    this.reportCtrls.addControl('checkbox', new UntypedFormControl(this.entity.key_financials_approved));
    this.getReport(this.entity.id)
    this.companyService.onReportLocked.subscribe((locked: boolean)=>{
      if(locked) {
        this.reportCtrls.controls['checkbox'].setValue(true)
      }
    })
    this.reloadPageSubscription = this.companyService.reloadPage.subscribe((data) => {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
      });
    });
  }

  getReport(reportId: string) {
    this.loading = true;
    this.http
      .get(
        `${CompanyReportsSettings.KEY_FINANCIAL_ENDPOINT}/${reportId}`
      )
      .subscribe(
        (result: any) => {
          this.generateTabularData(result);
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error(
            "Sorry, some error occurred",
            "Key Financial Approval"
          );
        }
      );
  }

  approveKeyFinancials() {
    this.http
      .put(
        `${CompanyReportsSettings.BASE_URL}/${this.entity.id}`,
        {
          id: this.entity.id,
          key_financials_approved: this.reportCtrls.value.checkbox
        }
      )
      .subscribe(
        (result) => {
          this.toastr.success(
            "Key financial approval status is updated successfully",
            "Key Financial Approval"
          );
        },
        (err) => {
          this.toastr.error(
            "Sorry, some error occurred",
            "Key Financial Approval"
          );
        }
      );
  }

  getQuantity(reportName: string, row, quantity, unit) {
    if (reportName === 'period_kpi' || reportName === 'snapshot_kpi') {
      return `${row.quantity} (${row.unit})`
    } else {
      return `${quantity.name} (${unit.name})`
    }
  }

  getDisplayValue(reportName: string, row: any, quantity) {
    let value, multiplier = null
    if (reportName === 'period_kpi' || reportName === 'snapshot_kpi') {
      value = this.getConvertedKPIValue(row);
    } else {
      value = row?.value_rep;
      multiplier = quantity?.multiplier;
      if (multiplier !== undefined && value !== null) {
        value = value / multiplier;
      }
    }
    return value
  }
  getConvertedKPIValue(row: any): any {
    if (row.value_rep !== null && row.value_rep !== undefined && row.value_rep !== '') {
      if (row.multiplier === 0.01 || row.multiplier === '0.01') {
        row.display_value = (row.value_rep / row.multiplier).toFixed(4);
      } else {
        row.display_value = (row.value_rep / row.multiplier).toString();
      }
    }
    return row.display_value
  }

  get settingLock() {
    return this.companyService.locking;
  }

  generateTabularData(reportData) {
    // this.tableFormatData = [];
    const fromDates = reportData.fromDates;
    const toDates = reportData.toDates;
    const headerTextArray: string[] = [];
    // check fromDates is empty, exit if yes
    if(!fromDates.length){
      return;
    }
    // generate multiple date support items like income statements, cash flow & period kpis
    this.generateMultipleDateSupportingItems(fromDates, toDates, reportData)
    toDates.forEach((date) => {
      if (this.defaultBalanceSheets.modules.length === 0 && reportData.balance_sheet[date]) {
        for (let i = 0; i < reportData.balance_sheet[date].modules.length; i++) {
          this.defaultBalanceSheets.modules.push({
            name: reportData.balance_sheet[date].modules[i].name,
            tree_name: reportData.balance_sheet[date].modules[i].tree_name,
            value_rep: null
          })
        }
      }
    })

    toDates.forEach((date) => {
      if (this.defaultSnapshotKpis.modules.length === 0 && reportData.snapshot_kpi[date]) {
        for (let i = 0; i < reportData.snapshot_kpi[date].modules.length; i++) {
          this.defaultSnapshotKpis.modules.push({
            name: reportData.snapshot_kpi[date].modules[i].name,
            tree_name: reportData.snapshot_kpi[date].modules[i].tree_name,
            value_rep: null
          })
        }
      }
    })
    // generate single date support items like snapshot kpis & balance sheet
    this.generateSingleDateSupportingItems(toDates, reportData);

    // generate header text
    fromDates.forEach((item, index) => {
      item =  this.datePipe.transform(item,'dd/MM/yyyy').toString();
      item += ' - ';
      item += this.datePipe.transform(toDates[index],'dd/MM/yyyy').toString();
      headerTextArray.push(item);
    });
    this.tableFormatData.tableHeadData = headerTextArray;
  }
  generateSingleDateSupportingItems(toDates: string[],reportData): void {
    toDates.forEach((date, index) => {
      if(reportData.balance_sheet[date] !== undefined) {
        this.tableFormatData.balanceSheets[index] = reportData.balance_sheet[date];
      } else {
        this.tableFormatData.balanceSheets[index] = this.defaultBalanceSheets;
      }
      if(reportData.snapshot_kpi[date] !== undefined) {
        reportData.snapshot_kpi[date].quantity = null;
        reportData.snapshot_kpi[date].unit = null;
        this.tableFormatData.snapshotKpis[index] = reportData.snapshot_kpi[date];
      } else {
        this.tableFormatData.snapshotKpis[index] = this.defaultSnapshotKpis;
      }
    })

  }
  generateMultipleDateSupportingItems(fromDates: string[], toDates: string[], reportData): void{
    fromDates.forEach((item, index) => {
      item += '/';
      item += toDates[index];
      for (let key in reportData.income_statement) {
        if(key === item) {
          if(this.defaultIncomeStatements.modules.length === 0 ) {
            for(let i=0; i<reportData.income_statement[key].modules.length; i++) {
              this.defaultIncomeStatements.modules.push({
                name: reportData.income_statement[key].modules[i].name,
                tree_name: reportData.income_statement[key].modules[i].tree_name,
                value_rep: null
              })
            }
          }
          this.tableFormatData.incomeStatements[index] = reportData.income_statement[key];
        }
      }
      for (let key in reportData.cash_flow) {
        if(key === item) {
          if(this.defaultCashFlows.modules.length === 0 ) {
            for(let i=0; i<reportData.cash_flow[key].modules.length; i++) {
              this.defaultCashFlows.modules.push({
                name: reportData.cash_flow[key].modules[i].name,
                tree_name: reportData.cash_flow[key].modules[i].tree_name,
                value_rep: null
              })
            }
          }
          this.tableFormatData.cashFlows[index] = reportData.cash_flow[key];
        }
      }
      for (let key in reportData.period_kpi) {
        if(key === item) {
          if(this.defaultPeriodKpis.modules.length === 0 ) {
            for(let i=0; i<reportData.period_kpi[key].modules.length; i++) {
              this.defaultPeriodKpis.modules.push({
                name: reportData.period_kpi[key].modules[i].name,
                tree_name: reportData.period_kpi[key].modules[i].tree_name,
                value_rep: null
              })
            }
          }
          reportData.period_kpi[key].quantity = null;
          reportData.period_kpi[key].unit = null;
          this.tableFormatData.periodKpis[index] = reportData.period_kpi[key];
        }
      }
    });

    // set static objects for empty object
    for (let i = 0; i < this.tableFormatData.periodKpis.length; i++ ) {
      // check item is empty
      if (!this.tableFormatData.periodKpis[i]) {
        // default item to empty object
        this.tableFormatData.periodKpis[i] = this.defaultPeriodKpis;
      }
    }
    // set static objects for empty object
    for (let i = 0; i < this.tableFormatData.incomeStatements.length; i++ ) {
      // check item is empty
      if (!this.tableFormatData.incomeStatements[i]) {
        // default item to empty object
        this.tableFormatData.incomeStatements[i] = this.defaultIncomeStatements;
      }
    }
    // set static objects for empty object
    for (let i = 0; i < this.tableFormatData.cashFlows.length; i++ ) {
      // check item is empty
      if (!this.tableFormatData.cashFlows[i]) {
        // default item to empty object
        this.tableFormatData.cashFlows[i] = this.defaultCashFlows;
      }
    }
  }
  getDisplayTitle() {
    let displayName = this.entity.company.name;
    if (this.entity) {
      displayName +=
        ' / ' +
        this.entity.report_type +
        ', ' +
        moment(this.entity.report_date).format('yyyy-MM-DD');
    }
    return displayName;
  }
  ngOnDestroy(): void {
    this.reloadPageSubscription.unsubscribe()
  }
}
