import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CompanyReportsSettings } from 'app/settings.class';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { EntitiesService } from 'app/entities/services/entities.service';
import * as moment from 'moment';
import { MLSendStatus } from 'app/company-reports/models/MLSendStatus';

@Component({
  selector: 'con-snapshot-kpi',
  templateUrl: './snapshot-kpi.component.html',
  styleUrls: ['./snapshot-kpi.component.scss']
})
export class SnapshotKpiComponent implements OnInit, OnDestroy {

  @Input() entity: any;
  public loading = false;
  public routeSubscription: Subscription;
  public companyReport: any;
  public id: number;
  public noDataMessage = 'No Snapshot kpis to display';
  public kpiList: any;
  public kpiType = 'SnapshotKpi';
  public previousYearValues: any = {};
  public previousValueSource: any = {};
  public MLSendStatus = MLSendStatus;
  public latestEmployeeData : any;
  reloadPageSubscription: Subscription;
  constructor( private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private entitiesService: EntitiesService,
    public companyService: CompanyReportsService
  ) { }

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe(
      (params: Params) => {
        this.id = params.id;
      }
    );
    this.companyService.selectedReport.subscribe(entity => {
      this.kpiList.data.forEach((kpi) => {
        kpi.locked = entity.locked;
        if (kpi.company_report) {
          kpi.company_report.locked = entity.locked;
        }
      });
    });
    this.loadPage();
    this.reloadPageSubscription = this.companyService.reloadPage.subscribe((data) => {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
      });
    });
  }
  loadPage() {
    this.loading = true;
    this.http.get(CompanyReportsSettings.BASE_URL + '/' + this.id + '/snapshot_kpis')
      .subscribe(kpiList => {
        this.kpiList = kpiList;
        if (this.kpiList.data.length) {
          this.kpiList.data.forEach((kpi, index) => {
            if (index === 0) {
              this.getKpiData(kpi);
            } else {
              kpi.hide = true;
            }
          });
          //
          if(this.entity.report_type === 'annual'){
            this.companyService.getLatestEmployeeData(this.entity.id).subscribe((data) => {
              this.latestEmployeeData = data;
              this.latestEmployeeData.fixedValues = {
                id: this.latestEmployeeData.id,
                source: this.latestEmployeeData.source,
                source_id:this.latestEmployeeData.source_id,
                source_type: this.latestEmployeeData.source_type,
              }

            }, (err) => {
              console.error(err);
            })
          }
        }
        this.loading = false;
      },
        err => {
          if (err.status === 404) {
            this.router.navigate(['snapshot_kpis', 'error'], { skipLocationChange: true });
          }
          this.loading = false;
        });
  }
  public getKpiData(kpi: any) {
    if (kpi && kpi.kpiData) {
      return;
    }
    kpi.loading = true;
    this.http
      .get(
        CompanyReportsSettings.BASE_URL +
        '/' +
        'snapshot_kpi' +
        '/' +
        kpi.id
      )
      .subscribe(
        (response: any) => {
          this.formatPreviousYearData(response);
          response.modules.forEach(module => {
            if (module.value_rep !== null && module.value_rep !== undefined && module.value_rep !== '') {
              if (module.multiplier === 0.01 || module.multiplier === '0.01') {
                module.display_value = (module.value_rep / module.multiplier).toFixed(4).toString();
              } else {
                module.display_value = (module.value_rep / module.multiplier).toString();
              }
            }
            if (module.prev_year_value !== null && module.prev_year_value !== undefined && module.prev_year_value !== '') {
              if (module.multiplier === 0.01 || module.multiplier === '0.01') {
                module.prev_display_value = (module.prev_year_value / module.multiplier).toFixed(4).toString();
              } else {
                module.prev_display_value = (module.prev_year_value / module.multiplier).toString();
              }
            }
          });
          kpi.kpiData = response;
          kpi.loading = false;
        },
        (err) => {
          if (err.status === 404) {
            this.router.navigate(['period_kpi', 'error'], {
              skipLocationChange: true,
            });
          }
          kpi.loading = false;
        }
      );
  }
  formatPreviousYearData(data: any) {
    this.previousYearValues = {};
    this.previousValueSource = {};
    if (data.past_modules && data.past_modules.length > 1) {
      this.formatPreviousYearValue(data.past_modules);
      this.setPreviousYearValue(data.modules);
      data.showPrevious = true;
    } else {
      data.showPrevious = false;
    }
  }
  formatPreviousYearValue(past_moduleList) {
    for (let index = 0; index < past_moduleList.length; index++) {
        this.previousYearValues[past_moduleList[index].module_id] = past_moduleList[index].value_rep;
        this.previousValueSource[past_moduleList[index].module_id] =  past_moduleList[index].value_source;
        if (past_moduleList[index].children && past_moduleList[index].children.length > 0) {
          this.formatPreviousYearValue(past_moduleList[index].children)
        }
    }
  }
  setPreviousYearValue(moduleList) {
    for (let index = 0; index < moduleList.length; index++) {
        moduleList[index].prev_year_value = this.previousYearValues[moduleList[index].module_id];
        moduleList[index].prev_value_source = this.previousValueSource[moduleList[index].module_id];
        if (moduleList[index].children && moduleList[index].children.length > 0) {
          this.setPreviousYearValue(moduleList[index].children)
        }
    }
  }
  getDisplayTitle() {
    let displayName = this.entity && this.entity.company ? this.entity.company.name : '';
    if (this.entity) {
      displayName +=
        ' / ' +
        this.entity.report_type +
        ', ' +
        moment(this.entity.report_date).format('yyyy-MM-DD');
    }
    return displayName;
  }
  get settingLock () {
    return this.companyService.locking;
  }

  updateEmployeeData(data: any) {
    this.latestEmployeeData = data;
  }

  ngOnDestroy(): void {
    this.reloadPageSubscription.unsubscribe();
  }
}
