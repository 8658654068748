import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CompanyReportsService } from '../../../company-reports/services/company-reports.service';
import { HttpClient } from '@angular/common/http';
import { EstimatesService } from '../../services/estimates.service';
import { ProfileService } from '../../../auth/services/profile.service';
import { Subject, combineLatest, forkJoin, of } from 'rxjs';
@Component({
  selector: 'con-survey-main',
  templateUrl: './survey-main.component.html',
  styleUrls: ['./survey-main.component.scss']
})
export class SurveyMainComponent implements OnInit, OnDestroy {

  private componentDestroyed$: Subject<boolean> = new Subject();

  company: any;
  surveyId: any;
  surveyEntity: any;
  isLoading = false;

  constructor(private http: HttpClient, private route: ActivatedRoute,
    private estimateService: EstimatesService,
    public profile: ProfileService,
    private companyReportService: CompanyReportsService
  ) { }


  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.pipe(
      takeUntil(this.componentDestroyed$),
      switchMap((params: Params) => {
        if (params && params.id && params.companyId) {
          return combineLatest([
            this.companyReportService.getCompanyData(params.companyId),
            this.estimateService.getConsensusValues().pipe(
              takeUntil(this.componentDestroyed$),
              switchMap((consensusData) => {
                if(consensusData)
                  return of(consensusData);
                else{
                  this.isLoading = true;
                  return this.estimateService.getConsenusData(params.id)
                }
              })
            ),
            this.estimateService.getCurrentSurveyApproveStatus().pipe(
              takeUntil(this.componentDestroyed$),
              switchMap(() => {
                this.isLoading = true;
                return this.estimateService.getSurvey(params.id);
              })
            )
          ]).pipe(
            map(([companyDetails, consensusData, surveyDetails]) => ({ companyDetails, consensusData, surveyDetails }))
          );
        } else {
          return of(null); // Return an observable that emits null if required parameters are missing
        }
      })
    ).subscribe(
      (data) => {
        if (data) {
          this.company = data.companyDetails;
          this.surveyEntity = data.surveyDetails;
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  
  }

  getStripedWebsiteString(companyWebsite: string): string {
    if (companyWebsite) {
      return companyWebsite.replace(/(^\w+:|^)\/\//, '');
    }
    return '';
  }

}
