<div class="container">
  <form [formGroup]='consensusSnapshotForm' (ngSubmit)="onSubmit()">
    <div class="row mt-15">

    <div formArrayName="modules" class="col-12">
      <table class="table bg-white tree-table consensus-modules">
        <thead class="thead-light">
        <tr>
          <th>Module Name</th>
          <th>Mean</th>
          <th>Median</th>
          <th>High</th>
          <th>Low</th>
          <th>Contr</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let moduleFormGroup of consensusSnapshotForm.get('modules')?.controls; let i = index">
          <tr *ngIf="shouldShowRow(moduleFormGroup)" [formGroupName]="i">
            <td>
              <div class="full-width-custom" [ngClass]="levelClass(moduleFormGroup)">
                <button *ngIf="hasChildren(moduleFormGroup.value.hasChildren)" class="btn place-holder-size outline-zero"  (click)="toggleExpand(moduleFormGroup); $event.stopPropagation()">
                  <con-icon-by-name [iconName]="moduleFormGroup.get('isExpanded').value ? 'angle-down' : 'angle-right'" ></con-icon-by-name>
                </button>
                <div *ngIf="!hasChildren(moduleFormGroup.value.hasChildren)" class="place-holder-size pull-left"></div>
                <span>{{moduleFormGroup?.value?.alias || moduleFormGroup?.value?.name}}</span>
              </div>
            </td>
            <td>
              <input formControlName="mean">
              <div class="mat-error" *ngIf="moduleFormGroup.get('mean').invalid">Invalid value</div>
            </td>
            <td>
              <input formControlName="median">
              <div class="mat-error" *ngIf="moduleFormGroup.get('median').invalid">Invalid value</div>
            </td>
            <td>
              <input formControlName="high">
              <div class="mat-error" *ngIf="moduleFormGroup.get('high').invalid">Invalid value</div>
            </td>
            <td>
              <input formControlName="low">
              <div class="mat-error" *ngIf="moduleFormGroup.get('low').invalid">Invalid value</div>
            </td>
            <td>
              <input formControlName="contributors_count">
              <div class="mat-error" *ngIf="moduleFormGroup.get('contributors_count').invalid">Invalid value</div>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>

    </div>
    <button *ngIf="!isSurveyLocked" type="submit" class="btn btn-success mt-15">Save</button>

  </form>
</div>
