import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { TreeNode } from './income-statement-editor.component';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { UntypedFormControl } from '@angular/forms'

@Component({
  selector: 'con-tree-layout',
  templateUrl: './tree-layout.component.html'
})

export class TreeLayoutComponent implements OnInit, OnChanges {
  @Input() incomeStatement: { company_report: any, modules: TreeNode[], quantity: any, past_statement_id: any, currency: any, past_modules: any, id: any, showPrevious: boolean, from_date: any, to_date: any, past_from_date: any, past_to_date: any, past_statements: any[], is_synthetic: any};
  @Input() locked: boolean;
  @Input() reportLock: boolean;
  @Input() entity: any;

  public processingTree = false;
  public activateActionButtons = false;
  public validationErrors = [];
  public noDataMessage = 'No data to display';
  // start addition
  dateRangePicker = new UntypedFormControl();
  // end addition
  public previousYearValues: any = {};
  public previousValueSource: any = {};
  loadingData = false;
  autoCalc = true;
  constructor(public companyService: CompanyReportsService,
              private router: Router,
              private toastr: ToastrService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.incomeStatement) {
      this.incomeStatement.modules =  changes.incomeStatement.currentValue.modules;
    }

  }
  ngOnInit() {
     /**AutoCalc function will be enabled by default and the toggle functionality will be hidden for now.
     * Following condition will be uncommented once the toggle functionality is enabled back
    */
    // if(this.incomeStatement.company_report.report_type === 'interim') {
    //   this.autoCalc = false;
    // }
    if(this.incomeStatement?.past_statement_id) {
      this.dateRangePicker.setValue(this.incomeStatement?.past_statement_id)
    } else if(this.incomeStatement?.past_statements !== null && this.incomeStatement.past_statements.length) {
      this.dateRangePicker.setValue(this.incomeStatement.past_statements[0].id);
      this._check();
    } else {
      this.dateRangePicker.setValue(null);
    }
  }
  revertTree(modules, quantity) {
    modules.forEach(element => {
      element.display_value = element.initialValue_rep ? (element.initialValue_rep / quantity).toString() : null;
      element.value_rep = element.initialValue_rep;
      element.has_error = false;
      element.has_limit_error = false;
      if (element.children && element.children.length > 0) {
        this.revertTree(element.children, quantity);
      }
    });
  }
  saveTree(editedModules, isId: number) {
    if (this.allowSave()) {
      const data = [];
      this.processingTree = true;
      getEditedModules(editedModules);
      function getEditedModules(modules) {
        modules.forEach(element => {
          if (element.initialValue_rep === undefined) {
            element.initialValue_rep = null;
          }
          if (element.initialValue_rep !== element.value_rep) {
            if (element.figure_id === null) {
              data.push({ module_id: element.module_id, value_rep: element.value_rep });
            } else {
              data.push({ figure_id: element.figure_id, value_rep: element.value_rep });
            }
          }
          if (element.children && element.children.length > 0) {
            getEditedModules(element.children);
          }
        });
      }

      this.companyService.saveEditordata({ editor_data: data }, isId, 'income_statement').subscribe((response: any) => {
        if (response && response.modules && response.quantity) {
          this.activateActionButtons = false;
          this.incomeStatement.modules = response.modules;
          this._check();
          this.toastr.success('Data inserted successfully!', 'Income Statement');
        } else {
          this.toastr.error('Sorry, some error occurred', 'Income Statement');
        }

        this.processingTree = false;
      },
        err => {
          if(err.hasOwnProperty('type')){
            if(err.type === "LOCKED_ERROR" && !this.reportLock){
              this.toastr.warning('ML data is being processed', 'ML Error');
            } else {
              this.toastr.warning(err.data.message);
            }
          }
          if (err.status === 404) {
            this.router.navigate(['income-statement', 'error'], { skipLocationChange: true });
          }
          this.processingTree = false;
        });
    }
  }
  allowSave() {
    return (this.validationErrors.length === 0);
  }
  enableActionButtons(event) {
    if(!this.autoCalc){
      this.activateActionButtons = true;
    } else {
      if (this.incomeStatement.modules) {
        let index = this.incomeStatement.modules.findIndex(module => module.line_id === event);
        if (index >= 0) {
          // if the edited node is level node
          let initialValue = null;
          const module = this.incomeStatement.modules[index];
          if (module.level) {
            if (module.display_value && module.display_value.trim() && !isNaN(module.display_value)) {
              initialValue = this.incomeStatement.modules[index].display_value;

            } else {
              initialValue = 0;
            }
          } else {
            // find first level node from current node to upward

            let parentLevelNodeIndex = null;
            this.incomeStatement.modules.forEach((moduleItem, arrayIndex) => {
              if (moduleItem.level && arrayIndex < index) {
                parentLevelNodeIndex = arrayIndex;
              }
            })
            if (parentLevelNodeIndex !== null) {
              index = parentLevelNodeIndex;
              const parentLevelNode = this.incomeStatement.modules[parentLevelNodeIndex];
              if (parentLevelNode.display_value && parentLevelNode.display_value.trim() && !isNaN(parentLevelNode.display_value)) {
                initialValue = parentLevelNode.display_value;
              } else {
                initialValue = 0;
              }
            }
          }

          this.calculateSumAtLevel(index, initialValue);
        }

        this.activateActionButtons = true;
      }
    }
  }
  calculateSumAtLevel(moduleIndex: number, initialValue: number) {
    let sum = initialValue !== null ? parseFloat(initialValue.toString()) : 0;
    if (this.incomeStatement && this.incomeStatement.modules && this.incomeStatement.modules.length > 0) {
      this.incomeStatement.modules.forEach((element, index) => {

        if (element.level && index > moduleIndex) { // (index > moduleIndex) only need to update the sum of levels that is  just below of edited node
          element.display_value = sum.toString();
          element.value_rep = element.display_value * this.incomeStatement.quantity.multiplier
        }
        if (initialValue !== null) {
          // if edited node is level, then
          if (index > moduleIndex) {
            if (!element.level && element.display_value && element.display_value.trim() && !isNaN(element.display_value)) {
              sum += parseFloat(element.display_value);
            }
          }
        } else {
          if (!element.level && element.display_value && element.display_value.trim() && !isNaN(element.display_value)) {
            sum += parseFloat(element.display_value);
          }
        }


      })
    }
  }
  onReachingMaxValue(event) {
    const node = event.node;
    const index = this.validationErrors.findIndex((element) => {
      return (element.name === node.name && element.module_id === node.module_id && element.fragment_id === node.fragment_id);
    });

    if (event.action === 'add') {
      if (index === -1) {
        this.validationErrors.push(node);
      }
      const maxValue = 999999999999999.9999999 / node.quantity;
      this.toastr.error('Enter a value less than ' + maxValue + ' ' + this.incomeStatement.quantity.name, 'Limit exceeded');
    } else if (event.action === 'remove' && index !== -1) {
      this.validationErrors.splice(index, 1);
    }
  }
  getYear(date) {
    if (date) {
      return moment(date).year();
    } else {
      return '';
    }
  }

  _check() {
    if(this.incomeStatement.id && this.dateRangePicker.value) {
      this.loadingData = true;
      this.companyService.getIncomeStatementById(+this.incomeStatement.id).subscribe((initialIncomeStatement: any) => {
        this.companyService.getIncomeStatementById(+this.dateRangePicker.value).subscribe((pastIncomeStatement: any) => {
          initialIncomeStatement.past_modules = pastIncomeStatement.modules;
          this.previousYearValues = {};
          this.previousValueSource = {};
          this.companyService.formatPreviousYearData(initialIncomeStatement, this.previousYearValues, this.previousValueSource);
          this.incomeStatement.modules = initialIncomeStatement.modules;
          this.incomeStatement.past_from_date = pastIncomeStatement.from_date;
          this.incomeStatement.past_to_date = pastIncomeStatement.to_date;
          this.loadingData = false;
        }, (error) => {
          console.error(error);
          this.loadingData = false;
        });
      }, (error) => {
        console.error(error);
        this.loadingData = false;
      });
    }
  }

  clearFormData() {
    if(this.incomeStatement.modules) {
      this.companyService.clearValues(this.incomeStatement.modules);
      this.activateActionButtons = true;
    }
  }

  toggleCalcOff() {
    this.autoCalc = !this.autoCalc;
  }
}
