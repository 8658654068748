
import {switchMap, take} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { StaticSettings } from '../../settings.class';

import { EntitiesService } from '../../entities/services/entities.service';
import { TranslationService } from 'app/utility/services/translation.service';
import { CountService } from '../services/count.service';
import { StaticService } from '../services/static.service';
@Component({
    selector: 'con-static-company',
    templateUrl: './static-company.component.html'
})

export class StaticCompanyComponent implements OnInit, OnDestroy {
    public company: any;
    private loading = true;
    public view: string;
    @Input() entity: any = {};
    tabName: any = 'all';
    sectors: any;
    private subscription: any;

    constructor(private service: EntitiesService,
                private http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private staticService: StaticService,
                private countService: CountService,
                private transService: TranslationService) {}

    public isLoading() {
        return this.loading;
    }

    ngOnInit() {
        this.staticService.getSectorEndpoint().pipe(take(1)).subscribe((sectors) => {
          this.sectors = sectors;
        })
        this.loadData();
        this.subscription = this.transService.onTranslationSaved.subscribe((companyId)=>{
            if(this.company.id === companyId) {
                this.loadData();
            }
        })
    }

    loadData() {
        this.route.queryParams
                  .subscribe((params: Params) => {
                      if (!params.view && ['mssing', 'revision'].indexOf(params.view) < 0) {
                          this.router.navigate([], { queryParams:  { view: 'missing' }});
                      } else {
                          this.view = params.view;
                      }
                  });
                  if(this.entity && this.entity.id){
                    this.http.get(StaticSettings.BASE_URL + '/' + StaticSettings.COMPANY_ENDPOINT + '/' + this.entity.id).subscribe(company => {
                        this.onDataReceived(company);
                    },
                    err => {
                        this.manageApiError(err);
                    });
                  }else{
                    this.route.params.pipe(
                        switchMap((params: Params) => {
                            this.loading = true;

                            return this.http.get(StaticSettings.BASE_URL + '/' + StaticSettings.COMPANY_ENDPOINT + '/' + params.id);
                        }))
                        .subscribe(company => {
                            this.onDataReceived(company);
                            this.countService.context = '';
                            this.countService.getCounts(true).pipe(take(1)).subscribe();
                        },
                        err => {
                            this.manageApiError(err);
                        });
                  }

    }

    onDataReceived(company: Object) {
        this.company = company;
        this.loading = false;
    }

    manageApiError(err) {
        if (err.status === 404) {
            this.router.navigate(['static', 'error'], { skipLocationChange: true });
        }
        this.loading = false;
    }

    updateCompany(company: any) {
        this.company = company;
    }

    onTabSelected(tabName){
        this.tabName= tabName;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
