<div>
<div class="container-fluid mb-3">
    <div>
        <div class="row">
            <div class="col-12">
                <div class="page-title mb-2 pb-3 pt-3">
                    <h3 class="mb-0">Shareholders</h3>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-start sticky-header non-sticky py-2">
            <h3><span class="badge badge-dark">{{getDisplayTitle()}}</span></h3>
            <h6 class="mb-0 ml-15" *ngIf='entity?.is_semi_annual === true'><a target='_blank' routerLink='../../../reporting-standard/company/{{entity?.company_id}}'><span class="badge primary-badge mt-10">Bi Annual Reporting Standard</span></a> </h6>
            <h6 class="mb-0 ml-15" *ngIf='entity?.is_semi_annual === false || entity?.is_semi_annual === null'><a target='_blank' routerLink='../../../reporting-standard/company/{{entity?.company_id}}'><span class="badge primary-badge mt-10">Normal Reporting Standard</span></a></h6>
          <span *ngIf="entity?.company?.website" class="website-link-positioning">
            <a [href]=entity?.company?.website class="link-fontsize" target="_blank">
              {{companyService.getStripedWebsiteString(entity?.company?.website)}}</a>
          </span>
            <div class="chip chip-inverse ml-auto">
                <con-avatar [userId]="entity.company.manager?.id" [size]="32"></con-avatar>
                {{ entity.company.manager?.first_name }} {{ entity.company.manager?.last_name }}
            </div>
        </div>
        <div class="alert ml-status-created" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.CREATED">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.CREATED_MESSAGE}}
          <button class='btn btn-primary pull-right cancel-btn-pos' (click)='companyService.cancelRequest(this.entity.id)'>Cancel</button>
        </div>
        <div class="alert ml-status-in-progress" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.IN_PROGRESS">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.IN_PROGRESS_MESSAGE}}
          <button class='btn btn-primary pull-right cancel-btn-pos' (click)='companyService.cancelRequest(this.entity.id)'>Cancel</button>
        </div>
        <div class="alert ml-status-submitted" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.SUBMITTED">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.SUBMITTED_MESSAGE}}
        </div>
        <div class="alert ml-status-closed" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.CLOSED">
          <con-icon-by-name iconName="check"></con-icon-by-name> {{MLSendStatus.CLOSED_MESSAGE}}
        </div>
        <div class="alert ml-status-failed" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.FAILED">
          <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.FAILED_MESSAGE}}
        </div>
        <con-no-data *ngIf="entity?.locked && !settingLock && !loading" count="0" class="locked-report"></con-no-data>
        <con-loader *ngIf="loading || settingLock"></con-loader>
        <div class="row" *ngIf="!settingLock">
            <div class="col-12">
                <div *ngIf="!loading">
                    <div class="card mb-2" *ngIf="!entity?.locked && !entitiesService.entityLockedByMl(entity)">
                        <div class="card-header" (click)="toggleType()">
                            <div class="d-flex justify-content-start align-items-center">
                                <con-icon-by-name iconName="group" [mutations]="['fw']" class="mr-1"></con-icon-by-name>New
                                shareholder
                                <div class="card-header-toggle-individual ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="p-4 col-6">
                            <con-entity-form-content [fromReports]="true" [fromShareholder]="true"
                                [fixedValues]="{ source_type: 'CompanyReport', source_id: entity.id }"
                                [parentCompany]="{id: entity?.company?.id, name: entity?.company?.name}"
                                entityName="Shareholder" [showWhenChanged]="false" [canDelete]="false"
                                emptyAfterSave="true" (afterSave)="shareholderCreated($event)"
                                (afterOwnershipChange)="shareholderCreated($event)"
                                toastMessage="Shareholder added successfully!">
                            </con-entity-form-content>
                        </div>
                    </div>
                    <div *ngIf="!loading">
                        <div *ngFor="let shareholder of companyReport.data; let index = index">
                            <div>
                                <div class="card mb-2">
                                    <div class="card-header" (click)="toggleType(shareholder)">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <con-icon-by-name iconName="group" [mutations]="['fw']" class="mr-1 cursor-pointer" (click)="companyService.goToEntity($event, 'shareholder', shareholder?.id)"></con-icon-by-name>
                                            Shareholder
                                            <div class="card-header-toggle-individual ml-auto">
                                                {{shareholder.shareholder_date}}
                                                <con-icon-by-name iconName="angle-right"
                                                    [mutations]="getMutations(shareholder)"></con-icon-by-name>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-4 col-6" *ngIf="!shareholder.hide">
                                        <con-entity-form-content
                                            [fromReports]="true"
                                            [parentCompany]="{id: entity?.company?.id, name: entity?.company?.name}"
                                            [entity]="shareholder" entityName="Shareholder"
                                            [fixedValues]="{source_id: shareholder.source_id, source_type: shareholder.source_type}"
                                            [showWhenChanged]="false" [canDelete]="false"
                                            (afterSave)="shareholderUpdated($event, index)"
                                            (afterOwnershipChange)="shareholderCreated($event)"
                                            toastMessage="Shareholder updated successfully!" [canEdit]="!entity?.locked && !entitiesService.entityLockedByMl(entity)">
                                        </con-entity-form-content>
                                    </div>
                                    <div class="card-body mb-2" *ngIf="shareholder.id && !shareholder.hide">
                                        <div class="card-header">
                                            <con-icon-by-name iconName="group" [mutations]="['fw']"></con-icon-by-name>
                                            Owners
                                        </div>
                                        <div class="card-body">
                                            <con-entity-form-content-table entityName="Owner"
                                                [only]="['name', 'shares', 'capital', 'votes']"
                                                [fixedValues]="{ shareholder_id: shareholder.id }"
                                                [showWhenChanged]="false" [canDelete]="!entity?.locked && !entitiesService.entityLockedByMl(entity)"
                                                [canEdit]="!entity?.locked && !entitiesService.entityLockedByMl(entity)"
                                                [isLocked]="entity?.locked || entitiesService.entityLockedByMl(entity)" [entityList]="shareholder.owners"
                                                toastMessage="Owner added successfully!">
                                            </con-entity-form-content-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <con-no-data [count]="companyReport?.data.length" [message]="noDataMessage"></con-no-data>
                    </div>
                </div>
            </div>
        </div>

        <hr>
    </div>
</div>
<div class="d-flex justify-content-center mt-2" [ngClass]="{'hide-pagination':!companyReport?.total}">
    <ngb-pagination [collectionSize]="companyReport?.total" [page]="companyReport?.current_page"
        (pageChange)="loadPage($event)" [pageSize]="companyReport?.per_page" maxSize="10" [rotate]="true">
    </ngb-pagination>
</div>
</div>
