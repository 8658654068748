import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EntitiesService } from '../../../entities/services/entities.service';
import { CompanyReportsService } from '../../services/company-reports.service';
import { MLSendStatus } from 'app/company-reports/models/MLSendStatus';
import * as moment from 'moment';
import { ApiSettings, CompanyReportsSettings } from '../../../settings.class';
import { ProfileService } from '../../../auth/services/profile.service';
import { Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'con-reporting-standard',
  templateUrl: './reporting-standard.component.html',
  styleUrls: ['./reporting-standard.component.scss']
})
export class ReportingStandardComponent implements OnInit {
  @Input() entity: any = {};
  public resetKey = 'ISNew';
  public companyTierId: number = CompanyReportsSettings.TIER_ID;
  showAddEvent = false;
  expandAddForm = true;
  runUpdate: Subject<boolean> = new Subject<boolean>();


  public loading: boolean;
  private page$ = new Subject<number>();
  public currentPage = 1;
  public data: any;
  private extraShow: any = {};
  public noDataMessage = 'No reporting standard to display.';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private http: HttpClient,
              public profile: ProfileService,
              public entitiesService: EntitiesService,
              public companyService: CompanyReportsService) { }

  ngOnInit(): void {
  }

  reportingStandardCreated($event: any) {
    this.entitiesService.resetDayInput.next(this.resetKey);
    this.runUpdate.next(true);
  }
  navigateToCompany(company: any) {
    this.router.navigate(['company_reports','reporting-standard', 'company', company.id]);
  }

  toggleType() {
    this.expandAddForm = ! this.expandAddForm;
  }

  removeAddEvent() {
    this.showAddEvent = false;
  }

  getMutations() {
    const mutation = !this.expandAddForm ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
    return mutation;
  }

  addNewEvent() {
    this.showAddEvent = true;
  }
}
