import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../auth/services/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'con-reporting-standard-start',
  templateUrl: './reporting-standard-start.component.html',
  styleUrls: ['./reporting-standard-start.component.scss']
})
export class ReportingStandardStartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
