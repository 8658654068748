<div class='container-fluid mb-3'>
  <div class='row'>
    <div class='col-12'>
      <div class='page-title mb-2 pb-3 pt-3'>
        <h3 class='mb-0'>Report Overview</h3>
      </div>
    </div>
  </div>
  <div class='row'>
    <div class='col-12'>
      <div class='d-flex justify-content-start py-2 sticky-header non-sticky'>
        <h3 class='mb-0'><span class='badge badge-dark'>{{ getDisplayName() }}</span></h3>
        <h6 class="mb-0 ml-15" *ngIf='entity?.is_semi_annual === true'><a target='_blank' routerLink='../../../reporting-standard/company/{{entity?.company_id}}'><span class="badge primary-badge mt-10">Bi Annual Reporting Standard</span></a> </h6>
        <h6 class="mb-0 ml-15" *ngIf='entity?.is_semi_annual === false || entity?.is_semi_annual === null'><a target='_blank' routerLink='../../../reporting-standard/company/{{entity?.company_id}}'><span class="badge primary-badge mt-10">Normal Reporting Standard</span></a></h6>
        <span *ngIf='entity?.company?.website' class='website-link-positioning'>
          <a [href]=entity?.company?.website class='link-fontsize' target='_blank'>
            {{companyService.getStripedWebsiteString(entity?.company?.website)}}</a>
        </span>
        <div class="ml-auto">
          <a [routerLink]="['/company_reports/report-event', entity?.company?.id]" [queryParams]="{view: 'upcoming'}" target="_blank">
            <div class="chip chip-inverse">
              <con-icon-by-name iconName="external-link"></con-icon-by-name>
              Upcoming Events
            </div>
          </a>
          <a [href]="entity?.task_backlink + '/annotate'" target='_blank' *ngIf='entity?.task_backlink'>
            <div class="chip chip-inverse">
              <con-icon-by-name iconName="external-link"></con-icon-by-name>
              Doc Processing
            </div>
          </a>
          <div class='chip chip-inverse'>
            <con-avatar [userId]="" [size]="32"></con-avatar>
            <span *ngIf="entity.company.manager">{{entity.company.manager?.first_name + ' ' + entity.company.manager?.last_name}}</span>
          </div>
        </div>
      </div>
      <div class='alert annotation-error-label text-white'
           *ngIf='entity?.is_fully_annotated !== null && !entity?.is_fully_annotated'>
        <con-icon-by-name iconName='gear'></con-icon-by-name>
        Not fully annotated
      </div>
      <div class='alert ml-status-created' *ngIf='companyService.mlCurrentStatus() === MLSendStatus.CREATED'>
        <con-icon-by-name iconName='gear'></con-icon-by-name>
        {{MLSendStatus.CREATED_MESSAGE}}
        <button class='btn btn-primary pull-right cancel-btn-pos' (click)='companyService.cancelRequest(this.entity.id)'>Cancel</button>
      </div>
      <div class='alert ml-status-in-progress' *ngIf='companyService.mlCurrentStatus() === MLSendStatus.IN_PROGRESS'>
        <con-icon-by-name iconName='gear'></con-icon-by-name>
        {{MLSendStatus.IN_PROGRESS_MESSAGE}}
        <button class='btn btn-primary pull-right cancel-btn-pos' (click)='companyService.cancelRequest(this.entity.id)'>Cancel</button>
      </div>
      <div class='alert ml-status-submitted' *ngIf='companyService.mlCurrentStatus() === MLSendStatus.SUBMITTED'>
        <con-icon-by-name iconName='gear'></con-icon-by-name>
        {{MLSendStatus.SUBMITTED_MESSAGE}}
      </div>
      <div class='alert ml-status-closed' *ngIf='companyService.mlCurrentStatus() === MLSendStatus.CLOSED'>
        <con-icon-by-name iconName='check'></con-icon-by-name>
        {{MLSendStatus.CLOSED_MESSAGE}}
      </div>
      <div class='alert ml-status-failed' *ngIf='companyService.mlCurrentStatus() === MLSendStatus.FAILED'>
        <con-icon-by-name iconName='gear'></con-icon-by-name>
        {{MLSendStatus.FAILED_MESSAGE}}
      </div>
      <con-no-data *ngIf='entity?.locked && !settingLock' count='0' class='locked-report'></con-no-data>
      <con-loader *ngIf='settingLock'></con-loader>
      <div *ngIf='!settingLock'>
        <div class='full-width-custom'>
          <div class='one-third-split'>
            <div class='card-content taxonomy-td'>
              <ng-container>
                <ng-container *ngFor='let relation of entityDescription?.getRelationsOutsideForm()'>
                  <div class='row' *ngIf="relation.name === 'taxonomies'">
                    <div class='col-md-12'>
                      <div class='card'>
                        <div class='card-header cursor-pointer' role='tab'
                             (click)='expandTaxonomy()'>
                          <div class='d-flex justify-content-start'>
                            <con-icon-by-name iconName='object-group' [mutations]="['fw']"
                                              class='mr-1'></con-icon-by-name>
                            {{relation.label }}
                            <div class='card-header-toggle ml-auto'>
                              <con-icon-by-name iconName='angle-right'
                                                [mutations]='getMutations(showTaxonomy)'></con-icon-by-name>
                            </div>
                          </div>
                        </div>
                        <div *ngIf='showTaxonomy'>
                          <con-to-many-editor [canShow]='true' [canEdit]='true' [relation]='relation'
                                              [entity]='entity' [showEdit]='true'
                                              (onChange)='afterRelationChange($event)'>
                          </con-to-many-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <!-- start create -->
            <div class='mb-2'></div>

            <div class='card-content taxonomy-td' *ngIf='!entity.locked'>
              <div class='row'>
                <div class='col-md-12'>
                  <div class='card'>
                    <div class='card-header cursor-pointer' role='tab'
                         (click)='expandCreateStatementListing()'>
                      <div class='d-flex justify-content-start'>
                        <con-icon-by-name iconName='object-group' [mutations]="['fw']" class='mr-1'></con-icon-by-name>
                        Create Combined Statements
                        <div class='card-header-toggle ml-auto'>
                          <con-icon-by-name iconName='angle-right'
                                            [mutations]='getMutations(showCreateStatements)'></con-icon-by-name>
                        </div>
                      </div>
                    </div>
                    <div *ngIf='showCreateStatements' class='p-15'>
                      <con-entity-form-content [resetKey]='resetKey' entityName='CustomCombinedStatements'
                                               [entity]='entityCompanyReport'
                                               [parentCompany]='{id: entity?.company?.id, name: entity?.company?.name, report_type:entity?.report_type}'
                                               [preFillValues]='{cash_flow:true, balance_sheet: true, income_statement: true}'
                                               [fixedValues]='{ company_report_id: entity?.id }'
                                               [showWhenChanged]='false'
                                               [canDelete]='false' [emptyAfterSave]='true'
                                               (afterSave)='afterDataSaved($event, false)'
                                               [combinedStatements]='true'
                                               [canStore]='!service.entityLockedByMl(entity)'
                                               toastMessage='Statements added successfully!'>
                      </con-entity-form-content>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- start edit -->
            <div class='mb-2'></div>
            <div class='card-content taxonomy-td' *ngIf='!entity.locked'>
              <div class='row'>
                <div class='col-md-12'>
                  <div class='card'>
                    <div class='card-header cursor-pointer' role='tab'
                         (click)='expandEditStatementListing()'>
                      <div class='d-flex justify-content-start'>
                        <con-icon-by-name iconName='object-group' [mutations]="['fw']" class='mr-1'></con-icon-by-name>
                        Statements Bulk Edit
                        <div class='card-header-toggle ml-auto'>
                          <con-icon-by-name iconName='angle-right'
                                            [mutations]='getMutations(showEditStatementListing)'></con-icon-by-name>
                        </div>
                      </div>
                    </div>
                    <div *ngIf='showEditStatementListing' class='p-15'>
                      <con-combined-statements-edit-view
                        [resetKey]='resetKey' entityName='CustomCombinedStatements'
                        [entity]='entityCompanyReport'
                        [parentCompany]='{id: entity?.company?.id, name: entity?.company?.name, report_type:entity?.report_type}'
                        [fixedValues]='{ company_report_id: entity?.id }' [showWhenChanged]='false'
                        [canDelete]='false' [emptyAfterSave]='true' (afterSave)='afterDataSaved($event, true)'
                        [combinedStatements]='true'
                        [canStore]='!service.entityLockedByMl(entity)'
                        toastMessage='Statements updated successfully!'
                        [editMode]='true'
                      ></con-combined-statements-edit-view>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end edit -->
            <!-- Listing -->
            <div class='mb-2'></div>
            <con-statements-listing [entity]='entity' (updatedFigures)='reloadEditView($event)'>

            </con-statements-listing>

          </div>
          <div class='two-third-split-with-gutter'>
            <div class='card-content'>
              <ng-container>
                <ng-container *ngFor='let relation of entityDescription?.getRelationsOutsideForm()'>
                  <div class='row' *ngIf="relation.name === 'companyReportFiles'">
                    <div class='col-md-12'>
                      <div class='card'>
                        <div class='card-header cursor-pointer' role='tab'
                             (click)='expandReports()'>
                          <div class='d-flex justify-content-start'>
                            <con-icon-by-name [iconName]='entityDescription.getIcon()' [mutations]="['fw']"
                                              class='mr-1'></con-icon-by-name>
                            <span>{{relation.label}}</span>
                            <div class='card-header-toggle ml-auto'>
                              <con-icon-by-name iconName='angle-right'
                                                [mutations]='getMutations(showReports)'></con-icon-by-name>
                            </div>
                          </div>
                        </div>

                        <div *ngIf='showReports'>
                          <con-to-many-editor [canShow]='true' [canEdit]='true' [relation]='relation'
                                              [entity]='entity' [showEdit]='true' [customCompanyReportFileView]='true'
                                              (onChange)='afterRelationChange($event)'>
                          </con-to-many-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
