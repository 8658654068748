import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ApiSettings, AuthSettings, PressReleaseAttachmentSettings } from '../../settings.class';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EntityAttachmentsService } from '../services/entity-attachments.service';
import { ValidatorConversionService } from '../../entities/services/validator-conversion.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EntitiesService } from 'app/entities/services/entities.service';

@Component({
  selector: 'con-multi-file-uploader',
  templateUrl: './multi-file-uploader.component.html',
  styleUrls: ['./multi-file-uploader.component.scss']
})
export class MultiFileUploaderComponent implements OnDestroy, OnInit, OnChanges {
  @Input() control;
  @Input() entityForm: FormGroup;
  @Input() isSaving: boolean = false;

  private componentDestroyed$ = new Subject();
  // file drop
  public files: NgxFileDropEntry[] = [];
  private UPLOAD_CONFIG: any = {
    url: ApiSettings.BASE_URL + '/' + ApiSettings.TEMPORARY_STORAGE,
    autoUpload: true,
    authTokenHeader: AuthSettings.HEADER_NAME,
    authToken: AuthSettings.HEADER_PREFIX + ' ' + AuthSettings.getToken()
  }
  uploadProgresses: { id: string; file: File; progress: number }[] = [];
  entityName: string = "PressReleaseAttachment"
  invalidFileNames: {size: string[], extension: string[]} = {size: [], extension: []};
  maxFileSize: number;
  validExtensions: string[];
  acceptParameterValue: string;

  constructor(private http: HttpClient,
    private attachmentService: EntityAttachmentsService,
    private validationService: ValidatorConversionService,
    private toaster: ToastrService, 
    private fb: FormBuilder,
    private entityService: EntitiesService
  ) { }

  ngOnInit(): void {
    this.entityService.getEntityDescriptionByEntityName(this.entityName).pipe(takeUntil(this.componentDestroyed$)).subscribe((entityDetails) => {
      const fieldRules = entityDetails.data.fields.find((field) => field.key === 'source').rules
      this.maxFileSize = this.validationService.getFileMaxSizeFromRules(fieldRules);
      this.validExtensions = this.validationService.getFileValidExtensionsFromRules(fieldRules);
      this.acceptParameterValue = this.validExtensions.map((ext) => `.${ext}`).join(',');
    })
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('isSaving') && changes.isSaving.currentValue){
      this.invalidFileNames = {size: [], extension: []};
    }
  }

  attachmentsGroup() : FormArray {
    return this.entityForm.get('attachments') as FormArray;
  }

  clearTempFiles() {
    let i;
    for (i = this.attachmentsGroup().controls.length - 1; i >= 0; i -= 1) {
      if (this.attachmentsGroup().controls[i].value.id === null) {
        this.attachmentsGroup().controls.splice(i, 1);
      }
    }
  }

  createAttachmentGroup(attachmentObject): FormGroup {
    return this.fb.group({
      id: attachmentObject.id,
      source: attachmentObject.source,
      file_name: attachmentObject.file_name,
      name: attachmentObject.name,
      ref_file_id: [attachmentObject.ref_file_id, [this.validationService.fileIdValidator]]
    })
  }

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.entityForm.markAsDirty();
    this.invalidFileNames = {size: [], extension: []};
    for (const [index, droppedFile] of files.entries()) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const fileErrors = this.validationService.validateFile(file, this.maxFileSize, this.validExtensions);
          if(fileErrors.length > 0){
            if(fileErrors.includes(PressReleaseAttachmentSettings.INVALID_SIZE_ERROR_KEY)){
              this.invalidFileNames.size.push(file.name);
            }
            if(fileErrors.includes(PressReleaseAttachmentSettings.INVALID_EXTENSION_ERROR_KEY)){
              this.invalidFileNames.extension.push(file.name);
            }
            return;
          }
          const uploadId = `${index}${Date.now().toString()}`;
          const formData = new FormData()
          formData.append('file', file, droppedFile.relativePath)
  
          // Headers
          const headers = new HttpHeaders({
            'Authorization': this.UPLOAD_CONFIG.authToken
          })
          this.uploadProgresses.push({ id: uploadId, file: file, progress: 0 });
          this.http.post(this.UPLOAD_CONFIG.url, formData, { headers: headers, reportProgress: true, observe: 'events' }).pipe(takeUntil(this.componentDestroyed$))
            .subscribe((data: any) => {
              if (data.type === HttpEventType.UploadProgress) {
                const progressIndex = this.uploadProgresses.findIndex((item) => item.id === uploadId);
                this.uploadProgresses[progressIndex].progress = Math.round(100 * data.loaded / data.total);
              } else if (data.type === HttpEventType.Response) {
                const tempAttachmentControl = this.createAttachmentGroup({
                  id: null,
                  file_name: data.body.file_name,
                  source: null,
                  name: droppedFile.relativePath,
                  ref_file_id: ''
                })
                this.removeUploadedFile(uploadId)
                this.attachmentsGroup().push(tempAttachmentControl);
              }
            },
              error => {
                this.removeUploadedFile(uploadId)
                this.toaster.error(`Failed to upload ${file.name}`, 'Something went wrong');
              })
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        // placeholder for future updates related empty directory handling
        // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
  
  removeUploadedFile(fileId: string): void {
    const spliceIndex = this.uploadProgresses.findIndex((item) => item.id === fileId);
    this.uploadProgresses.splice(spliceIndex, 1);
  }

  fileOver(event){
    // function call placeholder for future updates
  }

  fileLeave(event){
    // function call placeholder for future updates
  }

  removeFile(id, index) {
    if(id !== null) {
      this.attachmentService.markForDeletion(this.attachmentsGroup().at(index).value);
    }
    this.attachmentsGroup().removeAt(index);
    this.entityForm.markAsDirty();
  }

  updatedChangesInEditMode(item: FormGroup) {
    if(item.value.id !== null) {
      // in edit mode
     item.patchValue({
       file_name: item.value.source
     })
    }
  }
}
