import { Component, OnInit } from '@angular/core';
import { CompanyReportsSettings } from '../../../settings.class';
import { ProfileService } from '../../../auth/services/profile.service';
import { Router } from '@angular/router';
@Component({
  selector: 'con-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss']
})
export class UpcomingEventsComponent implements OnInit {
  public companyTierId: number = CompanyReportsSettings.TIER_ID;
  constructor(public profile: ProfileService, private router: Router) { }
  ngOnInit(): void {
  }
  navigateToCompany(company: any) {
    this.router.navigateByUrl(`company_reports/report-event/${company.id}?view=upcoming`);
  }
}
