import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {CompanyReportsSettings} from '../../../settings.class';
import {PaginatedDeliveries} from '../../models/PaginatedDeliveries';
import {MLSendStatus} from '../../models/MLSendStatus';
import {take} from 'rxjs/operators';
import {CompanyReportsService} from '../../services/company-reports.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'con-report-event-status',
  templateUrl: './report-event-status.component.html',
  styleUrls: ['./report-event-status.component.scss']
})
export class ReportEventStatusComponent implements OnInit, OnDestroy {
  public noDataMessage = 'No data to display.';
  public loading = true;
  public reportEventStatuses: any;
  public showCircularLoader = true;
  MLSendStatus = MLSendStatus;
  public filterModels = {
    selectedTypeFilter: '',
    selectedSort: 'created_at',
  }
  public filterTypes: any[];
  public selectedTypeFilter = {label: 'All', value: ''};
  private reportEventSubscription = new Subscription;
  constructor(private http: HttpClient,
              private router: Router,
              private toaster: ToastrService,
              public reportService: CompanyReportsService
  ) {
  }
  ngOnInit() {
    this.filterTypes = [
      {
        label: 'All',
        value: ''
      },
      {
        label: 'CREATED',
        value: 'CREATED'
      },
      {
        label: 'CLOSED',
        value: 'CLOSED'
      },
      {
        label: 'FAILED',
        value: 'FAILED'
      },
    ];
    this.loadPage(1);
  }
  ngOnDestroy() {
    this.reportEventSubscription.unsubscribe();
  }
  processParams(pageNumber: number) {
    const params: any = {};
    params.page = String(pageNumber);
    params.order_by = 'created_at';
    params.desc = true;
    if (this.selectedTypeFilter.value) {
      params.request_status = this.selectedTypeFilter.value;
    }
    return params;
  }
  loadPage(pageNumber: number) {
    this.loading = true;
    this.showCircularLoader = true;
    this.reportEventSubscription = this.http.get<any>(CompanyReportsSettings.BASE_URL + '/doc-processing-incoming-reports/',
      {
        params: this.processParams(pageNumber)
      })
      .subscribe(res => {
        this.formatDeliveriesData(res);
        this.loading = false;
        this.showCircularLoader = false;
      }, err => {
        this.loading = false;
        this.showCircularLoader = false;
        if (err.isPermissionError()) {
          this.router.navigate(['shareholders', 'auth'], {skipLocationChange: true});
        }
      });
  }
  formatDeliveriesData(response: any) {
    response.data.forEach((mlStatus, index) => {
      mlStatus.hide = true;
      mlStatus.loading = false;
    });
    this.reportEventStatuses = response;
  }
  public toggleType(docStatus) {
    docStatus.hide = !docStatus.hide;
    if (!docStatus.hide && !!docStatus.data === false) {
      docStatus.loading = true;
      this.http.get<any>(CompanyReportsSettings.BASE_URL + '/doc-processing-incoming-reports-data/' + docStatus.id + '/').pipe(take(1)).subscribe((response) => {
        docStatus.data = JSON.parse(response.instance_data);
        docStatus.loading = false;
      }, (error) => {
        console.error(error);
        docStatus.loading = false;
      });
    }
  }
  getMutations(event) {
    return (!event.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
  }
  selectTypeFilter(filter: any) {
    if (filter.value === this.selectedTypeFilter.value) {
      return;
    }
    this.selectedTypeFilter = filter;
    this.loadPage(1);
  }
  goToReport(event, docStatus) {
    event.stopPropagation();
    if(docStatus.company) {
      this.router.navigateByUrl(`company_reports/report-event/${docStatus.company.id}?view=missing`);
    }
  }
  getReportTitle(docStatus: any) {
    return docStatus.company.name;
  }
  copyJSON(event, data) {
     navigator.clipboard.writeText(JSON.stringify(data));
     const successMsg = ' JSON successfully copied to clipboard.'
    this.toaster.success(successMsg);
  }
}
