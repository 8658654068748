import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CompanyReportsService } from '../../services/company-reports.service';
import { Subject } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityConfirmModalComponent } from '../../../entities/components/views/entity-confirm-modal.component';
import { ToastrService } from 'ngx-toastr';
import { lowerCaseFirst } from 'change-case';
@Component({
  selector: 'con-events-table',
  templateUrl: './events-table.component.html',
  styleUrls: ['./events-table.component.scss']
})
export class EventsTableComponent implements OnInit {
  @Input() is_upcoming = false;
  @Input() companyId = null;
  @Input() reloadListing = false;
  @Input() customSortAndSearch = null;
  public loading: boolean;
  private page$ = new Subject<number>();
  public currentPage = 1;
  public responseData: any;
  public missingActions = [];
  public upcomingActions = [];
  public customFilterQuery = '';
  public filterExpanded = true;
  constructor(private fb: FormBuilder,
              private modalService: NgbModal,
              private toaster: ToastrService,
              private reportService: CompanyReportsService) {
  }
  ngOnInit(): void {
    this.missingActions.push({
      label: 'Edit',
      icon: 'plus-circle',
      buttonClass: 'primary',
      name: 'edit'
    });
    this.upcomingActions.push(
      {
        label: 'Start',
        icon: 'plus-circle',
        buttonClass: 'primary',
        name: 'start'
      });
  }
  updateFilterTerms(data: string) {
    this.customFilterQuery = data;
  }
}
