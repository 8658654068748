import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntitiesService, EntityDescription } from '../../services/entities.service';
import { SelectedInstancesService } from '../../../doc-process/sub-modules/kpi-and-kiid/services/selected-instances.service';
import { TranslationService } from '../../../utility/services/translation.service';
import { FileTokenService } from '../../../auth/services/file-token.service';
import { combineLatest, debounceTime, startWith, switchMap, take, tap } from 'rxjs/operators';
import { ApiSettings } from '../../../settings.class';
import { lowerCase, snakeCase } from 'change-case';
import { EntityConfirmModalComponent } from './entity-confirm-modal.component';
import { CompanyReportsService } from '../../../company-reports/services/company-reports.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';
import { ProfileService } from '../../../auth/services/profile.service';
import { UsersService } from '../../../shared/services/users.service';
@Component({
  selector: 'con-custom-entity-listing',
  templateUrl: './custom-entity-listing.component.html',
  styleUrls: ['./custom-entity-listing.component.scss']
})
export class CustomEntityListingComponent implements OnInit, OnChanges{
  @Input() entityName: string;
  @Input() isUpcoming: boolean;
  @Input() originEntityName: string = null;
  @Input() relation: string = null;
  @Input() entityId: number = null;
  @Input() actions: Array<any> = [];
  @Input() loaders: any = {};
  @Input() timestamps = false;
  @Input() runUpdate: Subject<boolean> = new Subject<boolean>();
  @Input() fixedSearchParams: any = {};
  @Input() isSelectable = false;
  @Input() external = false;
  @Input() externalMetaData: any;
  @Input() preserveSelection: any;
  @Input() enableHyperLink = true;
  @Input() smallCells = false;
  @Input() customAction = false;
  @Input() companyId = null;
  @Input() reloadListing = false;
  @Input() customFilterQuery = '';
  @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChecked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCheckedAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() onResponse: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedUrl = new EventEmitter<any>();
  private entityName$ = new Subject<string>();
  public searchForm = new FormGroup({});
  private page$ = new Subject<number>();
  public response: any;
  public entityDescription: EntityDescription;
  public currentPage  = 1;
  public loading: boolean;
  public noDataMessage = 'No data to display.';
  public orderField: string;
  public orderAsc: boolean;
  public allSelected: boolean;
  private snakeCase = snakeCase;
  private translationSub: Subscription;
  private customSortFilerEnabled: boolean = false;
  // private filterQuery = '?custom_sort=marketcap desc,report_date desc';
  private filterQuery = '';
  currentUserId: number;
  isSuperUser: boolean;
  users: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private service: EntitiesService,
    private reportService: CompanyReportsService,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private selectedInstancesService: SelectedInstancesService,
    private transService : TranslationService,
    private tokenService: FileTokenService,
    private usersService: UsersService,
    private profileService: ProfileService
  ) {}
  private resetOrdering() {
    if (!this.external) {
      this.orderField = 'marketcap';
      this.orderAsc = true;
    } else {
      this.orderField = 'id'
      this.orderAsc = true;
    }
  }
  public ngOnInit() {
    this.profileService.getUser().pipe(take(1))
      .subscribe(user => {
        this.currentUserId = user.id;
      })
    this.profileService.isSuperUser().pipe(take(1)).subscribe((isSuperUser: boolean) => {
      this.isSuperUser = isSuperUser
    });
    this.usersService.getUsers().pipe(take(1)).subscribe((users) => {
      if(users) {
        this.users = users;
      }
    })
    this.loadData();
    this.translationSub= this.transService.onTranslationSaved.subscribe((companyId)=> {
      if(this.entityId == companyId) {
        this.loadData();
      }
    })
  }
  public loadData() {
    this.allSelected = false
    const entityNameObservable = this.entityName$.pipe(
      startWith(this.entityName),
      switchMap(entityName => {
        const x = {
            "fields": [
              {
                "type": "day",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": true,
                "show_in_update": true,
                "key": "report_date",
                "label": "Report Date",
                "rules": [
                  "string",
                  "date",
                  "required",
                  "date_format:Y-m-d"
                ],
                "format": "Y-m-d"
              },
              {
                "type": "int",
                "is_searchable": false,
                "is_filterable": false,
                "is_primary": false,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": true,
                "show_in_update": true,
                "key": "marketcap",
                "label": "Market Cap",
                "rules": [
                  "numeric",
                  "nullable"
                ]
              },
              {
                "type": "string",
                "is_searchable": true,
                "is_filterable": true,
                "is_primary": true,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": false,
                "show_in_update": false,
                "key": "company.name",
                "label": "Comp. Name",
                "rules": [
                  "string",
                  "nullable"
                ]
              },
              {
                "type": "int",
                "is_searchable": true,
                "is_filterable": true,
                "is_primary": true,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": false,
                "show_in_update": false,
                "key": "claimed_by",
                "label": "Claimed By",
                "rules": [
                  "string",
                  "nullable"
                ]
              },
              {
                "type": "string",
                "is_searchable": true,
                "is_filterable": true,
                "is_primary": true,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": false,
                "show_in_update": false,
                "key": "taxonomy.name",
                "label": "Taxonomy. Name",
                "rules": [
                  "string",
                  "nullable"
                ]
              },
              {
                "type": "enum",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": true,
                "show_in_update": true,
                "key": "accounting_standard",
                "label": "Acc. Std",
                "rules": [
                  "alpha_dash_space",
                  "in:ifrs,us_gaap,swiss_gaap_fer,japanese_gaap,jmis,hkfrs,china_gaap,ind_as,tfrs,hgb",
                  "required"
                ],
                "values": [
                  {
                    "value": "ifrs",
                    "label": "IFRS"
                  },
                  {
                    "value": "us_gaap",
                    "label": "U.S. GAAP"
                  },
                  {
                    "value": "swiss_gaap_fer",
                    "label": "Swiss GAAP FER"
                  },
                  {
                    "value": "japanese_gaap",
                    "label": "Japanese GAAP"
                  },
                  {
                    "value": "jmis",
                    "label": "JMIS"
                  },
                  {
                    "value": "hkfrs",
                    "label": "HKFRS"
                  },
                  {
                    "value": "china_gaap",
                    "label": "China GAAP"
                  },
                  {
                    "value": "ind_as",
                    "label": "Ind AS"
                  },
                  {
                    "value": "tfrs",
                    "label": "TFRS"
                  },
                  {
                    "value": "hgb",
                    "label": "HGB"
                  }
                ]
              },
              {
                "type": "day",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": true,
                "show_in_update": true,
                "key": "period_end_date",
                "label": "Per. End Date",
                "rules": [
                  "string",
                  "date",
                  "required",
                  "date_format:Y-m-d"
                ],
                "format": "Y-m-d"
              },
              {
                "type": "int",
                "is_searchable": false,
                "is_filterable": false,
                "is_primary": false,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": true,
                "show_in_update": true,
                "key": "fiscal_year",
                "label": "Fis. Year",
                "rules": [
                  "numeric",
                  "nullable"
                ]
              },
              {
                "type": "int",
                "is_searchable": false,
                "is_filterable": false,
                "is_primary": false,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": true,
                "show_in_update": true,
                "key": "fiscal_offset",
                "label": "Fis. Offset",
                "rules": [
                  "numeric",
                  "nullable"
                ]
              },
              {
                "type": "text",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": true,
                "show_in_update": true,
                "key": "comment",
                "label": "Comment",
                "rules": [
                  "string",
                  "nullable"
                ]
              },
              {
                "type": "enum",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": true,
                "show_in_store": false,
                "show_in_update": true,
                "key": "data_entry_status",
                "label": "Status",
                "rules": [
                  "alpha_dash_space",
                  "in:Not Started,Data Entry,Verify,Completed,",
                  "nullable"
                ],
                "values": [
                  {
                    "value": "Not Started",
                    "label": "Not Started"
                  },
                  {
                    "value": "Data Entry",
                    "label": "Data Entry"
                  },
                  {
                    "value": "Verify",
                    "label": "Verify"
                  },
                  {
                    "value": "Completed",
                    "label": "Completed"
                  }
                ]
              },
              {
                "type": "string",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": false,
                "show_in_store": false,
                "show_in_update": false,
                "key": "companyReport.",
                "label": "Companyreport ",
                "rules": [
                  "string",
                  "nullable"
                ]
              },
              {
                "type": "int",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": false,
                "show_in_store": false,
                "show_in_update": false,
                "key": "company_report_id",
                "label": "Company Report Id",
                "rules": [
                  "numeric",
                  "nullable"
                ]
              },
              {
                "type": "string",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": false,
                "show_in_store": false,
                "show_in_update": false,
                "key": "taxonomy",
                "label": "Taxonomy ",
                "rules": [
                  "string",
                  "nullable"
                ]
              },
              {
                "type": "int",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": false,
                "show_in_store": false,
                "show_in_update": false,
                "key": "taxonomy_id",
                "label": "Taxonomy Id",
                "rules": [
                  "numeric",
                  "required"
                ]
              },
              {
                "type": "string",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": false,
                "show_in_store": false,
                "show_in_update": false,
                "key": "currency.",
                "label": "Currency ",
                "rules": [
                  "string",
                  "nullable"
                ]
              },
              {
                "type": "int",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": false,
                "show_in_store": false,
                "show_in_update": false,
                "key": "currency_id",
                "label": "Currency Id",
                "rules": [
                  "numeric",
                  "required"
                ]
              },
              {
                "type": "string",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": false,
                "show_in_store": false,
                "show_in_update": false,
                "key": "company.",
                "label": "Company ",
                "rules": [
                  "string",
                  "nullable"
                ]
              },
              {
                "type": "int",
                "is_searchable": false,
                "is_filterable": true,
                "is_primary": false,
                "disabled": false,
                "show_in_table": false,
                "show_in_store": false,
                "show_in_update": false,
                "key": "company_id",
                "label": "Company Id",
                "rules": [
                  "numeric",
                  "required"
                ]
              }
            ],
            "relations": [
              {
                "owner": "CompanyReportEvent",
                "name": "company",
                "foreign_key": "company_id",
                "label": "Company",
                "type": "belongsTo",
                "is_standard_relation": true,
                "is_standard_count": false,
                "disabled": false,
                "required": true,
                "model": "Company"
              },
              {
                "owner": "CompanyReportEvent",
                "name": "currency",
                "foreign_key": "currency_id",
                "label": "Currency",
                "type": "belongsTo",
                "is_standard_relation": true,
                "is_standard_count": false,
                "disabled": false,
                "required": true,
                "model": "Currency"
              },
              {
                "owner": "CompanyReportEvent",
                "name": "taxonomy",
                "foreign_key": "taxonomy_id",
                "label": "Taxonomy",
                "type": "belongsTo",
                "is_standard_relation": true,
                "is_standard_count": false,
                "disabled": false,
                "required": true,
                "model": "Taxonomy"
              },
              {
                "owner": "CompanyReportEvent",
                "name": "companyReport",
                "foreign_key": "company_report_id",
                "label": "Company Report",
                "type": "belongsTo",
                "is_standard_relation": false,
                "is_standard_count": false,
                "disabled": true,
                "required": false,
                "model": "CompanyReport"
              }
            ],
            "icon": "paperclip",
            "name": this.isUpcoming ? "CustomUpcomingCompanyReportEvent": "CustomMissingCompanyReportEvent"
          }
        const ed: EntityDescription= new EntityDescription(x);
        return of(ed);
      }),
      tap(entityDescription => {
        const form = {};
        entityDescription.getFilterableFields().forEach(field => {
          const fixedSearchObject = this.fixedSearchParams[field.key]
          if (fixedSearchObject) { form[field.key] = this.fixedSearchParams[field.key] } else { form[field.key] = ''; }
        });
        this.searchForm = this.formBuilder.group(form);
        this.page$ = new Subject<number>();
        this.resetOrdering();
        this.entityDescription = entityDescription;
      }),
      switchMap(entityDescription => {
        return this.searchForm.valueChanges.pipe(debounceTime(400),
          startWith({}),
          combineLatest(this.page$.pipe(startWith(1)),
            (terms, page) => {
              return {terms, page};
            }), );
      }),
      tap(params => this.currentPage = params.page),
      tap(params => this.loading = true),
      switchMap(res => {
        const terms = { ...this.fixedSearchParams, ...res.terms };
        if (this.relation) {
          return this.service.searchRelation(this.originEntityName,
            this.entityId,
            this.relation,
            terms,
            res.page,
            this.orderField,
            this.orderAsc);
        }
        if(this.companyId){
          terms['company_id'] = this.companyId;
        }
        return this.loadFilteredData()
        // below code is reference with row selection is enabled
        // return this.service.searchEntities(this.entityDescription.name,
        //   terms,
        //   {
        //     page: res.page,
        //     order_by: this.orderField,
        //     order_asc: this.orderAsc
        //   });
      }),
      switchMap(instance => {
        if (this.selectedInstancesService.selectedInstanceIds && instance.data) {
          this.handleSelectedInstances(instance, this.selectedInstancesService.selectedInstanceIds.projectInstanceTable)
        }
        if (this.selectedInstancesService.instancesMetadata && instance.data) {
          this.handleSelectedInstances(instance, this.selectedInstancesService.instancesMetadata.projectInstanceTable)
        }
        return of(instance)
      }))
      .subscribe(res => {
        if(this.filterQuery) {
          if (this.entityName === 'Owner') {
            this.formatOwnerData(res);
          } else {
            res.data.map(item => item.edit_mode = false);
            this.response = res;
            this.onResponse.emit(res);
          }
          this.loading = false;
        }
      }, err => {
        console.log(err);
        this.loading = false;
      });
  }
  ngOnDestroy(): void {
    this.translationSub.unsubscribe();
  }
  handleSelectedInstances(instance, selectedInstances) {
    let selectedCounter = 0
    instance.data.forEach(item => {
      if (selectedInstances.indexOf(item.id) > -1) {
        item.selected = true
        selectedCounter++
      }
    });
    if (selectedCounter === instance.data.length) { this.allSelected = true } else { this.allSelected = false }
  }
  public formatOwnerData(res) {
    if (res && res.data) {
      res.data.forEach(owner => {
        owner.capital = (owner.capital !== null && owner.capital !== '') ? (owner.capital * 100).toFixed(2) : null;
        owner.votes = (owner.votes !== null && owner.votes !== '') ? (owner.votes * 100).toFixed(2) : null;
      });
    }
    this.response = res;
  }
  public doAction(event, name, entity) {
    event.preventDefault();
    event.stopPropagation();
    if(name === 'edit') {
      entity.edit_mode = !entity.edit_mode;
    } else {
      this.onAction.emit({ entity, action: name });
    }
  }
  public ngOnChanges(event) {
    if (event.runUpdate) {
      this.runUpdate.subscribe(res => {
        this.page$.next(1);
      });
    }
    if (event.entityName) {
      this.entityName$.next(this.entityName);
    }
    if(event.reloadListing) {
      this.refresh();
    }
    if(event.customFilterQuery.currentValue) {
      this.filterQuery = event.customFilterQuery.currentValue;
      this.refresh();
    }
  }
  private loadFilteredData() {
    if(this.filterQuery) {
      if(this.companyId) {
        this.filterQuery += "&q:company_id=" + this.companyId;
      }
      return this.reportService.getReportEventCustomFilterData(this.entityDescription.name, this.filterQuery,this.currentPage);
    } else {
      return of(null)
    }
  }
  public changePage(event) {
    this.page$.next(event);
  }
  public fieldCanBeOrdered(field) {
    return field.key.indexOf('.') < 0;
  }
  public orderBy(field, asc) {
    this.orderField = field;
    this.orderAsc = asc;
    this.page$.next(1);
  }
  selectAll () {
    if (this.allSelected) {
      this.onCheckedAll.emit(this.response.data)
      this.response.data.forEach(entity => {
        entity.selected = true;
        this.selectRow(entity);
      });
    } else {
      this.onCheckedAll.emit([])
      this.response.data.forEach(entity => {
        entity.selected = false;
        this.selectRow(entity);
      });
    }
  }
  isAllSelected () {
    const selectedObject = JSON.parse(localStorage.getItem('selectedItems'));
    if (!selectedObject) {
      return false;
    } else {
      let allSelected = true;
      if (this.response && this.response.data) {
        this.response.data.forEach(entity => {
          if (!this.isChecked(entity.id)) {
            allSelected = false;
          }
        });
      }
      if (allSelected) {
        return true;
      } else {
        return false;
      }
    }
  }
  public selected(entity) {
    this.onChecked.emit(entity)
  }
  public selectRow (entity) {
    let selectedObject = this.selectedInstancesService.selectedInstanceIds// JSON.parse(localStorage.getItem('selectedItems'));
    if (!selectedObject) {
      selectedObject = {projectInstanceTable: []};
    }
    let selectedObjectDetails = this.selectedInstancesService.instancesMetadata// JSON.parse(localStorage.getItem('selectedItemDetails'));
    if (!selectedObjectDetails) {
      selectedObjectDetails = {projectInstanceTable: []};
    }
    if (entity.selected) {
      if (selectedObject[this.entityName] !== undefined && selectedObject[this.entityName].indexOf(entity.id) === -1) {
        selectedObject[this.entityName].push(entity.id);
      } else {
        selectedObject[this.entityName] = [entity.id];
      }
      if (selectedObjectDetails[this.entityName] !== undefined &&
        selectedObjectDetails[this.entityName].findIndex( ent => ent.id === entity.id) === -1) {
        selectedObjectDetails[this.entityName].push(entity);
      } else {
        selectedObjectDetails[this.entityName] = [entity];
      }
    } else {
      if (selectedObject[this.entityName] !== undefined) {
        const index = selectedObject[this.entityName].indexOf(entity.id);
        if (index > -1) {
          selectedObject[this.entityName].splice(index, 1);
        }
      }
      if (selectedObjectDetails[this.entityName] !== undefined) {
        const index = selectedObjectDetails[this.entityName].findIndex(ent => ent.id === entity.id);
        if (index > -1) {
          selectedObjectDetails[this.entityName].splice(index, 1);
        }
      }
    }
    this.handleSelectedInstances(this.response, selectedObject.projectInstanceTable)
    this.selectedInstancesService.addInstanceMetadata(selectedObjectDetails)
    this.selectedInstancesService.addselectedInstanceIds(selectedObject)
    // localStorage.setItem('selectedItems', JSON.stringify(selectedObject));
    // localStorage.setItem('selectedItemDetails', JSON.stringify(selectedObjectDetails));
  }
  public setSelection (data) {
    data.forEach(entity => {
      entity.selected = this.isChecked(entity.id);
    });
  }
  public isChecked (id: number) {
    const selectedObject = JSON.parse(localStorage.getItem('selectedItems'));
    if (selectedObject && selectedObject[this.entityName]) {
      if (selectedObject[this.entityName].indexOf(id) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public refresh() {
    this.page$.next(1);
  }
  public openTranslation(entity) {
    this.transService.onDescriptionSaved(entity);
  }
  public canTranslate(entity) {
    return this.entityName == "Description" && entity.language.name == "English";
  }
  // custom states
  executeNextState(evt, event: any) {
    evt.preventDefault();
    evt.stopPropagation();
    const patchString = this.getNextStateLabel(event.data_entry_status);
    if(patchString === 'Completed' && event.company_report === null) {
      this.toaster.warning("Please create a report before proceeding.");
      return;
    }
    if(patchString === 'Completed' && (event.company_report !== null && !event.company_report?.locked)) {
      this.lockReportFromEntity(event.id, event.company_report_id);
      return;
    }
    this.patchToNextState(event.id, patchString);
  }
  getCurrentStateLabel(entity) {
    if (entity.data_entry_status === 'Not Started') {
      return 'Start';
    }
    if (entity.data_entry_status === 'Data Entry') {
      return 'Done';
    }
    if (entity.data_entry_status === 'Verify') {
      return 'Lock';
    }
  }
  getNextStateLabel(dataEntryStatus){
    if (dataEntryStatus === 'Not Started') {
      return 'Data Entry';
    }
    if (dataEntryStatus === 'Data Entry') {
      return 'Verify';
    }
    if (dataEntryStatus === 'Verify') {
      return 'Completed';
    }
  }
  patchToNextState(id: number, patchString : string) {
    this.reportService.patchUpcomingReportEventStatus(id, {data_entry_status: patchString}).subscribe((response) => {
      this.loading = false;
      this.toaster.success("Updated status successfully!")
      this.refresh();
    })
  }
  patchClaim(evt, reportEventEntity: any, isClaim: boolean) {
    evt.stopPropagation();
    evt.preventDefault();
    this.reportService.patchClaimStatus(reportEventEntity.id, isClaim).subscribe((response) => {
      this.loading = false;
      this.toaster.success("Updated claim status successfully!")
      this.refresh();
    }, (error) => {
      if(error?.data?.status === 'FAILED'){
        this.toaster.error(error?.data?.message, "Could not update claim");
      }
    })
  }
  private lockReportFromEntity(eventId, companyReportId: number) {
    this.loading = true;
    this.reportService.setReportLock({locked: true} , companyReportId).subscribe((response) => {
      this.patchToNextState(eventId, "Completed");
    })
  }
  toggleEditMode(entity: any) {
    if(!this.editActionsEnabled(entity)){
      entity.edit_mode = false;
      return;
    }
    if(entity.hasOwnProperty('edit_mode')) {
      entity.edit_mode = !entity.edit_mode;
    }
  }
  check_data_available(entity: any) {
    if(entity) {
      return entity.report_date &&
        entity.report_type &&
        entity.accounting_standard &&
        entity.period_start_date &&
        entity.period_end_date &&
        entity.report_date_exactness &&
        entity.fiscal_offset
    }
    return false;
  }
  patchToDataEntryStatus(event, entity: any) {
    event.stopPropagation();
    event.preventDefault();
    this.patchToNextState(entity.id, "Data Entry");
  }

  deleteEntity(event, entity: any) {
    event.preventDefault();
    event.stopPropagation();
    const modalRef = this.modalService.open(EntityConfirmModalComponent,
      {
        size: 'md'
      });
    const data = {
      title: 'Lock',
      message: 'Are you sure you want to delete this report event?',
      alert: false
    }
    modalRef.componentInstance.modalData = data;
    modalRef.result.then((result) => {
      this.reportService.deleteReportEvent(entity.id).subscribe((response) => {
        this.toaster.success("Deleted successfully!");
        this.refresh();
      });
    });
  }
  formatMarketCap(marketCap: number | null): string {
    if (marketCap === null) {
      return 'N/A';
    }
    const milj = marketCap / 1_000_000;

    if (milj < 1) {
      return `<1`;
    }

    const formattedMarketCap = milj.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return `${formattedMarketCap}`;
  }
  editActionsEnabled(entity){
    if(entity?.claimed_by === null){
      return true;
    }
    return (entity?.claimed_by === this.currentUserId) || this.isSuperUser;
  }

  getColumnHeaderValue(field: any) {
    if(field && field.key === 'marketcap') {
      return field.label + ' (€)';
    } else if (field) {
      return field.label
    } else {
      return '';
    }
  }

  getClaimedByUser(claimed_by: any) {
    if(Object.values(this.users).length) {
      const userContext = Object.values(this.users).find((user) => user.id === claimed_by);
      if(userContext) {
        let fullName = userContext.first_name + ' ' + userContext.last_name;
        return fullName;
      }
      return '';
    }
    return '';
  }

  toggleComment(event, entity: any): void {
    event.preventDefault();
    event.stopPropagation();
    entity.showFullComment = !entity.showFullComment;
  }
}
