<div class="card mb-2">
    <div class="card-header cursor-pointer" (click)="toggleType()">
        <div class="d-flex justify-content-start">
            <span>Missing calendar events</span>
            <div class="card-header-toggle ml-auto">
                <con-icon-by-name iconName="angle-right" [mutations]="getMutations()"></con-icon-by-name>
            </div>
        </div> 
        
    </div>
    <ng-container *ngIf="show() || loading">
        <div class="card-body">
            <con-loader *ngIf="loading"></con-loader>
            <div *ngIf="!loading && error">
                <p class="lead">
                    Oops, something went wrong!
                </p>
            </div>
            <div *ngIf="!loading && missingTypes && !missingTypes.length">
                <span class="lead">
                    All missing events for this {{ownerType.toLowerCase()}} are created successfully !
                </span>
            </div>
            <div class="card" *ngFor="let type of missingTypes">
                <div class="card-header" (click)="toggleType(type)">
                    <div class="d-flex justify-content-start align-items-center">
                        <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                        {{ type.name }}
                        <div class="card-header-toggle-individual ml-auto">
                            <div class="chip chip-link" *ngIf="type.missing_event">
                                <i class="fa fa-pencil fa-fw"></i>
                                 Edit
                             </div>
                             <con-icon-by-name iconName="angle-right" [mutations]="getMutations(type)"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="showEvent(type)">
                    <con-custom-entity-form-content *ngIf="!type.missing_event"
                        [showEditButton]="false"
                        entityName="CalendarEvent"
                        (showComments)="setCommentFlag($event, type)"
                        [fixedValues]="{ calendar_event_type_id: type.id, owner_id: company.id, owner_type: ownerType, revised_at: now }"
                        [hasFixedCalendarValues]="true"
                        [addCommentSection]="true"
                        [preFillValues]="{ from_date: tomorrow }"
                        [initialSaveEnabled]="false"
                        [setEntityFormContent]="false"
                        [handleFullDayEvent]="true"
                        [enableRecurringEvent]="true"
                        [relationValues]="{ calendarEventType: type, owner: company }"
                        (afterSave)="eventCreated($event)"></con-custom-entity-form-content>
                    <con-custom-entity-form-content *ngIf="type.missing_event"
                        showEditButton=true
                        [entity]="type.missing_event"
                        entityName="CalendarEvent"
                        (showComments)="setCommentFlag($event, type)"
                        (afterSave)="eventCreated($event)"
                        [hasFixedCalendarValues]="true"
                        (afterDirectProfileChange)="direktProfileChanged($event, type.missing_event.id)"
                        [setEntityFormContent]="false"
                        (afterDelete)="afterEventDeleted(type)"
                        [handleFullDayEvent]="true"
                        [enableRecurringEvent]="true"
                        [fixedValues]="{ calendar_event_type_id: type.id, owner_id: company.id, owner_type: ownerType, revised_at: now }">
                    </con-custom-entity-form-content>
                        <div  *ngIf="type.missing_event" class="mt-2">
                            <ng-container *ngIf="type?.missing_event?.isLoadingComments">
                                <con-loader></con-loader>
                            </ng-container>
                            <ng-container *ngIf="!type?.missing_event?.isLoadingComments">
                                <div class="alert alert-info clearfix" *ngFor="let comment of type.missing_event.comments">
                                    <div class="float-left text-left" *ngIf="!isEditingComment(comment)">
                                        {{ comment.comment }} ({{ comment.language.iso639_2 }})
                                    </div>
                                    <con-entity-form-content
                                        *ngIf="isEditingComment(comment, type.missing_event)"
                                        entityName="CalendarEventComment"
                                        [entity]="comment"
                                        [fixedValues]="{ calendar_event_id: type.missing_event.id, language_id: comment.language_id }"
                                        (afterSave)="commentUpdated($event, type.missing_event)"
                                        (afterDelete)="commentDeleted($event, type.missing_event)"
                                        toastMessage="Updated successfully!"
                                        [setEntityFormContent]="false"
                                    ></con-entity-form-content>
                                    <div class="float-right">
                                        <con-icon-by-name iconName="pencil" (click)="toggleEditOfComment(comment, type.missing_event)"></con-icon-by-name>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="border p-3" *ngIf="type.missing_event.id">
                                <con-entity-form-content
                                    entityName="CalendarEventComment"
                                    [fixedValues]="{ calendar_event_id: type.missing_event.id }"
                                    [emptyAfterSave]="true"
                                    [entity]="{ language: commentLangDefault, language_id: commentLangDefault.id}"
                                    [preFillValues]=" { language_id: commentLangDefault.id}"
                                    (afterSave)="commentAdded($event, type.missing_event, true)"
                                    [direktProfileLanguage]="{ eventId: type.missing_event.id, language: newEventDirectProfileLangs }"
                                    [setEntityFormContent]="false"
                                    toastMessage="Added successfully!"
                                >
                                </con-entity-form-content>
                            </div>
                        </div>
                    <p class="lead" *ngIf="!profile.can(profile.getPermissionName('CalendarEvent', 'store'))">
                        You are not allowed to create new company events!
                    </p>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="row">
    <div class="col-12">
        <div class="card mb-2">
            <div class="card-header d-flex justify-content-start" (click)="company.expanded = !company.expanded">
                <div class="float-left text-left"> New Event</div>
                <div class="card-header-toggle ml-auto">
                    <con-icon-by-name iconName="angle-right" [mutations]="getMutations(null,true)"></con-icon-by-name>
                </div>
            </div>
            <div class="card-body" *ngIf="showEvent()">
                <con-custom-entity-form-content 
                    [ownerType]="ownerType"    
                    [showEditButton]="false"
                    entityName="CalendarEvent"
                    [fixedValues]="{ direkt_id: null, revised_at: now, owner_id: company.id, owner_type: ownerType }"
                    [emptyAfterSave]="true"
                    [hasFixedCalendarValues]="true"
                    [preFillValues]="{ from_date: tomorrow }"
                    (afterSave)="newEventChanged()"
                    [addCommentSection]="true"
                    [initialSaveEnabled]="false"
                    [setEntityFormContent]="false"
                    [handleFullDayEvent]="true"
                    toastMessage="Added successfully!"
                    [enableRecurringEvent]="true"
                    [relationValues]="{ owner: company }">
                </con-custom-entity-form-content>
            </div>
        </div>
    </div>
</div>
