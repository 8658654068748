import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CompanyReportsSettings } from 'app/settings.class';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import * as moment from 'moment';
import { MLSendStatus } from 'app/company-reports/models/MLSendStatus';
import {EntitiesService} from '../../../entities/services/entities.service';

@Component({
  selector: 'con-report-shareholder',
  templateUrl: './shareholder.component.html'
})
export class ShareholderComponent implements OnInit, OnDestroy {

  @Input() entity: any;
  public loading = true;
  public routeSubscription: Subscription;
  public companyReport: any;
  public id: number;
  public noDataMessage = 'No shareholders to display';
  public MLSendStatus = MLSendStatus;
  reloadPageSubscription: Subscription;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public entitiesService: EntitiesService,
    public companyService: CompanyReportsService) { }

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe(
      (params: Params) => {
        this.id = params.id;
      });
      this.companyService.selectedReport.subscribe(entity => {
        this.companyReport.data.forEach((shareholder) => {
          shareholder.locked = entity.locked || this.entitiesService.entityLockedByMl(entity);
          shareholder.hide  = true;
        });
      });
    this.loadPage(1);
    this.reloadPageSubscription = this.companyService.reloadPage.subscribe((data) => {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
      });
    });
  }
  loadPage(pageNumber: number) {
      this.loading = true;
      this.http.get(CompanyReportsSettings.BASE_URL + '/' + this.id + '/shareholders', {
                    params: {
                        page: String(pageNumber)
                    }
                })
      .subscribe(companyReport => {
        this.companyReport = companyReport;
        if (this.companyReport.data.length) {
          this.companyReport.data.forEach(share => {
            share.hide = true;
            share.formatted = false;
            if (share.source_type === 'CompanyReport' && share.source) {
              share.source.company = {
                name: this.entity.company.name + ' - ' + moment(share.source.report_date).format('yyyy-MM-DD')
              };
            }
            if(share.prefill_owners && share.prefill_owners.length>0 && ( !share.owners || share.owners.length==0 )) {
              share.owners=share.prefill_owners;
              // If shareholder owners field is empty, then assign prefill_owners object to owners object
            }
          });
          this.formatOwnerValues();
        }
        this.loading = false;
      },
        err => {
          if (err.status === 404) {
            this.router.navigate(['shareholders', 'error'], { skipLocationChange: true });
          }
          this.loading = false;
        });
}
  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.reloadPageSubscription.unsubscribe();
  }
  formatOwnerValues() {
    if (this.companyReport.data
        && this.companyReport.data.length) {
            this.companyReport.data.forEach(share => {
                if(!share.formatted) {
                  share.owners.forEach(owner => {
                      owner.capital = (owner.capital !== null && owner.capital !== '') ? (owner.capital * 100).toFixed(2) : null;
                      owner.votes = (owner.votes !== null && owner.votes !== '') ? (owner.votes * 100).toFixed(2) : null;
                  });
                }
                share.formatted = true;
            });
        }
  }
  public toggleType(event?) {
    event.hide = !event.hide;
  }
  getMutations(event) {
    return (!event.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
  }
  shareholderCreated(shareholder) {
    shareholder.owners = shareholder.prefill_owners;
    shareholder.source.company = {
      name: this.entity.company.name + ' - ' + moment(shareholder.source.report_date).format('yyyy-MM-DD')
    };
    this.companyReport.data.unshift(shareholder);
    this.companyReport.data.forEach(share => {
      share.hide = true;
      share.formatted = true;
    });
    this.companyReport.data[0].hide = false;
    this.companyReport.data[0].formatted = false;
    this.formatOwnerValues()
  }
  shareholderUpdated(shareholder, index) {
    shareholder.source.company = {
      name: this.entity.company.name + ' - ' + moment(shareholder.source.report_date).format('yyyy-MM-DD')
    };
    this.companyReport.data[index] = shareholder;
    this.formatOwnerValues();
  }
  ownerUpdated(owner: any, index: number) {
    this.companyReport.data[index].owners = owner;
  }
  getDisplayTitle() {
    let displayName = this.entity && this.entity.company ? this.entity.company.name : '';
    if (this.entity) {
      displayName +=
        ' / ' +
        this.entity.report_type +
        ', ' +
        moment(this.entity.report_date).format('yyyy-MM-DD');
    }
    return displayName;
  }
  get settingLock () {
    return this.companyService.locking;
  }
}
