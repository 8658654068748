import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiSettings, CompanyReportsSettings } from '../../../settings.class';
import * as moment from 'moment';
import { ProfileService } from '../../../auth/services/profile.service';
import { EntitiesService } from '../../../entities/services/entities.service';
import { CompanyReportsService } from '../../services/company-reports.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'con-mismatch-reporting-standard',
  templateUrl: './mismatch-reporting-standard.component.html',
  styleUrls: ['./mismatch-reporting-standard.component.scss']
})
export class MismatchReportingStandardComponent implements OnInit {
  public entity: any = {};
  companyItemsLoading = false;
  public resetKey = 'ISNew';
  public companyTierId: number = CompanyReportsSettings.TIER_ID;
  showAddEvent = false;
  expandAddForm = true;
  companyId: number;
  runUpdate: Subject<boolean> = new Subject<boolean>();

  public mismatchLoading: boolean;
  private page$ = new Subject<number>();
  public currentPage = 1;
  mismatchData: any = {};
  errorMessage = null;
  reportingStandard: any = {};

  constructor(private route: ActivatedRoute,
              private router: Router,
              private http: HttpClient,
              public profile: ProfileService,
              public entitiesService: EntitiesService,
              public companyService: CompanyReportsService) {
    this.route.paramMap.subscribe( paramMap => {
      this.companyId = Number(paramMap.get('id'));
      this.entitiesService.getEntityById('Company', this.companyId).subscribe((company: any) => {
        this.entity = company;
      });
      this.companyService.getCompanyReportingStandard(this.companyId).subscribe((res: any) => {
        this.reportingStandard = res.data;
      });

    })
  }

  ngOnInit(): void {
    this.getMismatchData();
  }
  getMismatchData() {
    this.errorMessage = null;
    this.mismatchLoading = true;
    this.companyService.getCompanyReportMismatch(this.companyId, 1).subscribe((res: any) => {
      this.mismatchData = res;
      this.mismatchLoading = false;
    }, (error) => {
      console.log(error);
      if(error.type === "VALUE_ERROR" && error.status === 400 && error.hasOwnProperty('data')) {
        this.errorMessage = error.data.message;
      }
      this.mismatchLoading = false;
    })
  }

  reportingStandardCreated($event: any) {
    this.entitiesService.resetDayInput.next(this.resetKey);
    this.runUpdate.next(true);
    this.getMismatchData();
  }
  navigateToCompany(company: any) {
    this.router.navigate(['company_reports','reporting-standard', 'company', company.id]);
  }

  toggleType() {
    this.expandAddForm = ! this.expandAddForm;
  }

  removeAddEvent() {
    this.showAddEvent = false;
  }

  getMutations() {
    const mutation = !this.expandAddForm ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
    return mutation;
  }

  addNewEvent() {
    this.showAddEvent = true;
  }
  changePage(page: number) {
    this.page$.next(page);
  }

  formatDateTime(date: string) {
    if (date === null) {
      date = 'Unspecified';
    } else {
      date = moment(date).format('YYYY-MM-DD');
    }
    return date;
  }
}
