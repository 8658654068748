<div *ngIf="company">
    <div class="d-flex justify-content-start sticky-header py-2">
        <h3 class="mb-0"><span class="badge badge-dark">{{ company.name }}</span></h3>
        <div class="chip chip-inverse ml-auto">
            <con-avatar *ngIf="company.manager?.id" [userId]="company.manager?.id" [size]="32"></con-avatar>
            {{managerName}}
        </div>
    </div>

    <div class="card mb-2" *ngIf="showEditor">
        <div class="card-header">
            <con-icon-by-name iconName="hashtag" [mutations]="['fw']"></con-icon-by-name> Duplicate primary instrument
        </div>
        <div class="card-body">
            <strong *ngIf="hasNoPrimaries">Company has two active primary instruments</strong>
            <div class="form-group">
                <label>
                    <small>{{ hasNoPrimaries ? "Duplicate instrument 1": "Current primary instrument"}}</small>
                </label>
                <div class="alert alert-info alert-dismissible mb-0">
                    <a class="alert-link-icon mr-1" href="entity/instrument/{{currentInstrument?.id}}">
                        <con-icon-by-name iconName="line-chart" [mutations]="['fw']"></con-icon-by-name>
                    </a>
                    <strong>{{currentInstrument?.ticker}}, {{currentInstrument?.name}},
                        {{currentInstrument?.isin}}</strong>
                </div>
            </div>
            <div class="form-group">
                <label>
                    <small>{{ hasNoPrimaries ? "Duplicate instrument 2": "New primary instrument"}}</small>
                </label>
                <div class="alert alert-info alert-dismissible mb-0">
                    <a class="alert-link-icon mr-1" href="entity/instrument/{{newInstrument?.id}}">
                        <con-icon-by-name iconName="line-chart" [mutations]="['fw']"></con-icon-by-name>
                    </a>
                    <strong>{{newInstrument?.ticker}}, {{newInstrument?.name}}, {{newInstrument?.isin}}</strong>
                </div>
            </div>
            <con-loader *ngIf="is_processing"></con-loader>
            <button class="btn btn-success" *ngIf="!hasNoPrimaries" [disabled]="is_processing"
                (click)="switchPrimary()">Switch Primary</button>
            <button *ngIf="company.tasks" [disabled]="is_processing" class="btn btn-warning"
                (click)="markAsDone()">{{ hasNoPrimaries ? "Mark as done":"Keep Current"}}</button>
        </div>
    </div>
    <div class="row" *ngIf="!showEditor">
        <div class="col-12">
            <div class="alert no-data">Nothing to display!</div>
        </div>

    </div>
</div>
