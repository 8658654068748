import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import * as moment from 'moment';
import { EntitiesService } from '../../../entities/services/entities.service';
import { CompanyReportsService } from '../../services/company-reports.service';
@Component({
  selector: 'con-report-event',
  templateUrl: './report-event.component.html',
  styleUrls: ['./report-event.component.scss']
})
export class ReportEventComponent implements OnInit{
  public currentView: string = '';
  public companyId = null;
  public company = null;
  entityName = 'CompanyReportEvent';
  newReportExpanded = true;
  isUpcoming = false;
  reloadListing = false;
  loading = true;
  showAddReportEvent = false;
  constructor(private route: ActivatedRoute, public reportService: CompanyReportsService, private router: Router) {
  }
  ngOnInit() {
    this.loading = true;
    combineLatest([this.route.params, this.route.queryParams])
      .subscribe(([params, queryParams]) => {
        this.companyId = params.id;
        this.currentView = queryParams.view;
        this.reportService.getCompanyData(this.companyId).subscribe((companyDetails) => {
          this.company = companyDetails;
          this.isUpcoming = this.currentView !== 'missing';
          this.loading = false;
        }, (error) => {console.log(error); this.loading = false;})
      }, (error) => {console.log(error); this.loading = false;})
  }
  getCurrentDate(): string {
    return moment().format('YYYY-MM-DD').toString();
  }
  toggleType() {
    this.newReportExpanded = !this.newReportExpanded;
  }
  getMutations() {
    const mutation =  this.newReportExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
    return mutation;
  }
  refreshEventTable() {
    this.reloadListing = true;
    // reset reload onchanges detection
    setTimeout(()=> {
      this.reloadListing = false;
    }, 100)
  }
  toggleAddReportEvent() {
    this.showAddReportEvent = !this.showAddReportEvent;
  }
  closeAddReportEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.showAddReportEvent = false;
  }
}
