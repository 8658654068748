<div class="table-responsive">
  <table class="table table-hover">
    <thead class="thead-light">
    <tr>
      <th *ngIf="isSelectable"></th>
      <th>
        Id
        <!-- below commented code of sort reference if is needed -->
        <!--        <i class="fa fa-sort-asc fa-lg" [ngClass]="{'active': orderField === 'id' && orderAsc }" (click)="orderBy('id', true)"></i><i class="fa fa-sort-desc fa-lg" [ngClass]="{'active': orderField === 'id' && !orderAsc }" (click)="orderBy('id', false)"></i>-->
      </th>
      <th *ngFor="let field of entityDescription?.getFieldsForTable(timestamps)" [ngClass]="field.key === 'claimed_by'? 'min-w-150':''">
        <ng-container>
          {{getColumnHeaderValue(field)}}
        </ng-container>
        <!-- below commented code of sort reference if is needed -->
        <!--        <span *ngIf="fieldCanBeOrdered(field)"> <i class="fa fa-sort-asc fa-lg" [ngClass]="{'active': orderField === field.key && orderAsc }" (click)="orderBy(field.key, true)"></i><i class="fa fa-sort-desc fa-lg" [ngClass]="{'active': orderField === field.key && !orderAsc }" (click)="orderBy(field.key, false)"></i></span>-->
      </th>
      <th *ngIf="actions.length || customAction">
        Action
      </th>
    </tr>
    <tr>
      <td colspan='100%'>
        <div class="btn-group btn-group-md" role="group">
          <button class="btn btn-dark" ngbTooltip="Refresh Table" container="body" (click)="refresh()">
            <con-icon-by-name iconName="refresh" [mutations]="['fw']">
            </con-icon-by-name>
          </button>
          <button class="btn btn-info" [ngbTooltip]="'Showing ' + response?.data.length + ' of ' + response?.total" container="body">
            <con-icon-by-name iconName="info" [mutations]="['fw']">
            </con-icon-by-name>
          </button>
        </div>
      </td>
    </tr>
    <!-- below commented code needed for future column based selection -->
    <!--    <tr>-->
    <!--      <td *ngIf="isSelectable" class="text-center select-all">-->
    <!--        <input type="checkbox" [(ngModel)]="allSelected" (change)="selectAll()">-->
    <!--      </td>-->
    <!--      <td class="align-middle">-->
    <!--        <div class="btn-group btn-group-md" role="group">-->
    <!--          <button class="btn btn-dark" ngbTooltip="Refresh Table" container="body" (click)="refresh()">-->
    <!--            <con-icon-by-name iconName="refresh" [mutations]="['fw']">-->
    <!--            </con-icon-by-name>-->
    <!--          </button>-->
    <!--          <button class="btn btn-info" [ngbTooltip]="'Showing ' + response?.data.length + ' of ' + response?.total" container="body">-->
    <!--            <con-icon-by-name iconName="info" [mutations]="['fw']">-->
    <!--            </con-icon-by-name>-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </td>-->
    <!--      <td *ngFor="let field of entityDescription?.getFieldsForTable(timestamps)">-->
    <!--        <con-entity-search-by-field-->
    <!--          [field]="field"-->
    <!--          [control]="searchForm.controls[field.key]">-->
    <!--        </con-entity-search-by-field>-->
    <!--      </td>-->
    <!--      <td *ngIf="actions.length || customAction">-->
    <!--      </td>-->
    <!--    </tr>-->
    </thead>
    <tbody *ngIf="!loading">
    <ng-container *ngFor="let entity of response?.data">
      <tr (click)='toggleEditMode(entity)'>
        <td *ngIf="isSelectable" class="text-center">
          <input type="checkbox" [(ngModel)]="entity.selected" (change)="selectRow(entity); selected(entity)">
        </td>
        <td *ngIf="!enableHyperLink">{{ entity.id }}</td>
        <td *ngIf="enableHyperLink">
          <a [routerLink]="['', 'entity', 'company_report_event', entity.id]">
            {{ entity.id }}
          </a>
        </td>
        <td *ngFor="let field of entityDescription.getFieldsForTable(timestamps)">
          <ng-container *ngIf="field.key === 'report_date' && entity?.company_report_id !== null">
            <a [routerLink]="['', 'company_reports',entity?.company_id, 'report', entity?.company_report_id,'report-overview']">
              <con-entity-content-by-field
                [field]="field"
                [entityDescription]="entityDescription"
                [entity]="entity">
              </con-entity-content-by-field>
            </a>
          </ng-container>
          <ng-container *ngIf="field.key === 'data_entry_status'">
            <ng-container *ngIf="entity?.data_entry_status === 'Not Started'">
              <span class="badge badge-danger badge-custom-width" ngbTooltip='Not Started'>
                    <i class="fa fa-exclamation-circle fa-2x"></i>
              </span>
            </ng-container>
            <ng-container *ngIf="entity?.data_entry_status === 'Data Entry'">
              <span class="badge badge-primary" ngbTooltip='Data Entry'>
                    <i class="fa fa-keyboard-o fa-2x"></i>
              </span>
            </ng-container>
            <ng-container *ngIf="entity?.data_entry_status === 'Verify'">
              <span class="badge badge-success" ngbTooltip='Verify'>
                    <i class="fa fa-check fa-2x"></i>
              </span>
            </ng-container>
            <ng-container *ngIf="entity?.company_report?.is_fully_annotated !== null && !entity?.company_report?.is_fully_annotated && entity?.company_report?.is_fully_annotated !== undefined">
              <span class="badge badge-danger ml-15" ngbTooltip='Not Fully Annotated'>
                    <i class="fa fa-tags fa-2x"></i>
              </span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="field.key === 'marketcap'">
            <div  [ngbTooltip]="entity?.currency?.name">{{formatMarketCap(entity?.marketcap)}}</div>
          </ng-container>
          <ng-container *ngIf="field.key === 'fiscal_year'">
            {{entity?.fiscal_period}} {{entity?.fiscal_year}}
          </ng-container>
          <ng-container *ngIf="field.key === 'comment'">
            <ng-container *ngIf="!entity?.showFullComment">
              <span>{{ entity?.comment | excerpt:30 }}</span> <br/>
              <a class="btn-link cursor-pointer" (click)="toggleComment($event, entity)" *ngIf='entity?.comment && (entity?.comment?.length > 30)'>Show More</a>
            </ng-container>
            <ng-container *ngIf="entity?.showFullComment">
              <span>{{ entity?.comment }}</span><br/>
              <a class="btn-link cursor-pointer" (click)="toggleComment($event, entity)">Show Less</a>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="field.key === 'company.name'">
            <a [routerLink]="['', 'company_reports','company',entity?.company_id]">
              <con-entity-content-by-field
                [field]="field"
                [entityDescription]="entityDescription"
                [entity]="entity">
              </con-entity-content-by-field>
            </a>
          </ng-container>
          <ng-container *ngIf="field.key === 'claimed_by'">
            <div class="chip chip-inverse wrap" *ngIf='entity?.claimed_by'>
              <con-avatar [userId]="entity?.claimed_by" [size]="32"></con-avatar>
              {{getClaimedByUser(entity?.claimed_by)}}
            </div>
          </ng-container>
          <ng-container *ngIf="(field.key !== 'data_entry_status' && field.key !== 'claimed_by' && field.key !== 'report_date'  && field.key !== 'comment'  && field.key !== 'company.name' && field.key !== 'marketcap' && field.key !== 'fiscal_year') ||  (field.key === 'report_date' && entity?.company_report_id === null)">
            <con-entity-content-by-field
              [field]="field"
              [entityDescription]="entityDescription"
              [entity]="entity">
            </con-entity-content-by-field>
          </ng-container>
        </td>
        <td *ngIf="loaders[entity.id]">
          <con-loader></con-loader>
        </td>
        <td *ngIf="actions.length && !loaders[entity.id] && !customAction" class="row-action">
          <ng-container *ngFor="let action of actions">
            <span *ngIf="!entity.locked"><button class="btn btn-sm action-btn" [ngClass]="'btn-' + action.buttonClass" (click)="doAction($event, action.name, entity)"><con-icon-by-name [iconName]="action.icon"></con-icon-by-name> {{ action.label }}</button></span>
          </ng-container>
          <ng-container *ngIf="canTranslate(entity)">
                            <span>
                                <button class="btn btn-sm btn-primary translate" (click)="openTranslation(entity)"><con-icon-by-name iconName="globe"></con-icon-by-name>Translate</button>
                            </span>
          </ng-container>
        </td>
        <td *ngIf='customAction' class="min-w-300">

          <button class='btn btn-primary' *ngIf="entity?.claimed_by === null" (click)='patchClaim($event, entity, true)'>Claim</button>
          <button class='btn btn-primary' *ngIf="entity?.claimed_by && editActionsEnabled(entity)" (click)='patchClaim($event, entity, false)'>Unclaim</button>
          <button class='btn btn-primary' (click)="doAction($event, 'edit', entity)" *ngIf="getCurrentStateLabel(entity) === 'Start'  && editActionsEnabled(entity)">
            <con-icon-by-name iconName="plus-circle"></con-icon-by-name> Edit
          </button>
          <button class='btn btn-primary' [ngClass]="getCurrentStateLabel(entity) === 'Lock'? 'btn-success': ''" (click)="executeNextState($event, entity)" *ngIf="check_data_available(entity)  && editActionsEnabled(entity)">
              {{getCurrentStateLabel(entity)}}
          </button>
          <button class='btn btn-primary' *ngIf="entity.data_entry_status === 'Verify'  && editActionsEnabled(entity)" (click)='patchToDataEntryStatus($event, entity)'>
            <con-icon-by-name iconName="undo" ngbTooltip="Revert to data entry status"></con-icon-by-name>
          </button>
          <button class='btn btn-danger' (click)="deleteEntity($event, entity)">
            <con-icon-by-name iconName="minus-circle" ngbTooltip="Delete report event"></con-icon-by-name>
          </button>
        </td>
      </tr>
      <tr *ngIf="entity.edit_mode">
        <td colspan='100%' class="edit-mode-bg">
          <div class="card-body">
            <div class="col-3">
              <h6>Edit Report Event:</h6>
              <con-entity-form-content [entity]="entity" entityName="CompanyReportEvent"
                                       [fromMissingEvents] = "true"
                                       [showWhenChanged]="false"
                                       [fixedValues]='{data_entry_status: {}, report_date_exactness: {}}'
                                       (afterSave)="refresh()"
                                       [canDelete]="false" toastMessage="Missing event updated successfully!"
              >
              </con-entity-form-content>
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
<con-loader *ngIf="loading"></con-loader>
<con-no-data *ngIf="!loading" [count]="response?.total" [message]="noDataMessage"></con-no-data>
<div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!response?.total}">
  <ngb-pagination [collectionSize]="response?.total"
                  [(page)]="currentPage"
                  (pageChange)="changePage($event)"
                  [pageSize]="response?.per_page"
                  maxSize="10"
                  [rotate]="true"></ngb-pagination>
</div>
