import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntitiesService, EntityDescription } from '../../../../entities/services/entities.service';
import { SelectedInstancesService } from '../../../../doc-process/sub-modules/kpi-and-kiid/services/selected-instances.service';
import { TranslationService } from '../../../../utility/services/translation.service';
import { FileTokenService } from '../../../../auth/services/file-token.service';
import { combineLatest, debounceTime, startWith, switchMap, take, tap } from 'rxjs/operators';
import { ApiSettings } from '../../../../settings.class';
import { snakeCase } from 'change-case';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'con-exchange-rate-lisitng',
  templateUrl: './exchange-rate-lisitng.component.html',
  styleUrls: ['./exchange-rate-lisitng.component.scss']
})
export class ExchangeRateLisitngComponent implements OnChanges, OnInit {
  @Input() entityName: string;
  @Input() originEntityName: string = null;
  @Input() relation: string = null;
  @Input() entityId: number = null;
  @Input() actions: Array<any> = [];
  @Input() loaders: any = {};
  @Input() timestamps = true;
  @Input() runUpdate: Subject<boolean> = new Subject<boolean>();
  @Input() externalData: Subject<boolean> = new Subject<boolean>();
  @Input() fixedSearchParams: any = {};
  @Input() isSelectable = false;
  @Input() external = false;
  @Input() externalMetaData: any;
  @Input() preserveSelection: any;
  @Input() enableHyperLink = true;
  @Input() smallCells = false;
  @Input() refreshExternalData: Subject<any> = new Subject<any>();

  @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadExternalData: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChecked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCheckedAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() onResponse: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedUrl = new EventEmitter<any>();
  private entityName$ = new Subject<string>();
  public searchForm = new FormGroup({});
  private page$ = new Subject<number>();
  public response: any;
  public entityDescription: EntityDescription;
  public currentPage  = 1;
  public loading: boolean;
  public noDataMessage = 'No data to display.';
  public orderField: string;
  public orderAsc: boolean;
  public allSelected: boolean;
  private snakeCase = snakeCase;
  private externalTerms: any;
  private refreshExternalDataSubscription: Subscription;
  private translationSub: Subscription

  constructor(
    private formBuilder: FormBuilder,
    private service: EntitiesService,
    private selectedInstancesService: SelectedInstancesService,
    private toaster: ToastrService,
    private transService : TranslationService,
    private tokenService: FileTokenService
  ) {}
  private resetOrdering() {
    if (!this.external) {
      this.orderField = 'updated_at';
      this.orderAsc = false;
    } else {
      this.orderField = 'id'
      this.orderAsc = true;
    }
  }
  public ngOnInit() {
    this.loadData();
    this.translationSub= this.transService.onTranslationSaved.subscribe((companyId)=> {
      if(this.entityId == companyId) {
        this.loadData();
      }
    })
  }
  public loadData() {
    if (this.refreshExternalData)  {
      this.refreshExternalDataSubscription = this.refreshExternalData.subscribe(() => this.refresh())
    }

    this.allSelected = false
    const entityNameObservable = this.entityName$.pipe(
      startWith(this.entityName),
      switchMap(entityName => {
        if (this.externalMetaData) {
          this.service.externalEntityDescriptionsSubject.next(this.externalMetaData)
          return this.service.getExternalEntityDescriptionByEntityName(entityName);
        }
        return this.service.getEntityDescriptionByEntityName(entityName);
      }),
      tap(entityDescription => {
        const form = {};
        entityDescription.getFilterableFields().forEach(field => {
          const fixedSearchObject = this.fixedSearchParams[field.key]
          if (fixedSearchObject) { form[field.key] = this.fixedSearchParams[field.key] } else { form[field.key] = ''; }
        });
        this.searchForm = this.formBuilder.group(form);
        this.page$ = new Subject<number>();
        this.resetOrdering();
        this.entityDescription = entityDescription;
      }),
      switchMap(entityDescription => {
        return this.searchForm.valueChanges.pipe(debounceTime(400),
          startWith({}),
          combineLatest(this.page$.pipe(startWith(1)),
            (terms, page) => {
              return {terms, page};
            }), );
      }),
      tap(params => this.currentPage = params.page),
      tap(params => this.loading = true),
      switchMap(res => {
        const terms = { ...this.fixedSearchParams, ...res.terms };
        if (this.external) {
          this.externalTerms = terms;
          const params =  this.service.createExternalEntities(this.entityDescription.name,
            this.externalTerms,
            res.page,
            this.orderField,
            this.orderAsc);
          this.loadExternalData.emit(params);
          return this.externalData;
        }
        if (this.relation) {;
          return this.service.searchRelation(this.originEntityName,
            this.entityId,
            this.relation,
            terms,
            res.page,
            this.orderField,
            this.orderAsc);
        }
        return this.service.searchEntities(this.entityDescription.name,
          terms,
          {
            page: res.page,
            order_by: this.orderField,
            order_asc: this.orderAsc
          });
      }),
      switchMap(instance => {

        if (this.selectedInstancesService.selectedInstanceIds && instance.data) {
          this.handleSelectedInstances(instance, this.selectedInstancesService.selectedInstanceIds.projectInstanceTable)
        }
        if (this.selectedInstancesService.instancesMetadata && instance.data) {
          this.handleSelectedInstances(instance, this.selectedInstancesService.instancesMetadata.projectInstanceTable)
        }
        return of(instance)
      }))
      .subscribe(res => {
        if (this.entityName === 'Owner') {
          this.formatOwnerData(res);
        } else {
          res.data.forEach((ex) => {
            ex.edit_mode = false;
            ex.updatedValue = ex.amount;
            ex.errorMsg = "";
          });
          this.response = res;
          this.onResponse.emit(res);
        }
        this.loading = false;
      }, err => {
        console.log(err);
        this.loading = false;
      });
  }

  enableEdit(data: any) {
    data.edit_mode = !data.edit_mode
  }
  saveExchangeRate(ex: any) {
    if(ex.updatedValue == ex.amount) {
      ex.edit_mode = false;
      return;
    }
    const parseValue = parseFloat(ex.updatedValue)
    if (!isNaN(parseValue) && parseValue > 0)
    {
      const dataToPatch = {
        id: ex.id,
        amount: ex.updatedValue
      }
      this.service.saveEntity("ExchangeRate", dataToPatch).subscribe((response) => {
        ex.amount = response.amount;
        ex.updatedValue = response.amount;
        ex.edit_mode = false;
        ex.errorMsg = "";
        this.toaster.success("Exchange Rate updated successfully", "Exchange Rate")
      })
    } else {
      ex.errorMsg = "Valid number is required"
    }
  }
  setInput(ex: any, value: any) {
    ex.updatedValue = value;
  }
  revertChange(ex: any) {
    ex.edit_mode = false;
    ex.updatedValue = ex.amount;
    ex.errorMsg = "";
  }
  ngOnDestroy(): void {
    this.refreshExternalDataSubscription.unsubscribe()
    this.translationSub.unsubscribe()
  }

  handleSelectedInstances(instance, selectedInstances) {
    let selectedCounter = 0
    instance.data.forEach(item => {
      if (selectedInstances.indexOf(item.id) > -1) {
        item.selected = true
        selectedCounter++
      }
    });
    if (selectedCounter === instance.data.length) { this.allSelected = true } else { this.allSelected = false }
  }

  public formatOwnerData(res) {
    if (res && res.data) {
      res.data.forEach(owner => {
        owner.capital = (owner.capital !== null && owner.capital !== '') ? (owner.capital * 100).toFixed(2) : null;
        owner.votes = (owner.votes !== null && owner.votes !== '') ? (owner.votes * 100).toFixed(2) : null;
      });
    }
    this.response = res;
  }
  public doAction(name, entity) {
    this.onAction.emit({ entity, action: name });
  }
  public ngOnChanges(event) {
    if (event.runUpdate) {
      this.runUpdate.subscribe(res => {
        this.page$.next(1);
      });
    }
    if (event.entityName) {
      this.entityName$.next(this.entityName);
    }
  }
  public changePage(event) {
    this.page$.next(event);
  }
  public fieldCanBeOrdered(field) {
    return field.key.indexOf('.') < 0;
  }
  public orderBy(field, asc) {
    this.orderField = field;
    this.orderAsc = asc;
    this.page$.next(1);
  }
  selectAll () {
    if (this.allSelected) {
      this.onCheckedAll.emit(this.response.data)
      this.response.data.forEach(entity => {
        entity.selected = true;
        this.selectRow(entity);
      });
    } else {
      this.onCheckedAll.emit([])
      this.response.data.forEach(entity => {
        entity.selected = false;
        this.selectRow(entity);
      });
    }
  }
  isAllSelected () {
    const selectedObject = JSON.parse(localStorage.getItem('selectedItems'));
    if (!selectedObject) {
      return false;
    } else {
      let allSelected = true;
      if (this.response && this.response.data) {
        this.response.data.forEach(entity => {
          if (!this.isChecked(entity.id)) {
            allSelected = false;
          }
        });
      }
      if (allSelected) {
        return true;
      } else {
        return false;
      }
    }
  }
  public selected(entity) {
    this.onChecked.emit(entity)
  }
  public selectRow (entity) {

    let selectedObject = this.selectedInstancesService.selectedInstanceIds// JSON.parse(localStorage.getItem('selectedItems'));
    if (!selectedObject) {
      selectedObject = {projectInstanceTable: []};
    }
    let selectedObjectDetails = this.selectedInstancesService.instancesMetadata// JSON.parse(localStorage.getItem('selectedItemDetails'));
    if (!selectedObjectDetails) {
      selectedObjectDetails = {projectInstanceTable: []};
    }
    if (entity.selected) {
      if (selectedObject[this.entityName] !== undefined && selectedObject[this.entityName].indexOf(entity.id) === -1) {
        selectedObject[this.entityName].push(entity.id);
      } else {
        selectedObject[this.entityName] = [entity.id];
      }

      if (selectedObjectDetails[this.entityName] !== undefined &&
        selectedObjectDetails[this.entityName].findIndex( ent => ent.id === entity.id) === -1) {
        selectedObjectDetails[this.entityName].push(entity);
      } else {
        selectedObjectDetails[this.entityName] = [entity];
      }
    } else {
      if (selectedObject[this.entityName] !== undefined) {
        const index = selectedObject[this.entityName].indexOf(entity.id);
        if (index > -1) {
          selectedObject[this.entityName].splice(index, 1);
        }
      }
      if (selectedObjectDetails[this.entityName] !== undefined) {
        const index = selectedObjectDetails[this.entityName].findIndex(ent => ent.id === entity.id);
        if (index > -1) {
          selectedObjectDetails[this.entityName].splice(index, 1);
        }
      }
    }
    this.handleSelectedInstances(this.response, selectedObject.projectInstanceTable)
    this.selectedInstancesService.addInstanceMetadata(selectedObjectDetails)
    this.selectedInstancesService.addselectedInstanceIds(selectedObject)
    // localStorage.setItem('selectedItems', JSON.stringify(selectedObject));
    // localStorage.setItem('selectedItemDetails', JSON.stringify(selectedObjectDetails));
  }
  public setSelection (data) {
    data.forEach(entity => {
      entity.selected = this.isChecked(entity.id);
    });
  }
  public isChecked (id: number) {
    const selectedObject = JSON.parse(localStorage.getItem('selectedItems'));
    if (selectedObject && selectedObject[this.entityName]) {
      if (selectedObject[this.entityName].indexOf(id) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public refresh() {
    if (this.external) {
      this.loading = true;
      const params =  this.service.createExternalEntities(
        this.entityDescription.name,
        this.externalTerms, 1,
        this.orderField, this.orderAsc);
      this.loadExternalData.emit(params);
      return;
    }
    this.page$.next(1);
  }

  public openTranslation(entity) {
    this.transService.onDescriptionSaved(entity);
  }

  public canTranslate(entity) {
    return this.entityName == "Description" && entity.language.name == "English";
  }

  emitPreviewUrl(entity) {
    this.tokenService.getToken().
    pipe(take(1)).subscribe(token => {
      const previewUrl = ApiSettings.BASE_URL + '/file/preview/' + entity.source + '?token=' + token.token;
      this.selectedUrl.emit(previewUrl);
    });
  }
}
