<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title pb-3 pt-3">
        <h3 class="mb-0">Exchange Rate</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
            <div class="card">
              <con-exchange-rate-lisitng  entityName="ExchangeRate"></con-exchange-rate-lisitng>
            </div>
      <!-- table for exchange rate start -->
<!--      <div class="card">-->
<!--        <con-entity-list-->
<!--          entityName="ExchangeRate"-->
<!--        ></con-entity-list>-->
<!--      </div>-->

<!--      <div class="card">-->
<!--        <div class="table-responsive">-->
<!--          <table class="table">-->
<!--            <thead  class="thead-light">-->
<!--            <tr>-->
<!--              <th>Currency</th>-->
<!--              <th  class='ex-width'>Exchange Rate</th>-->
<!--              <th class="text-right actions-width">-->
<!--                <con-icon-by-name (click)="refresh()" iconName="refresh"></con-icon-by-name>-->
<!--              </th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <con-loader *ngIf="loading"></con-loader>-->
<!--            <tbody *ngIf="!loading">-->
<!--            <ng-container *ngFor="let ex of data?.data">-->
<!--              <tr>-->
<!--                <td>{{ ex.currency?.name }}</td>-->
<!--                <td  class='ex-width'>-->
<!--                <ng-container *ngIf='!ex?.edit_mode'>-->
<!--                  {{ ex?.amount }}-->
<!--                </ng-container>-->
<!--                  <ng-container *ngIf='ex?.edit_mode'>-->
<!--                    <input type='number' class="form-control actions-width" [value]='ex.amount' (input)='setInput(ex, $event.target.value)'>-->
<!--                    <span *ngIf='ex.errorMsg' class='mat-error'>-->
<!--                      {{ex.errorMsg}}-->
<!--                    </span>-->
<!--                  </ng-container>-->
<!--                </td>-->
<!--                <td  class='actions-width'>-->
<!--                  <ng-container *ngIf='!ex?.edit_mode'>-->
<!--                    <button class='pull-right btn btn-primary' (click)='enableEdit(ex)'>Edit</button>-->
<!--                  </ng-container>-->
<!--                  <ng-container *ngIf='ex?.edit_mode'>-->
<!--                    <button class='pull-right ml-15 btn btn-warning'(click)='revertChange(ex)'>Cancel</button>-->
<!--                    <button class='pull-right btn btn-primary' (click)='saveExchangeRate(ex)'>Update</button>-->
<!--                  </ng-container>-->
<!--                </td>-->
<!--              </tr>-->
<!--            </ng-container>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </div>-->
<!--        <con-no-data *ngIf="!loading" [count]="data?.total" [message]="noDataMessage"></con-no-data>-->
<!--        <div class="card-body d-flex justify-content-center" [ngClass]="{'hide-pagination':!data?.total}">-->
<!--          <ngb-pagination [collectionSize]="data?.total"-->
<!--                          [(page)]="currentPage"-->
<!--                          (pageChange)="changePage($event)"-->
<!--                          [pageSize]="data?.per_page"-->
<!--                          maxSize="10"-->
<!--                          [rotate]="true"></ngb-pagination>-->
<!--        </div>-->
<!--      </div>-->
      <!-- table for exchange rate end -->
    </div>
  </div>
</div>
