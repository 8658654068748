import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CompanyReportsService } from '../../services/company-reports.service';
import * as moment from 'moment';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { EntitiesService, EntityDescription } from '../../../entities/services/entities.service';
import { DateConverterService } from 'app/shared/services/date-converter.service';
import { CalendarService } from 'app/calendar/calendar.service';
import { takeUntil } from 'rxjs/operators';
import { CalendarEventSettings } from 'app/settings.class';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'con-report-dates',
  templateUrl: './report-dates.component.html',
  styleUrls: ['./report-dates.component.scss']
})

export class ReportDatesComponent implements OnInit, OnDestroy {

  @Input() entity: any = {};

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>();
  public refreshTable$: Subject<any> = new Subject<any>();

  public showCreateForm = true;
  public isLoading = false;
  public isSaving = false;
  public fixedValues: any = {}
  public eventDateRange: any;
  public entityDescription: EntityDescription;
  public reportDatesPageError: string;
  public calendarEventForm: FormGroup;
  public formErrors: string[] = [];

  constructor( 
    public companyService: CompanyReportsService, 
    private calendarService: CalendarService,
    private entityService: EntitiesService,
    private dateDateConverter: DateConverterService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.initReportDatesPage();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private initReportDatesPage() {
    this.isLoading = true;
    combineLatest([
      this.companyService.getCalendarEventTypes(),
      this.entityService.getEntityDescriptionByEntityName(CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY)
    ]).pipe(takeUntil(this.componentDestroyed$)).subscribe(
      ([eventTypes, entityDescription]: [any, EntityDescription]) => {
        this.entityDescription = entityDescription;
        this.fixedValues = this.calendarService.getPrefillValuesForReportDatesForm(entityDescription);
        const calendarEventType = eventTypes.data.find((item) => item.name === CalendarEventSettings.REPORT_DATES_CALENDAR_EVENT_TYPE);
        if(calendarEventType) {
          this.fixedValues.calendar_event_type_id = calendarEventType.id;
          this.fixedValues.owner_id = this.entity.company_id;
          this.initForm();
        } else {
          this.reportDatesPageError = 'Earnings event type not found';
        }
        this.eventDateRange = this.getDateRange();
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false
      })
  }

  private getDateRange(): any {
    const today = moment();
    const fromDate =  moment(today).subtract(8, 'M').format('YYYY-MM-DD');
    const toDate = moment(today).add(8, 'M').format('YYYY-MM-DD');
    return {
      from_date: `f:geq:${fromDate}&f:leq:${toDate}`,
      owner_id: `f:eq:${this.entity.company_id}`,
      calendar_event_type_id: this.fixedValues.calendar_event_type_id,
      owner_type: this.fixedValues.owner_type
    };
  }

  private createCalendarEvent(payload: any) {
    this.isSaving = true;
    this.entityService.saveEntity(CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY, payload).pipe(takeUntil(this.componentDestroyed$)).subscribe(
      (response) => {
        this.refreshTable$.next(true);
        this.isSaving = false;
        this.initForm();
        this.toaster.success('Saved successfully!', 'Calendar event');
      },
      (error) => {
        if(error.isValueError()) {
          this.formErrors = this.calendarService.getFlattendErrorArray(error.data);
        } else {
          this.toaster.error('Something went wrong. Please contact the administrator.', 'Save calendar event');
        }
        this.isSaving = false;
      }
    )
  }

  public initForm() {
    const tomorrow = moment().add(1, 'day').startOf('day');
    this.calendarEventForm = new FormGroup({
      from_date: new FormControl(tomorrow.format('YYYY-MM-DD'), [Validators.required]),
    });
  }

  public expandCreateForm() {
    this.showCreateForm = ! this.showCreateForm;
  }

  public getMutations(event) {
    return (!event ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
  }

  public updateListing() {
    this.refreshTable$.next(true);
  }

  public getDisplayTitle() {
    return this.entity.company.name + ' / ' + this.entity.report_type + ', ' + moment(this.entity.report_date).format('yyyy-MM-DD');
  }

  public submitCalendarEvent() {
    this.formErrors = [];
    if(this.calendarEventForm.invalid) return;
    const payload = {
      ...this.fixedValues,
      from_date: this.dateDateConverter.toEntityString(moment.utc(this.calendarEventForm.value.from_date))
    }
    this.createCalendarEvent(payload);
  }
}
