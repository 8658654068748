import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ApiSettings } from '../../../settings.class';
import { EntitiesService } from '../../../entities/services/entities.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'con-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.scss']
})
export class ExchangeRateComponent implements OnInit {

  ngOnInit() {
  }
}
