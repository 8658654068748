import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import {switchMap, tap} from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { EntitiesService, EntityDescription } from '../../../entities/services/entities.service';
import { ProfileService } from '../../../auth/services/profile.service';

import { camelCase, upperCaseFirst } from 'change-case';
import * as moment from 'moment';
import {MLSendStatus} from '../../models/MLSendStatus';
import {HttpClient} from '@angular/common/http';
import { StatementType } from '../../models/StatementType';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';



@Component({
  selector: 'con-new-report-file',
  templateUrl: './new-report-file.component.html'
})
export class NewReportFileComponent implements OnInit, OnDestroy {

  @Input() entity: any;

  public entityDescription: EntityDescription;
  public entityName: string;
  public showTaxonomy = false;
  public showStatementListing = true;
  public showEditStatementListing = true;
  public showCreateStatements = true;
  public showReports = true;
  public MLSendStatus = MLSendStatus;
  private loading: boolean;
  private id: number;
  private showComponentDict: any = {};
  public mlDirectoryData = null;

  public resetKey = 'ISNew';
  public entityCompanyReport: any;
  reloadPageSubscription: Subscription;
  constructor(public service: EntitiesService,
    public profile: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private entitiesService: EntitiesService,
    private toasterService: ToastrService,
    public companyService: CompanyReportsService) { }

  ngOnInit() {
    this.initialize();
    this.reloadPageSubscription = this.companyService.reloadPage.subscribe((data) => {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
      });
    });
  }

  initialize() {
    this.route.params.pipe(
      switchMap((params: Params) => {
        this.entityName = upperCaseFirst(camelCase('company_report'));
        return this.service.getEntityDescriptionByEntityName(this.entityName);
      }),
      tap(entityDescription => {
        this.entityDescription = entityDescription;
      }), )
      .subscribe(
        (entityDescription) => {
          this.entityDescription = entityDescription;
        },
        (err) => {
          this.router.navigate(['entity', 'error'], { skipLocationChange: true });
        }
      );

    this.entityCompanyReport = {
      company_report: {
        company: {
          name:
            this.entity.company.name +
            ' - ' +
            moment(this.entity.report_date).format('yyyy-MM-DD'),
        },
        id: this.entity.id,
        locked: this.entity.locked
      },
      company_report_id: this.entity.id,
    };
  }

  afterRelationChange(event) {
    const isMultiDimensionalArray = event.objects.filter(Array.isArray).length;
    if (event.action === 'added' && isMultiDimensionalArray) {
      this.entity.taxonomies.push(event.objects[0][0]);
    }
    if (event.action === 'removed' && isMultiDimensionalArray) {
      let index = -1;
      this.entity.taxonomies.forEach((tax, idx) => {
        if (tax.id === event.objects[0][0].id) {
          index = idx;
        }
      });
      if (index > -1) {
        this.entity.taxonomies.splice(index, 1);
      }
    }
    if ((event.action === 'added') && !isMultiDimensionalArray) {
      const report = event.objects.shift();
      if(report.hasOwnProperty('company_report')) {
        this.entity.taxonomies = report.company_report.taxonomies;
      }
    }
    this.companyService.hasTaxomnomy.next(this.entity.taxonomies);
  }
  showComponent(relationName: string) {
    return this.showComponentDict[relationName];
  }
  public isLoading() {
    return this.loading || this.profile.isLoading() || this.service.isLoading();
  }
  expandTaxonomy() {
    this.showTaxonomy = ! this.showTaxonomy;
  }
  expandReports() {
    this.showReports = ! this.showReports;
  }
  expandStatementListing() {
    this.showStatementListing = ! this.showStatementListing;
  }
  expandEditStatementListing() {
    this.showEditStatementListing = ! this.showEditStatementListing;
  }
  expandCreateStatementListing() {
    this.showCreateStatements = ! this.showCreateStatements;
  }
  getMutations(event) {
    return (!event ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
  }
  getDisplayName() {
    let displayName = this.entity && this.entity.company ? this.entity.company.name : '';
    if (this.entity) {
      displayName +=
        ' / ' +
        this.entity.report_type +
        ', ' +
        moment(this.entity.report_date).format('yyyy-MM-DD');
    }
    return displayName;
  }
  get settingLock () {
    return this.companyService.locking;
  }

  afterDataSaved($event: any, isUpdate) {
    this.entitiesService.resetDayInput.next(this.resetKey);
    if(!isUpdate) {
      // call when creation happens
      this.entitiesService.companyReportFileEditingSubject.next('true');
    }
    this.entitiesService.combinedStatementListing.next(true);
  }

  reloadEditView(figuresUpdated: boolean) {
    this.showEditStatementListing = false;
  }
  ngOnDestroy(): void {
    this.reloadPageSubscription.unsubscribe();
  }
}
