import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MarketcapComponent } from './layout/marketcap/marketcap.component';
import { MarketcapStartComponent } from './layout/marketcap-start/marketcap-start.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthModule } from 'app/auth/auth.module';
import { EntitiesModule } from 'app/entities/entities.module';
import { MarketcapCompanyComponent } from './layout/marketcap-company/marketcap-company.component';
import { ShareCountEditorComponent } from './components/share-count-editor/share-count-editor.component';
import { MissingShareCountComponent } from './layout/missing-share-count/missing-share-count.component';
import { MarketcapCountService } from './service/marketcap-count.service';
import { UpdateShareCountComponent } from './layout/update-share-count/update-share-count.component';
import { DuplicatePrimaryInstrumentComponent } from './layout/duplicate-primary-instrument/duplicate-primary-instrument.component';
import { PrimaryInstrumentEditorComponent } from './components/primary-instrument-editor/primary-instrument-editor.component';
import { ShareCountListComponent } from './components/share-count-list/share-count-list.component';
import { MissingPrimaryInstrumentComponent } from './layout/missing-primary-instrument/missing-primary-instrument.component';
import { MissingPrimaryEditorComponent } from './components/missing-primary-editor/missing-primary-editor.component';
import { ExchangeRateComponent } from './layout/exchange-rate/exchange-rate.component';
import { ExchangeRateLisitngComponent } from './layout/exchange-rate/exchange-rate-lisitng/exchange-rate-lisitng.component';

@NgModule({
  declarations: [
    MarketcapComponent,
    MarketcapStartComponent,
    MarketcapCompanyComponent,
    ShareCountEditorComponent,
    MissingShareCountComponent,
    UpdateShareCountComponent,
    DuplicatePrimaryInstrumentComponent,
    PrimaryInstrumentEditorComponent,
    ShareCountListComponent,
    MissingPrimaryInstrumentComponent,
    MissingPrimaryEditorComponent,
    ExchangeRateComponent,
    ExchangeRateLisitngComponent,

  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    NgbModule,
    AuthModule,
    EntitiesModule,
    FormsModule
  ],
  providers: [
    MarketcapCountService
  ]
})
export class MarketCapModule { }
