import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from './../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { StartComponent } from './components/layout/start.component';
import { LayoutComponent } from './components/layout/layout.component';
import { CompanyComponent } from './components/layout/company.component';
import { InstitutionComponent } from './components/layout/institution.component';

import { MissingEventsComponent } from './components/missing-events.component';
import { ConfirmEventsComponent } from './components/confirm-events.component';
import { UnconfirmedHistoricalEventsComponent } from './components/unconfirmed-historical-events.component';
import { MonthExplorerComponent } from './components/month-explorer.component';
import { MonthShowerComponent } from './components/month-shower.component';

import { EntitiesModule } from '../entities/entities.module';
import { AuthModule } from '../auth/auth.module';

import { MomentModule } from 'ngx-moment';
import { CalendarOverviewComponent } from './components/layout/calendar-overview.component';
import { DirektSyncStatusComponent } from './components/direkt-sync-status.component';
import { DirektSyncEventsFilterComponent } from './components/direkt-sync-events-filter.component';
import { RecurringEventsModalComponent } from './components/recurring-events-modal.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        EntitiesModule,
        AuthModule,
        RouterModule,
        NgbModule,
        MomentModule,
        NgSelectModule
    ],

    providers: [
    ],

    declarations: [
        StartComponent,
        LayoutComponent,
        CompanyComponent,
        InstitutionComponent,
        MissingEventsComponent,
        ConfirmEventsComponent,
        UnconfirmedHistoricalEventsComponent,
        MonthExplorerComponent,
        MonthShowerComponent,
        CalendarOverviewComponent,
        DirektSyncStatusComponent,
        DirektSyncEventsFilterComponent,
        RecurringEventsModalComponent
    ],

    exports: []
})

export class CalendarModule {}
