import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { StaticSettings, ApiSettings } from '../../settings.class';
import { CountService } from '../services/count.service';
import { StaticService } from '../services/static.service';
import { TranslationService } from 'app/utility/services/translation.service';
import {filter, switchMap, take, takeUntil} from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
    selector: 'con-static-revision',
    templateUrl: './static-revision.component.html'
})

export class StaticRevisionComponent implements OnInit,  OnDestroy {
    private componentDestroyed$: Subject<boolean> = new Subject();
    public revisions: any;
    public loading = true;
    public noDataMessage = 'No data to display.';
    tabName = 'all';
    dataUnderATab = {}
    subscription: any;
    filterExpanded = true;
    updatedFilterData: any;
    initialized = false;
    sectors: any;

    private isParamsInitializedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isParamsInitialized$: Observable<boolean> = this.isParamsInitializedSubject.asObservable();
    private cancelPreviousLoadRequests: Subject<void> = new Subject<void>();

    constructor(private http: HttpClient,
                private router: Router,
                private countService: CountService,
                private staticService: StaticService,
                private transService: TranslationService) {}

    ngOnInit(): void {
      this.staticService.getSectorEndpoint().pipe(takeUntil(this.componentDestroyed$)).subscribe(sectors => {
          this.sectors = sectors;
        })
    }

    initPage() {
        this.initialized = true;
        this.loadPage(1);
        this.subscription = this.transService.onTranslationSaved.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
            this.resetFetchedObject();
            this.loadPage(1);
        })
    }

    loadPage(pageNumber: number) {
        this.countService.setIsLoading(true);
        const url = this.getEndpoint(this.tabName);
        this.dataUnderATab[this.getActiveTab()] = [];
        this.callAPI(pageNumber, url);
    }

    callAPI(pageNumber: number, url: string): void {
        this.cancelPreviousLoadRequests.next();
        this.isParamsInitialized$.pipe(
            switchMap(isEnabled => {
                if (isEnabled) {
                    this.loading = true;
                    return this.http.get<any>(url, { params: { page: String(pageNumber), exchange_ids: this.updatedFilterData } })
                        .pipe(takeUntil(this.cancelPreviousLoadRequests)); // Cancel previous request if new one is triggered
                } else {
                    return new Observable(); // Return empty observable to hold the call
                }
            })
        ).subscribe((res: any) => {
            this.countService.setCount('revisions', res.total);
            this.revisions = res;
            this.dataUnderATab[this.getActiveTab()] = res;
            this.loading = false;
            this.countService.setIsLoading(false);
        }, err => {
            this.loading = false;
            this.countService.setIsLoading(false);
            if (err.status === ApiSettings.RESPONSE_CODES.PERMISSION_ERROR) {
                this.router.navigate(['static', 'auth'], { skipLocationChange: true });
            }
        });
    }

    getActiveTab(): string {
        return this.tabName ? this.tabName : 'all';
    }

    removeFromList(company: any) {
        this.resetFetchedObject()
        const indx = this.revisions.data.findIndex(c => c.id === company.id);
        this.revisions.data.splice(indx, 1);
        if (!this.revisions.data.length) {
            this.initPage();
        }
    }
    resetFetchedObject() {
        if(this.tabName === 'all') {
            this.dataUnderATab = {}
        } else {
            this.dataUnderATab['all'] = null
        }
    }
    whenFixed(company: any) {
        this.removeFromList(company);
        // set count based api response
        const url = this.getEndpoint(this.tabName);
        this.countService.setIsLoading(true);
        this.http.get<any>(url).pipe(take(1)).subscribe(res => {
          this.countService.setCount('revisions', res.total);
          this.countService.setIsLoading(false);
        }, (error) => {
          console.error(error);
          this.countService.setIsLoading(false);
        })
    }

    updateInList(company: any) {
        const indx = this.revisions.data.findIndex(c => c.id === company.id);
        company.positions = this.revisions.data[indx].positions;
        company.required_languages = this.revisions.data[indx].required_languages;
        company.descriptions = this.revisions.data[indx].descriptions;
        this.revisions.data[indx] = company;
        this.resetFetchedObject()
    }

    onTabSelected(tabName){
        this.tabName = tabName;
        this.loadPage(1);
    }

    getEndpoint(tabName: any) {
        return this.staticService.getEndpoint(tabName, 'revisions')
    }

    ngOnDestroy() {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

  updateFilterConfiguration(filterUpdatedData: any) {
    this.updatedFilterData = filterUpdatedData.exchange_ids;
    if(!this.initialized)
        this.isParamsInitializedSubject.next(true);
    this.initPage();
  }
}
