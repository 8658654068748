import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'con-static-tab',
  templateUrl: './static-tab.component.html',
  styleUrls: ['./static-tab.component.scss']
})
export class StaticTabComponent{
  selectedTab: string = 'all';
  @Output() onTabChanged: EventEmitter<any> = new EventEmitter<any>();
  
  constructor() { }

  onTabSelected(tabName: string){
    this.selectedTab = tabName;
    this.onTabChanged.emit(tabName);
  }

}
